import "./user-modal.sass"

import * as React from "react"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import PassIcon from "../../../assets/icons/pass.png"

const AddUserModal = (props: any) => {
  const [assignSelectedEmail, assignSelectedEmailSet] = React.useState()

  return (
    <Dialog open={props.open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">{props.title}</DialogTitle>
      <DialogContent>
        <DialogContentText
          style={{
            maxHeight: "300px",
            minWidth: "200px",
            overflowY: "scroll",
          }}
        >
          {props.listOfUsers.map((value: any, index: any) => {
            return (
              <span style={{ display: "block" }} key={index}>
                <span
                  id={index}
                  className="name-field"
                  onClick={() => assignSelectedEmailSet(value)}
                >
                  {value}
                </span>
                {assignSelectedEmail === value && (
                  <img
                    alt="pass"
                    style={{ float: "right", width: "25px", height: "auto" }}
                    src={PassIcon}
                  />
                )}
              </span>
            )
          })}
        </DialogContentText>
      </DialogContent>
      <DialogActions className="buttons-container">
        <button
          id="delete-modal-cancel"
          onClick={() => props.onClose()}
          color="primary"
          className={
            props.disabled ? "btn quaternary inactive" : "btn quaternary"
          }
          disabled={props.disabled ? props.disabled : undefined}
        >
          Cancel
        </button>
        <button
          id="delete-modal-confirm"
          onClick={() => props.onSubmitAssignModal(assignSelectedEmail)}
          color="primary"
          className={props.disabled ? "btn denial inactive" : "btn primary"}
          disabled={props.disabled ? props.disabled : undefined}
        >
          {props.submitButton}
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default AddUserModal
