import "date-fns"
import "./create-edit-study.sass"

import * as React from "react"
import * as Yup from "yup"

import { reduxForm, reset } from "redux-form"
import { useDispatch, useSelector } from "react-redux"

import CustomInput from "../custom-fields/input"
import CustomRadioButton from "../custom-fields/radio-button"
import CustomSelect from "../custom-fields/select"
import CustomSwitch from "../custom-fields/switch"
import CustomTextArea from "../custom-fields/textarea"
import { DATE_FORMAT } from "../../../constants/constants"
import { Grid } from "@material-ui/core"
import LoaderPoints from "../../../components/common/loader-points/loader-points"
import { RootState } from "../../../reducers/mainReducer"
import Tooltip from "@material-ui/core/Tooltip"
import { compose } from "recompose"
import { format } from "date-fns"
import { updateProject } from "../../../actions/apiAction"
import { useEffect } from "react"
import { useFormik } from "formik"
import { warning } from "../../../actions/notificationsActions"

const toolTipText = "You can't submit, required missing values"

const EditStudyForm = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  useEffect(() => {
    dispatch(reset("editStudyForm"))
    const formInitial = {
      name: state.current.project.data.name,
      status: state.current.project.data.state,
      age_measure: state.current.project.data.config.age_measure,
      description: state.current.project.data.description,
      num_readers: state.current.project.data.config.num_readers,
      num_diagnosis_adjudicators:
        state.current.project.data.config.num_diagnosis_adjudicators,
      number_expected_tm_per_subject:
        state.current.project.data.config.number_expected_tm_per_subject,
    }
    props.initialize(formInitial)
  }, [state.current.project.data])

  const formik = useFormik({
    initialValues: {
      name: state.current.project.data.name,
      status: state.current.project.data.state,
      age_measure: state.current.project.data.config.age_measure,
      description: state.current.project.data.description,
      num_readers: state.current.project.data.config.num_readers,
      num_diagnosis_adjudicators:
        state.current.project.data.config.num_diagnosis_adjudicators,
      number_expected_tm_per_subject:
        state.current.project.data.config.number_expected_tm_per_subject,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(25, "Must be only 25 or less!")
        .required("Required!")
        .matches(
          /^[aA-zZ0-9\s]+$/,
          "Only letters and numbers are allowed for this field !"
        ),
      num_readers: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      num_diagnosis_adjudicators: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      number_expected_tm_per_subject: Yup.string()
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
    }),
    onSubmit: (values) => onSubmit(values),
    validateOnChange: true,
    enableReinitialize: true,
  })

  const onSubmit = (values: any) => {
    const params: any = {
      ...values,
      name: values.name,
      status: values.status,
      age_measure: values.age_measure,
      form_id: state.current.project.data.config.form_id,
      description: values.description,
      num_readers: values.num_readers,
      num_diagnosis_adjudicators: values.num_diagnosis_adjudicators,
      number_expected_tm_per_subject: values.number_expected_tm_per_subject,
    }

    const max_num_diagnosis_adjudicators = 1
    if (
      parseInt(values.num_diagnosis_adjudicators) >
      max_num_diagnosis_adjudicators
    ) {
      dispatch(
        warning(
          `The number of adjudicators must be between 0 and ${max_num_diagnosis_adjudicators}!`
        )
      )
    } else {
      if (!Object.keys(formik.errors).length) {
        dispatch(updateProject(params, props.history))
      }
    }
  }

  return (
    <Grid container>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <div className="container-form">
          <h1>
            {props.title}
            <LoaderPoints isloading={state.current.project.isFetching} />
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <Grid container className="form-container-create-edit">
              <div className="study-info">
                <div className="line">
                  <span className="title">Creation date: </span>
                  <span className="value">
                    {format(
                      state.current.project.data.config.time_created.$date,
                      `${DATE_FORMAT}`
                    )}
                  </span>
                </div>
                <div className="line">
                  <span className="title"> Owner: </span>
                  <span className="value">
                    {state.current.project.data.owner_name}
                  </span>
                </div>
                <div className="line">
                  <span className="title"> Data location: </span>
                  <span className="value">
                    {
                      state.project_config.data.data_locations.find(
                        (item: any) =>
                          item.value ===
                          state.current.project.data.config.data_location
                      )?.label
                    }
                  </span>
                </div>
                <div className="line">
                  <span className="title"> Form Type: </span>
                  <span className="value">
                    {state.current.project.data.config.form_id}
                  </span>
                </div>
              </div>

              <CustomInput
                label={"Study Name"}
                name={"name"}
                placeholder={"Study Name e.g 'My Test Study'"}
                value={formik.values.name}
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
              />

              <CustomSelect
                label={"Study status"}
                name={"status"}
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
                defaultValue={formik.values.status}
                options={state.project_config.status}
              />

              <CustomRadioButton
                label={"Age units *"}
                name="age_measure"
                defaultValue="month"
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
              />

              <CustomTextArea
                label={"Description"}
                name="description"
                placeholder={"Desciption of the study (max. 240 characters)"}
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
              />

              <CustomInput
                label={"Number of readers *"}
                name="num_readers"
                placeholder={""}
                type={"number"}
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
                disabled={state.current.project.data.state !== "active"}
              />

              <CustomInput
                label={"Number of diagnosis adjudicators *"}
                name="num_diagnosis_adjudicators"
                placeholder={""}
                type={"number"}
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
                disabled={state.current.project.data.state !== "active"}
              />

              <CustomInput
                label={"Number of expected timepoints per subject *"}
                name="number_expected_tm_per_subject"
                placeholder={""}
                type={"number"}
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
              />

              <CustomSwitch
                label={"Task e-signature"}
                name="password_verify"
                onChange={formik.handleChange}
                classname={"form-item"}
                formik={formik}
              />
            </Grid>

            {Object.keys(formik.errors).length ? (
              <div className="error-message">
                <p className="description-error-text">
                  Some fields are not valid
                </p>
              </div>
            ) : (
              <></>
            )}

            <Grid container className="buttons-container">
              <button
                className="btn quaternary"
                onClick={(e: any) => {
                  e.preventDefault()
                  props.history.goBack()
                }}
              >
                Cancel
              </button>

              {Object.keys(formik.errors).length ? (
                <Tooltip title={toolTipText} placement="top">
                  <button
                    className="btn inactive denial"
                    type="submit"
                    disabled={props.submitting}
                  >
                    Save Changes
                  </button>
                </Tooltip>
              ) : (
                <button
                  className="btn primary"
                  type="submit"
                  disabled={props.submitting}
                >
                  Save Changes
                </button>
              )}
            </Grid>
          </form>
        </div>
      </Grid>
    </Grid>
  )
}

export default compose(
  reduxForm({
    form: "editStudyForm",
  })
)(EditStudyForm)
