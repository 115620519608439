import { AnyAction } from "redux"
import { ICollaboratorTable } from "../utils/interfaces"
import { SET_COLLABORATORS } from "../constants/constants"

const SITES_INITIAL_STATE: ICollaboratorTable = {
  data: [],
  total: 0,
}

export default function (state = SITES_INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SET_COLLABORATORS:
      return {
        ...state,
        data: action.collaborators,
        total: action.collaborators.length,
      }
    default:
      return state
  }
}
