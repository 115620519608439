import * as React from "react"

import EmptyMessage from "../../components/common/empty-message/empty-message"
import Fetching from "../../components/common/fetching-table/fetching-table"
import { Grid } from "@material-ui/core"
import LoaderPoints from "../../components/common/loader-points/loader-points"
import Paper from "@material-ui/core/Paper"
import { RootState } from "../../reducers/mainReducer"
import TableLayout from "../../components/common/Table/table-layout"
import { activityColumn } from "../../utils/column-definition"
import { useEffect } from "react"
import { useSelector } from "react-redux"

let studyName = ""

const ActivityPage = () => {
  const state = useSelector((state: RootState | any) => state)

  useEffect(() => {
    studyName = state.current.project.data.name.toUpperCase()
  }, [])

  return (
    <div className="container">
      <Grid container className="container-worklist-page">
        <Grid item xs={12} sm={12} md={2}>
          <h1>
            Activity log
            <LoaderPoints isloading={state.activityTable.isFetching} />
          </h1>
        </Grid>
        <Grid item xs={12}>
          <Fetching
            isFetching={
              state.activityTable.isFetching && !state.activityTable.data.length
            }
          >
            <EmptyMessage dataLoaded={state.activityTable.data.length}>
              <Grid
                item
                xs={12}
                container
                className="no-task"
                justifyContent={"center"}
                alignContent={"center"}
              >
                <p>Your activity list is currently empty</p>
              </Grid>
            </EmptyMessage>
            <Grid item xs={12}>
              <div className="button-row">
                <label>
                  There are {state.activityTable.data.length} activities within
                  this study
                </label>
              </div>
            </Grid>
            {state.activityTable.data.length && (
              <Grid item xs={12}>
                <Paper className="root">
                  <TableLayout
                    columns={activityColumn}
                    data={state.activityTable.data}
                    pagination={true}
                    rowLink={false}
                  />
                </Paper>
              </Grid>
            )}
          </Fetching>
        </Grid>
      </Grid>
    </div>
  )
}

export default ActivityPage
