import { ISessionInitialState, IUserData } from "../utils/interfaces"
import {
  SET_SESSION,
  SET_SESSION_EXPIRED,
  IS_FETCHING_SESSION,
} from "../constants/constants"

import { AnyAction } from "redux"

const SESSION_INITIAL_STATE: IUserData = {
  user_data: {
    "2fa": {},
    av_data: 0,
    av_projects: 0,
    dev: 1,
    email: "",
    institution: "",
    name: "",
    release_version: 0,
    run_parallel: 0,
    session_expiration: 0,
    tab_preference: "",
    username: "",
    access: null,
  },
  isFetching: false,
  session_expired: false,
}

export default function (
  state: ISessionInitialState = SESSION_INITIAL_STATE,
  action: AnyAction
) {
  switch (action.type) {
    case SET_SESSION:
      return {
        user_data: {
          ...state.user_data,
          ...action.payload.user_data,
        },
        isFetching: false,
      }
    case IS_FETCHING_SESSION:
      return {
        ...state,
        isFetching: action.payload,
      }
    case SET_SESSION_EXPIRED:
      return {
        ...state,
        session_expired: action.payload,
      }
    default:
      return state
  }
}
