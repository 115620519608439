import * as React from "react"

import { TableCell, TableHead, TableRow } from "@material-ui/core"

const TableHeaderComponent = ({ columns }: any) => {
  return (
    <TableHead>
      <TableRow>
        {columns.map((column: any, index: any) => {
          return (
            <TableCell key={index} align={column.align}>
              {column.label}
            </TableCell>
          )
        })}
      </TableRow>
    </TableHead>
  )
}

export default TableHeaderComponent
