import "./work-list-session-matrix.sass"

import * as React from "react"

import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  makeStyles,
} from "@material-ui/core"
import {
  getCurrentTaskEndpoint,
  getHistory,
} from "../../actions/CentralReviewAction"
import { useDispatch, useSelector } from "react-redux"

import { Link } from "react-router-dom"
import LinkIcon from "../../assets/icons/link.png"
import LoaderPoints from "../../components/common/loader-points/loader-points"
import { RootState } from "../../reducers/mainReducer"

const useStyles = makeStyles({
  root: {
    width: "95%",
    margin: "auto",
  },
  container: {
    // maxHeight: 740,
  },
  linkContainer: {
    height: "25px",
    marginLeft: "5px",
  },
  linkImage: {
    height: "100%",
  },
  headerCell: {
    display: "flex",
    alignItems: "center",
  },
  sessionId: {
    fonstSize: "10px",
  },
})

let projectManager = ""
let isManager

const WorkListMatrix = (props: any) => {
  const classes = useStyles()
  const [columns, setColumns] = React.useState<any[]>([])
  const [keys, setKeys] = React.useState<any[]>([])

  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  React.useEffect(() => {
    const getHistoryData = {
      projectset_id: props.match.params.project_id,
      task_id: props.match.params.task_id,
    }

    dispatch(getHistory(getHistoryData))
  }, [])

  React.useEffect(() => {
    // -------------------- SET COLUMNS -----------------------------

    const valuesToExclude = [
      "Patient Name",
      "Timepoint",
      "Projectset Id",
      "Task Id",
      "annotation string",
    ]

    const keysToReturn = Object.keys(
      state.CentralReview.currentTask.historyTable
    ).filter((key) => {
      if (valuesToExclude.includes(key)) {
        return false
      }
      return key
    })

    setKeys(keysToReturn)

    const subjectId =
      state.CentralReview.currentTask.task_info.patient_secret_name &&
      state.CentralReview.currentTask.task_info.patient_secret_name
    const columnsArray: any[] = [
      {
        id: "subject",
        label: `Subject ${subjectId || "..."}`,
        minWidth: 170,
      },
    ]

    state.CentralReview.currentTask.historyTable["Task Id"]?.map(
      (value: any, index: any) => {
        if (index === 0) {
          columnsArray.push({
            id: `baseline_${index}`,
            label: "Baseline",
            session: "",
            minWidth: 170,
            align: "right",
            url: `/project/${props.match.params.project_id}/task/${value}`,
          })
        } else {
          const timepoint =
            state.CentralReview.currentTask.historyTable.Timepoint[index]
          columnsArray.push({
            id: `timepoint_${timepoint}`,
            label: `Timepoint ${timepoint}`,
            session: `Session ${timepoint}`,
            minWidth: 170,
            align: "right",
            isNull: value === null || typeof value === "undefined",
            url: `/project/${props.match.params.project_id}/task/${value}`,
          })
        }
      }
    )
    setColumns(columnsArray)
  }, [
    state.CentralReview.currentTask.historyTable,
    state.CentralReview.currentTask.task_info,
  ])

  React.useEffect(() => {
    isManager = state.current.project.isManager
    projectManager = state.current.project.data.name
    Promise.all([dispatch(getCurrentTaskEndpoint(props.match.params))]).then(
      () => {}
    )
  }, [state.current.project.data])

  return (
    <div>
      <Grid container className="container">
        <Grid item xs={12} sm={12} md={12}>
          <div style={{ marginLeft: "2%" }}>
            <h1>
              History
              <LoaderPoints isloading={state.CentralReview.isFetching} />
            </h1>
            <p>
              You can see the history of all the timepoints of this subject.
              Click on each timepoint to view each reading in detail.
            </p>
          </div>
        </Grid>
      </Grid>
      <Paper className={classes.root}>
        <TableContainer className={classes.container}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column, index) => {
                  return (
                    <TableCell
                      key={index}
                      align={column.align}
                      style={{ minWidth: column.minWidth }}
                    >
                      <div className={classes.headerCell}>
                        {column.label}
                        {column.id !== "subject" &&
                          column.label !== "Baseline" &&
                          !column.isNull && (
                            <>
                              <div className={classes.linkContainer}>
                                <Link
                                  to={column.url}
                                  rel="noopener noreferrer"
                                  target="_blank"
                                >
                                  <img
                                    alt="link"
                                    className={classes.linkImage}
                                    src={LinkIcon}
                                  />
                                </Link>
                              </div>
                            </>
                          )}
                        <br />
                      </div>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {keys.map((key: any, index: any) => (
                <TableRow hover role="checkbox" key={index} tabIndex={-1}>
                  <TableCell key={index}>{key}</TableCell>
                  {Object.values(
                    state.CentralReview.currentTask.historyTable[key]
                  ).map((value: any, index) => {
                    if (value === null || typeof value === "undefined") {
                      return <TableCell key={index}>#</TableCell>
                    }
                    if (typeof value === "boolean") {
                      return (
                        <TableCell key={index}>{value.toString()}</TableCell>
                      )
                    }
                    if (value.$numberDecimal?.length) {
                      return (
                        <TableCell key={index}>
                          {value.$numberDecimal}
                        </TableCell>
                      )
                    } else {
                      return <TableCell key={index}>{value}</TableCell>
                    }
                  })}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  )
}

export default WorkListMatrix
