import "./overview-page.sass"

import * as React from "react"

import { useDispatch, useSelector } from "react-redux"

import Grid from "@material-ui/core/Grid"
import LoaderContainer from "../../components/common/loader-container/loader-container"
import StudyAcquisitionCard from "../../components/common/dashboard/study-acquisition-card"
import StudyActivityCard from "../../components/common/dashboard/study-activity-card"
import StudyInfoCard from "../../components/common/dashboard/study-info-card"
import StudyQcCard from "../../components/common/dashboard/study-qc-card"
import brainThinking from "../../assets/lotties/brain-lottie"
import { getActivityList } from "../../actions/apiAction"
import { useEffect } from "react"

const Overview = (props: any) => {
  const state = useSelector((state: any) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getActivityList(props.match.params.project_id))
  }, [])

  return (
    <LoaderContainer
      isloading={state.current.project.isFetching}
      lottieSVG={brainThinking}
    >
      <Grid className="title-description">
        <h1 className="h1 title">Study Overview</h1>
        <p className="p paragraph">
          On this screen you can view the highlights of your study, e.g., study
          details, uploaded sessions and their Quality Control status as well as
          the number of completed activities.
        </p>
      </Grid>
      <Grid className="columns-container">
        <Grid item className="container-study-overview-page" xs={12} sm={6}>
          <Grid item xs={12} className="cards-left-container">
            <StudyInfoCard />
          </Grid>
          <Grid item xs={12} className="cards-left-container">
            <StudyActivityCard />
          </Grid>
        </Grid>
        <Grid item className="container-study-overview-page" xs={12} sm={6}>
          <Grid item xs={12} className="cards-left-container">
            <StudyAcquisitionCard />
          </Grid>
          <Grid item xs={12} className="cards-left-container">
            <StudyQcCard />
          </Grid>
        </Grid>
      </Grid>
    </LoaderContainer>
  )
}

export default Overview
