import {
  GET_FORM_TYPES,
  GET_ROLES_ADD_COLLABORATOR,
  IS_FETCHING,
  RECEIVE_PROJECT_CONFIG,
} from "../constants/constants"

import { AnyAction } from "redux"
import { IProjectConfig } from "../utils/interfaces"

const PROJECTS_INITIAL_STATE: IProjectConfig = {
  data: {
    data_locations: [{ value: "us", label: "USA" }],
  },
  formTypes: [],
  roles: [],
  status: [
    { value: "active", label: "Active" },
    { value: "setup", label: "In Set Up" },
    { value: "archived", label: "Archived" },
  ],
  isFetching: false,
}

export default function (state = PROJECTS_INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case RECEIVE_PROJECT_CONFIG:
      return {
        ...state,
        data: {
          allowed_project_types: action.allowed_project_types,
          project_types: action.project_types,
          data_locations: action.data_locations,
        },
      }

    case IS_FETCHING:
      return {
        ...state,
        isFetching: true,
      }
    case GET_FORM_TYPES:
      return {
        ...state,
        formTypes: action.payload,
        isFetching: false,
      }

    case GET_ROLES_ADD_COLLABORATOR:
      return {
        ...state,
        roles: action.payload,
        isFetching: false,
      }

    default:
      return {
        ...state,
      }
  }
}
