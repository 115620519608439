import "./side-panel.sass"

import * as React from "react"

import { Logo } from "../Logo"
import Tabs from "../dashboard/tabs"
import { sessionLogout } from "../../../actions/sessionAction"
import { useDispatch } from "react-redux"

const elements = [
  { url: "/project", id: 1, name: "Studies" },
  {
    url: "https://qmenta.atlassian.net/wiki/spaces/QTG/pages/1176502459/Getting+started",
    id: 4,
    name: "Documentation",
    externalURL: true,
  },
  {
    url: process.env.REACT_APP_PROFILE_URL,
    id: 5,
    name: "My Account",
    externalURL: true,
  },
]

const DashboardSidePanel = (props: any) => {
  const dispatch = useDispatch()
  return (
    <aside className="dashboard-side-panel">
      <Logo color={"#FFFFFF"} />
      <nav className="side-menu">
        <ul>
          <Tabs elements={elements} {...props} />
          <li className={`tab-link`} key={`tab Logout`}>
            <button
              className={`logout`}
              onClick={() => dispatch(sessionLogout())}
            >
              Logout
            </button>
          </li>
        </ul>
      </nav>
    </aside>
  )
}

export default DashboardSidePanel
