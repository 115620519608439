import {
  hide,
  notificationError,
  success,
  warning,
} from "../actions/notificationsActions"

export default (select = ({ meta }: any) => meta.notification) =>
  ({ getState, dispatch }: any) =>
  (next: any) =>
  (action: any) => {
    const returnValue = next(action)

    if (!action || !action.meta) {
      return returnValue
    }

    const event = select(action)
    if (!event) {
      return returnValue
    }

    switch (event.type) {
      case "hide":
        dispatch(hide(event.message))
        break
      case "notificationError":
        dispatch(notificationError(event.message))
        break
      case "success":
        dispatch(success(event.message))
        break
      case "warning":
        dispatch(warning(event.message))
        break
    }

    return returnValue
  }
