const brainThinking = {
  v: "5.7.12",
  fr: 29.9700012207031,
  ip: 0,
  op: 150.000006109625,
  w: 1920,
  h: 1080,
  nm: "Info Concept 1",
  ddd: 0,
  assets: [
    {
      id: "comp_0",
      nm: "Gear 55",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-16.475, -9.52],
                    [9.52, -16.457],
                    [16.454, 9.524],
                    [-9.5, 16.462],
                  ],
                  o: [
                    [16.462, 9.51],
                    [-9.499, 16.472],
                    [-16.475, -9.489],
                    [9.5, -16.473],
                  ],
                  v: [
                    [-94.501, 165.043],
                    [-81.909, 212.071],
                    [-128.923, 224.664],
                    [-141.537, 177.646],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-9.499, -16.462],
                    [16.482, -9.494],
                    [9.52, 16.472],
                    [-16.485, 9.51],
                  ],
                  o: [
                    [9.518, 16.462],
                    [-16.465, 9.515],
                    [-9.517, -16.457],
                    [16.462, -9.509],
                  ],
                  v: [
                    [142.539, 177.646],
                    [129.936, 224.674],
                    [82.908, 212.071],
                    [95.514, 165.043],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0.008, 19.015],
                    [-19.03, 0],
                    [0.008, -19.009],
                    [19.009, 0.011],
                  ],
                  o: [
                    [0, -19.009],
                    [19.009, 0.021],
                    [0.008, 19.01],
                    [-19.02, 0.011],
                  ],
                  v: [
                    [-33.926, 224.929],
                    [0.506, 190.484],
                    [34.928, 224.929],
                    [0.506, 259.349],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 76.455],
                    [-76.463, 0],
                    [0, -76.449],
                    [76.453, 0],
                  ],
                  o: [
                    [0, -76.46],
                    [76.453, 0],
                    [0, 76.461],
                    [-76.463, 0],
                  ],
                  v: [
                    [-137.943, 0.489],
                    [0.496, -137.949],
                    [138.933, 0.489],
                    [0.496, 138.929],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [19.02, 0.01],
                    [-0.01, 19.009],
                    [-19.009, 0.011],
                    [0, -19.019],
                  ],
                  o: [
                    [-19.009, 0.01],
                    [-0.01, -19.01],
                    [19.02, 0],
                    [0, 19.009],
                  ],
                  v: [
                    [-223.941, 34.904],
                    [-258.363, 0.479],
                    [-223.941, -33.961],
                    [-189.509, 0.479],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-16.472, -9.51],
                    [9.499, -16.462],
                    [16.462, 9.52],
                    [-9.52, 16.457],
                  ],
                  o: [
                    [16.464, 9.5],
                    [-9.52, 16.473],
                    [-16.475, -9.5],
                    [9.507, -16.473],
                  ],
                  v: [
                    [-176.65, -141.564],
                    [-164.037, -94.536],
                    [-211.073, -81.933],
                    [-223.676, -128.95],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-9.5, -16.472],
                    [16.472, -9.51],
                    [9.51, 16.451],
                    [-16.472, 9.51],
                  ],
                  o: [
                    [9.499, 16.457],
                    [-16.475, 9.51],
                    [-9.499, -16.478],
                    [16.473, -9.519],
                  ],
                  v: [
                    [224.696, -128.94],
                    [212.103, -81.912],
                    [165.057, -94.505],
                    [177.67, -141.554],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-19.017, 0],
                    [0, -19.02],
                    [19.019, 0],
                    [0, 19.01],
                  ],
                  o: [
                    [19.019, 0],
                    [0, 19.01],
                    [-19.017, 0],
                    [0, -19.02],
                  ],
                  v: [
                    [224.951, -33.94],
                    [259.376, 0.489],
                    [224.951, 34.925],
                    [190.529, 0.489],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 8",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-9.5, -16.473],
                    [16.464, -9.489],
                    [9.5, 16.487],
                    [-16.464, 9.515],
                  ],
                  o: [
                    [9.51, 16.462],
                    [-16.472, 9.51],
                    [-9.52, -16.442],
                    [16.455, -9.51],
                  ],
                  v: [
                    [-164.058, 95.489],
                    [-176.65, 142.512],
                    [-223.676, 129.899],
                    [-211.083, 82.887],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 9",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [16.482, 9.489],
                    [-9.509, 16.473],
                    [-16.464, -9.51],
                    [9.527, -16.473],
                  ],
                  o: [
                    [-16.462, -9.51],
                    [9.51, -16.476],
                    [16.455, 9.505],
                    [-9.492, 16.457],
                  ],
                  v: [
                    [177.661, 142.532],
                    [165.057, 95.499],
                    [212.093, 82.896],
                    [224.678, 129.93],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 10",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-9.509, -16.462],
                    [16.454, -9.515],
                    [9.492, 16.482],
                    [-16.472, 9.5],
                  ],
                  o: [
                    [9.518, 16.472],
                    [-16.472, 9.499],
                    [-9.517, -16.467],
                    [16.455, -9.51],
                  ],
                  v: [
                    [-81.898, -211.117],
                    [-94.483, -164.074],
                    [-141.519, -176.683],
                    [-128.916, -223.7],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 11",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-19.017, -0.011],
                    [-0.01, -19.009],
                    [19.009, -0.011],
                    [-0.008, 19.02],
                  ],
                  o: [
                    [19.009, 0],
                    [0.01, 19.02],
                    [-19.017, -0.011],
                    [0, -19.009],
                  ],
                  v: [
                    [0.514, -258.39],
                    [34.946, -223.966],
                    [0.514, -189.525],
                    [-33.918, -223.966],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 12",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [16.472, 9.5],
                    [-9.507, 16.473],
                    [-16.462, -9.51],
                    [9.507, -16.472],
                  ],
                  o: [
                    [-16.464, -9.515],
                    [9.509, -16.472],
                    [16.475, 9.51],
                    [-9.51, 16.478],
                  ],
                  v: [
                    [95.514, -164.064],
                    [82.908, -211.107],
                    [129.944, -223.7],
                    [142.549, -176.678],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 13",
            },
            {
              inv: false,
              mode: "f",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-0.028, 24.104],
                    [24.098, 0],
                    [0, -24.099],
                    [-24.091, 0.011],
                  ],
                  o: [
                    [0, -24.088],
                    [-24.101, -0.01],
                    [0, 24.104],
                    [24.098, 0],
                  ],
                  v: [
                    [44.145, 0.479],
                    [0.506, -43.139],
                    [-43.124, 0.479],
                    [0.506, 44.107],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 14",
            },
            {
              inv: false,
              mode: "f",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-0.018, 62.377],
                    [21.373, 21.362],
                    [30.211, 0],
                    [21.365, -21.367],
                    [0, -30.229],
                    [-21.375, -21.378],
                    [-30.221, 0.01],
                  ],
                  o: [
                    [0, -30.229],
                    [-21.385, -21.377],
                    [-30.232, -0.01],
                    [-21.365, 21.362],
                    [0, 30.234],
                    [21.365, 21.373],
                    [62.372, -0.011],
                  ],
                  v: [
                    [113.65, 0.479],
                    [80.517, -79.518],
                    [0.506, -112.657],
                    [-79.515, -79.528],
                    [-112.65, 0.479],
                    [-79.504, 80.492],
                    [0.496, 113.626],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 15",
            },
            {
              inv: false,
              mode: "f",
              pt: {
                a: 0,
                k: {
                  i: [
                    [18.762, 18.775],
                    [0, 26.554],
                    [-18.764, 18.764],
                    [-26.551, -0.01],
                    [-18.772, -18.774],
                    [0, -26.538],
                    [54.774, -0.01],
                  ],
                  o: [
                    [-18.775, -18.769],
                    [0, -26.549],
                    [18.762, -18.765],
                    [26.551, 0],
                    [18.771, 18.764],
                    [-0.01, 54.786],
                    [-26.541, 0],
                  ],
                  v: [
                    [-69.767, 70.753],
                    [-98.873, 0.479],
                    [-69.778, -69.794],
                    [0.496, -98.88],
                    [70.77, -69.784],
                    [99.876, 0.479],
                    [0.496, 99.858],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 16",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.935, 0],
                        [-3.917, 0.133],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.238, 2.95],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.781, 5.232],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.67, 6.927],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.082, 7.805],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.639, 7.616],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.165, 6.605],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.151, 4.803],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [7.483, 2.42],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.936, 0],
                        [3.908, -0.144],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [7.297, -2.96],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.837, -5.299],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.663, -6.938],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.051, -7.814],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.636, -7.626],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.163, -6.604],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.159, -4.803],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.493, -2.42],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [3.887, 0.143],
                        [3.946, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [7.475, -2.425],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.179, -4.809],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.165, -6.604],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.639, -7.615],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.082, -7.815],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.68, -6.916],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.791, -5.242],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.238, -2.96],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.899, -0.147],
                        [-3.935, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.503, 2.41],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.161, 4.819],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.208, 6.672],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.636, 7.652],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.077, 7.814],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.681, 6.928],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.791, 5.242],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [7.238, 2.955],
                        [0, 0],
                      ],
                      v: [
                        [-99.261, 346.922],
                        [-25.353, 359.959],
                        [-11.726, 315.698],
                        [-0.004, 315.989],
                        [11.757, 315.698],
                        [25.391, 359.948],
                        [99.299, 346.922],
                        [96.962, 300.711],
                        [119.036, 292.641],
                        [146.967, 329.557],
                        [211.953, 292.044],
                        [193.88, 249.247],
                        [211.869, 234.229],
                        [250.824, 259.415],
                        [299.056, 201.944],
                        [267.474, 167.937],
                        [279.255, 147.652],
                        [324.425, 157.994],
                        [350.101, 87.476],
                        [308.918, 66.394],
                        [312.971, 43.24],
                        [358.896, 37.497],
                        [358.891, -37.539],
                        [312.971, -43.271],
                        [308.918, -66.431],
                        [350.081, -87.512],
                        [324.415, -158.005],
                        [279.255, -147.694],
                        [267.474, -167.979],
                        [299.03, -201.965],
                        [250.813, -259.437],
                        [211.848, -234.267],
                        [193.88, -249.283],
                        [211.935, -292.064],
                        [146.95, -329.577],
                        [119.028, -292.667],
                        [96.943, -300.747],
                        [99.251, -346.933],
                        [25.373, -359.959],
                        [11.739, -315.719],
                        [-0.004, -316.02],
                        [-11.765, -315.733],
                        [-25.381, -359.949],
                        [-99.268, -346.923],
                        [-96.961, -300.87],
                        [-119.168, -292.841],
                        [-146.968, -329.561],
                        [-211.933, -292.054],
                        [-193.955, -249.447],
                        [-212.027, -234.352],
                        [-250.811, -259.421],
                        [-299.048, -201.944],
                        [-267.618, -168.112],
                        [-279.501, -147.729],
                        [-324.415, -157.995],
                        [-350.078, -87.486],
                        [-308.984, -66.431],
                        [-313.024, -43.231],
                        [-358.886, -37.508],
                        [-358.896, 37.528],
                        [-312.976, 43.261],
                        [-308.928, 66.43],
                        [-350.068, 87.496],
                        [-324.415, 158.015],
                        [-279.264, 147.683],
                        [-267.475, 167.978],
                        [-299.031, 201.954],
                        [-250.803, 259.436],
                        [-211.848, 234.261],
                        [-193.87, 249.272],
                        [-211.922, 292.044],
                        [-146.939, 329.566],
                        [-119.008, 292.656],
                        [-96.923, 300.727],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_1",
      nm: "Gear 56",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "gear 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [471, 471, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-7.711, -20.735],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [21.507, -3.992],
                  ],
                  v: [
                    [43.037, -15.363],
                    [4.773, -3.571],
                    [-7.73, -44.144],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-21.15, 8.248],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-5.452, -22.11],
                  ],
                  v: [
                    [-16.032, -41.773],
                    [-3.477, -1.029],
                    [-43.587, 11.329],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [8.898, 19.386],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-21.02, 4.832],
                  ],
                  v: [
                    [-40.735, 19.484],
                    [-0.934, 7.221],
                    [10.628, 44.747],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [18.994, -8.352],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [3.449, 20.401],
                  ],
                  v: [
                    [18.826, 42.032],
                    [7.316, 4.678],
                    [45.269, -7.018],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.361, 6.977],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.48, 5.153],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.573, 2.273],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.977, -1.36],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.152, -4.479],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.273, -6.572],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.36, -6.978],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.48, -5.153],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.574, -2.273],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.977, 1.361],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.152, 4.481],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.274, 6.574],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.802, -6.781],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.802, -6.533],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.58, -4.153],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.781, -0.803],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.531, 2.803],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.153, 5.579],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.803, 6.78],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.801, 6.532],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.579, 4.154],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.782, 0.804],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.532, -2.803],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.153, -5.579],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [67.03, 7.894],
                        [66.257, -12.846],
                        [76.095, -15.873],
                        [71.835, -29.708],
                        [61.996, -26.677],
                        [50.958, -44.256],
                        [57.964, -51.795],
                        [47.358, -61.647],
                        [40.346, -54.099],
                        [22.001, -63.8],
                        [24.302, -73.837],
                        [10.191, -77.068],
                        [7.894, -67.031],
                        [-12.846, -66.261],
                        [-15.877, -76.097],
                        [-29.709, -71.833],
                        [-26.678, -61.997],
                        [-44.254, -50.961],
                        [-51.797, -57.966],
                        [-61.647, -47.356],
                        [-54.097, -40.347],
                        [-63.798, -22.005],
                        [-73.839, -24.302],
                        [-77.068, -10.188],
                        [-67.03, -7.895],
                        [-66.259, 12.845],
                        [-76.097, 15.874],
                        [-71.836, 29.711],
                        [-61.997, 26.678],
                        [-50.959, 44.253],
                        [-57.967, 51.796],
                        [-47.36, 61.648],
                        [-40.347, 54.095],
                        [-22.003, 63.797],
                        [-24.3, 73.84],
                        [-10.19, 77.068],
                        [-7.895, 67.029],
                        [12.847, 66.258],
                        [15.874, 76.097],
                        [29.708, 71.839],
                        [26.677, 61.998],
                        [44.253, 50.96],
                        [51.796, 57.964],
                        [61.647, 47.359],
                        [54.097, 40.348],
                        [63.798, 22.003],
                        [73.837, 24.302],
                        [77.068, 10.19],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.4549019607843137, 0.7607843137254902,
                      0.8588235294117647, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8130.00033114169,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_2",
      nm: "Gear 56",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "gear 2",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.051, 0.051, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.606],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 16.0000006516934, s: [1090, 1090, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [0, 0],
                    [0.58, -0.578],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.365, 0.206],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.359, 0.766],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.75, 0.349],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.799, -0.214],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.465, -0.667],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-0.375, -0.167],
                    [-0.363, -0.206],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.678, -0.491],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.811, 0.056],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.596, 0.576],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  v: [
                    [15.716, 8.041],
                    [11.462, 7.573],
                    [9.89, 9.473],
                    [11.169, 13.552],
                    [7.298, 15.831],
                    [4.341, 12.738],
                    [1.929, 13.185],
                    [0.272, 17.13],
                    [-4.157, 16.388],
                    [-4.419, 12.119],
                    [-5.53, 11.56],
                    [-6.569, 10.908],
                    [-10.374, 12.867],
                    [-13.29, 9.449],
                    [-10.759, 5.999],
                    [-11.616, 3.701],
                    [-15.792, 2.757],
                    [-15.829, -1.736],
                    [-11.669, -2.752],
                    [-10.835, -5.084],
                    [-13.44, -8.471],
                    [-10.581, -11.936],
                    [-6.745, -10.041],
                    [-6.755, -10.029],
                    [-4.608, -11.289],
                    [-4.632, -11.284],
                    [-4.423, -15.56],
                    [-0.007, -16.376],
                    [1.712, -12.457],
                    [4.134, -12.053],
                    [7.038, -15.194],
                    [10.95, -12.98],
                    [9.749, -8.873],
                    [11.343, -7.003],
                    [11.341, -7.007],
                    [15.587, -7.546],
                    [17.157, -3.338],
                    [13.599, -0.961],
                    [13.62, 1.491],
                    [17.218, 3.808],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.185, 1.768],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.455, -1.057],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.428, -1.711],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.785, -1.612],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.783, -0.444],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.014, 1.51],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.156, -1.79],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.723, -0.462],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.613, 0.749],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.774, 1.648],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.459, 1.728],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.785, 0.444],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.246, -1.246],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [27.798, 2.429],
                        [27.749, -2.919],
                        [35.423, -7.991],
                        [32.04, -17.047],
                        [22.907, -15.888],
                        [19.483, -19.9],
                        [22.063, -28.738],
                        [13.651, -33.502],
                        [7.397, -26.743],
                        [2.196, -27.615],
                        [-1.507, -36.046],
                        [-11.015, -34.287],
                        [-11.411, -25.1],
                        [-16.026, -22.39],
                        [-24.261, -26.492],
                        [-30.413, -19.034],
                        [-24.806, -11.748],
                        [-26.604, -6.7],
                        [-35.552, -4.547],
                        [-35.47, 5.119],
                        [-26.489, 7.104],
                        [-24.62, 12.125],
                        [-30.093, 19.52],
                        [-23.817, 26.873],
                        [-15.63, 22.659],
                        [-13.394, 24.063],
                        [-11.04, 25.259],
                        [-10.442, 34.448],
                        [-0.907, 36.046],
                        [2.657, 27.556],
                        [7.843, 26.597],
                        [14.208, 33.251],
                        [22.538, 28.349],
                        [19.784, 19.569],
                        [23.187, 15.434],
                        [32.319, 16.49],
                        [35.552, 7.38],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8713.00035488776,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_3",
      nm: "Gear 57",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "gear 4",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [815, 815, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-2.717, 0],
                    [0, -2.715],
                    [2.715, 0],
                    [0, 2.716],
                  ],
                  o: [
                    [2.715, 0],
                    [0, 2.716],
                    [-2.717, 0],
                    [0, -2.715],
                  ],
                  v: [
                    [19.977, -23.792],
                    [24.893, -18.876],
                    [19.977, -13.959],
                    [15.059, -18.876],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-2.715, 0],
                    [0, -2.716],
                    [2.717, 0],
                    [0, 2.715],
                  ],
                  o: [
                    [2.717, 0],
                    [0, 2.715],
                    [-2.715, 0],
                    [0, -2.716],
                  ],
                  v: [
                    [0.498, -31.292],
                    [5.416, -26.375],
                    [0.498, -21.459],
                    [-4.418, -26.375],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.717, 0],
                    [0, 2.716],
                  ],
                  o: [
                    [2.717, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  v: [
                    [-18.979, -23.792],
                    [-14.061, -18.876],
                    [-18.979, -13.959],
                    [-23.895, -18.876],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.715, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.715, 0],
                    [0, 2.716],
                  ],
                  v: [
                    [-27.002, 5.32],
                    [-31.918, 0.403],
                    [-27.002, -4.513],
                    [-22.086, 0.403],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.717, 0],
                    [0, 2.715],
                    [-2.715, 0],
                    [0, -2.716],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.716],
                    [2.717, 0],
                    [0, 2.715],
                  ],
                  v: [
                    [-18.979, 24.126],
                    [-23.895, 19.21],
                    [-18.979, 14.293],
                    [-14.061, 19.21],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.717, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.717, 0],
                    [0, 2.716],
                  ],
                  v: [
                    [0.498, 32.292],
                    [-4.418, 27.375],
                    [0.498, 22.459],
                    [5.416, 27.375],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [6.137, -0.465],
                    [3.84, 4.759],
                    [0, 0],
                    [0.217, 2.881],
                    [-9.656, 1.669],
                    [0, 0],
                    [-0.766, -10.132],
                    [1.256, -2.819],
                    [0, 0],
                  ],
                  o: [
                    [-6.559, 0.496],
                    [0, 0],
                    [-1.322, -2.365],
                    [-0.756, -9.973],
                    [0, 0],
                    [10.004, -0.042],
                    [0.25, 3.296],
                    [0, 0],
                    [-3.174, 4.748],
                  ],
                  v: [
                    [1.951, 19.686],
                    [-14.484, 12.568],
                    [-16.305, 9.875],
                    [-18.689, 1.947],
                    [-2.801, -18.463],
                    [0.437, -18.744],
                    [19.689, -0.956],
                    [18.086, 8.322],
                    [16.504, 11.177],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.715, 0],
                    [0, 2.715],
                    [-2.717, 0],
                    [0, -2.716],
                  ],
                  o: [
                    [-2.717, 0],
                    [0, -2.716],
                    [2.715, 0],
                    [0, 2.715],
                  ],
                  v: [
                    [19.977, 24.126],
                    [15.059, 19.21],
                    [19.977, 14.293],
                    [24.893, 19.21],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 8",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.715, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.715, 0],
                    [0, 2.716],
                  ],
                  v: [
                    [28, 5.32],
                    [23.084, 0.403],
                    [28, -4.513],
                    [32.916, 0.403],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 9",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.262, 1.142],
                        [0, 0],
                        [0, 0],
                        [0.043, 0.58],
                        [0.068, 0.572],
                        [0, 0],
                        [0, 0],
                        [0.527, 1.033],
                        [0, 0],
                        [0, 0],
                        [0.846, 0.787],
                        [0, 0],
                        [0, 0],
                        [1.068, 0.452],
                        [0, 0],
                        [0, 0],
                        [1.164, 0.059],
                        [0, 0],
                        [0, 0],
                        [1.1, -0.336],
                        [0, 0],
                        [0, 0],
                        [0.922, -0.699],
                        [0, 0],
                        [0, 0],
                        [0.633, -0.973],
                        [0, 0],
                        [0, 0],
                        [0.264, -1.143],
                        [0, 0],
                        [0, 0],
                        [-0.043, -0.582],
                        [-0.068, -0.571],
                        [0, 0],
                        [0, 0],
                        [-0.527, -1.035],
                        [0, 0],
                        [0, 0],
                        [-0.848, -0.789],
                        [0, 0],
                        [0, 0],
                        [-1.07, -0.453],
                        [0, 0],
                        [0, 0],
                        [-1.096, -0.063],
                        [0, 0],
                        [0, 0],
                        [-1.16, 0.355],
                        [0, 0],
                        [0, 0],
                        [-0.922, 0.698],
                        [0, 0],
                        [0, 0],
                        [-0.629, 0.972],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.018, -0.575],
                        [-0.045, -0.583],
                        [0, 0],
                        [0, 0],
                        [-0.432, -1.09],
                        [0, 0],
                        [0, 0],
                        [-0.769, -0.867],
                        [0, 0],
                        [0, 0],
                        [-1.016, -0.549],
                        [0, 0],
                        [0, 0],
                        [-1.137, -0.167],
                        [0, 0],
                        [0, 0],
                        [-1.143, 0.234],
                        [0, 0],
                        [0, 0],
                        [-0.988, 0.61],
                        [0, 0],
                        [0, 0],
                        [-0.719, 0.906],
                        [0, 0],
                        [0, 0],
                        [-0.367, 1.102],
                        [0, 0],
                        [0, 0],
                        [0.017, 0.577],
                        [0.045, 0.581],
                        [0, 0],
                        [0, 0],
                        [0.432, 1.089],
                        [0, 0],
                        [0, 0],
                        [0.77, 0.866],
                        [0, 0],
                        [0, 0],
                        [1.018, 0.55],
                        [0, 0],
                        [0, 0],
                        [1.07, 0.158],
                        [0, 0],
                        [0, 0],
                        [1.209, -0.241],
                        [0, 0],
                        [0, 0],
                        [0.99, -0.609],
                        [0, 0],
                        [0, 0],
                        [0.719, -0.905],
                        [0, 0],
                        [0, 0],
                        [0.365, -1.101],
                      ],
                      v: [
                        [35.738, 8.204],
                        [42.627, 4.23],
                        [36.643, -1.038],
                        [36.557, -2.771],
                        [36.381, -4.501],
                        [41.506, -10.603],
                        [34.096, -13.501],
                        [32.654, -16.687],
                        [35.377, -24.161],
                        [27.404, -24.349],
                        [24.979, -26.83],
                        [24.982, -34.805],
                        [17.424, -32.254],
                        [14.297, -33.759],
                        [11.574, -41.25],
                        [5.348, -36.271],
                        [1.895, -36.608],
                        [-3.232, -42.722],
                        [-7.381, -35.907],
                        [-10.744, -35.053],
                        [-17.646, -39.041],
                        [-19.211, -31.225],
                        [-22.078, -29.263],
                        [-29.934, -30.65],
                        [-28.73, -22.768],
                        [-30.756, -19.947],
                        [-38.609, -18.565],
                        [-34.791, -11.583],
                        [-35.738, -8.216],
                        [-42.627, -4.241],
                        [-36.643, 1.022],
                        [-36.557, 2.76],
                        [-36.381, 4.489],
                        [-41.506, 10.594],
                        [-34.098, 13.488],
                        [-32.654, 16.676],
                        [-35.377, 24.149],
                        [-27.406, 24.335],
                        [-24.979, 26.819],
                        [-24.982, 34.793],
                        [-17.428, 32.24],
                        [-14.297, 33.748],
                        [-11.572, 41.239],
                        [-5.35, 36.258],
                        [-2.098, 36.577],
                        [3.055, 42.722],
                        [7.189, 35.931],
                        [10.746, 35.04],
                        [17.645, 39.027],
                        [19.213, 31.212],
                        [22.08, 29.251],
                        [29.932, 30.638],
                        [28.732, 22.753],
                        [30.756, 19.935],
                        [38.607, 18.554],
                        [34.793, 11.569],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 9145.00037248349,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_4",
      nm: "Gear 57",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "sub",
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.208, -3.567],
                        [3.571, 0.203],
                        [-0.204, 3.569],
                        [-3.57, -0.207],
                      ],
                      o: [
                        [-0.204, 3.577],
                        [-3.575, -0.212],
                        [0.21, -3.575],
                        [3.57, 0.209],
                      ],
                      v: [
                        [6.467, 0.369],
                        [-0.373, 6.47],
                        [-6.47, -0.374],
                        [0.375, -6.469],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.375, -6.441],
                        [6.441, 0.374],
                        [-0.372, 6.442],
                        [-6.441, -0.374],
                      ],
                      o: [
                        [-0.373, 6.437],
                        [-6.443, -0.37],
                        [0.369, -6.439],
                        [6.439, 0.372],
                      ],
                      v: [
                        [11.662, 0.673],
                        [-0.674, 11.66],
                        [-11.662, -0.677],
                        [0.674, -11.663],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.584, -10.042],
                        [10.037, 0.577],
                        [-0.579, 10.042],
                        [-10.039, -0.582],
                      ],
                      o: [
                        [-0.58, 10.035],
                        [-10.039, -0.583],
                        [0.579, -10.038],
                        [10.039, 0.58],
                      ],
                      v: [
                        [18.176, 1.052],
                        [-1.051, 18.177],
                        [-18.177, -1.055],
                        [1.051, -18.175],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 9854.00040136165,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 8",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [739, 739, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0.01, -14.334],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [13.961, 2.444],
                  ],
                  v: [
                    [29.918, 0.492],
                    [4.014, -1.007],
                    [5.607, -28.471],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-14.704, 0.224],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [1.693, -14.657],
                  ],
                  v: [
                    [0.027, -28.91],
                    [-1.569, -1.33],
                    [-28.717, -2.9],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [1.016, 13.784],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-13.856, -1.821],
                  ],
                  v: [
                    [-28.83, 2.693],
                    [-1.89, 4.252],
                    [-3.359, 29.653],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [13.422, -0.773],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-2.524, 13.168],
                  ],
                  v: [
                    [2.226, 29.861],
                    [3.691, 4.573],
                    [29.384, 6.06],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.752, 4.541],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.555, 4.14],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.478, 2.869],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.544, 0.753],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.141, -1.554],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.867, -3.479],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.752, -4.545],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.556, -4.14],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.48, -2.867],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.544, -0.754],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.141, 1.554],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.867, 3.477],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.227, -4.599],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.448, -3.784],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.938, -2.016],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.6, 0.226],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.785, 2.446],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.021, 3.934],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.225, 4.602],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.448, 3.784],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.935, 2.018],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.6, -0.227],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.784, -2.448],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.021, -3.936],
                      ],
                      v: [
                        [43.135, 7.19],
                        [49.793, 7.576],
                        [50.338, -1.786],
                        [43.679, -2.177],
                        [40.953, -15.343],
                        [46.912, -18.336],
                        [42.703, -26.72],
                        [36.735, -23.72],
                        [27.794, -33.762],
                        [31.459, -39.334],
                        [23.622, -44.489],
                        [19.959, -38.919],
                        [7.192, -43.143],
                        [7.576, -49.797],
                        [-1.787, -50.339],
                        [-2.17, -43.681],
                        [-15.338, -40.957],
                        [-18.333, -46.917],
                        [-26.714, -42.705],
                        [-23.717, -36.738],
                        [-33.756, -27.795],
                        [-39.332, -31.465],
                        [-44.488, -23.627],
                        [-38.908, -19.963],
                        [-43.135, -7.196],
                        [-49.793, -7.583],
                        [-50.338, 1.78],
                        [-43.678, 2.166],
                        [-40.951, 15.335],
                        [-46.916, 18.331],
                        [-42.703, 26.713],
                        [-36.735, 23.713],
                        [-27.791, 33.753],
                        [-31.459, 39.331],
                        [-23.621, 44.486],
                        [-19.959, 38.911],
                        [-7.191, 43.133],
                        [-7.578, 49.792],
                        [1.785, 50.339],
                        [2.172, 43.676],
                        [15.34, 40.95],
                        [18.334, 46.909],
                        [26.717, 42.7],
                        [23.717, 36.733],
                        [33.756, 27.791],
                        [39.332, 31.455],
                        [44.485, 23.618],
                        [38.912, 19.955],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 9854.00040136165,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_5",
      nm: "Gear 58",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "sub 3",
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.5, -6.628],
                        [6.625, -0.502],
                        [0.502, 6.628],
                        [-6.629, 0.502],
                      ],
                      o: [
                        [0.504, 6.627],
                        [-6.629, 0.502],
                        [-0.502, -6.629],
                        [6.625, -0.501],
                      ],
                      v: [
                        [12, -0.916],
                        [0.91, 11.992],
                        [-12, 0.899],
                        [-0.906, -12.01],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.902, -11.951],
                        [11.949, -0.904],
                        [0.906, 11.952],
                        [-11.951, 0.905],
                      ],
                      o: [
                        [0.904, 11.953],
                        [-11.953, 0.904],
                        [-0.902, -11.951],
                        [11.951, -0.904],
                      ],
                      v: [
                        [21.641, -1.646],
                        [1.639, 21.632],
                        [-21.641, 1.628],
                        [-1.637, -21.649],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8726.00035541727,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 10",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [649, 649, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [11.928, -0.902],
                    [7.465, 9.248],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [0.422, 5.598],
                    [-18.768, 3.244],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-1.488, -19.691],
                    [2.443, -5.48],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-12.746, 0.965],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-2.568, -4.599],
                    [-1.467, -19.381],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [19.445, -0.081],
                    [0.484, 6.404],
                    [0, 0],
                    [0, 0],
                    [0, 0],
                    [-6.168, 9.228],
                  ],
                  v: [
                    [3.32, 37.787],
                    [-28.621, 23.955],
                    [-11.627, 12.869],
                    [-15.078, 7.578],
                    [-32.158, 18.722],
                    [-36.793, 3.313],
                    [-5.914, -36.352],
                    [-4.361, -15.806],
                    [1.937, -16.281],
                    [0.379, -36.898],
                    [37.795, -2.328],
                    [34.676, 15.704],
                    [18.086, 4.488],
                    [14.549, 9.721],
                    [31.602, 21.251],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [-0.342, 1.487],
                        [0, 0],
                        [0, 0],
                        [0.057, 0.757],
                        [0.092, 0.746],
                        [0, 0],
                        [0, 0],
                        [0.688, 1.345],
                        [0, 0],
                        [0, 0],
                        [1.102, 1.025],
                        [0, 0],
                        [0, 0],
                        [1.393, 0.59],
                        [0, 0],
                        [0, 0],
                        [1.516, 0.077],
                        [0, 0],
                        [0, 0],
                        [1.432, -0.436],
                        [0, 0],
                        [0, 0],
                        [1.201, -0.91],
                        [0, 0],
                        [0, 0],
                        [0.822, -1.267],
                        [0, 0],
                        [0, 0],
                        [0.34, -1.488],
                        [0, 0],
                        [0, 0],
                        [-0.057, -0.757],
                        [-0.092, -0.745],
                        [0, 0],
                        [0, 0],
                        [-0.688, -1.349],
                        [0, 0],
                        [0, 0],
                        [-1.103, -1.028],
                        [0, 0],
                        [0, 0],
                        [-1.394, -0.59],
                        [0, 0],
                        [0, 0],
                        [-1.428, -0.083],
                        [0, 0],
                        [0, 0],
                        [-1.51, 0.462],
                        [0, 0],
                        [0, 0],
                        [-1.199, 0.91],
                        [0, 0],
                        [0, 0],
                        [-0.82, 1.266],
                      ],
                      o: [
                        [0, 0],
                        [0.477, -1.434],
                        [0, 0],
                        [0, 0],
                        [-0.021, -0.75],
                        [-0.059, -0.758],
                        [0, 0],
                        [0, 0],
                        [-0.561, -1.419],
                        [0, 0],
                        [0, 0],
                        [-1.002, -1.129],
                        [0, 0],
                        [0, 0],
                        [-1.322, -0.716],
                        [0, 0],
                        [0, 0],
                        [-1.478, -0.217],
                        [0, 0],
                        [0, 0],
                        [-1.488, 0.305],
                        [0, 0],
                        [0, 0],
                        [-1.287, 0.795],
                        [0, 0],
                        [0, 0],
                        [-0.936, 1.18],
                        [0, 0],
                        [0, 0],
                        [-0.478, 1.436],
                        [0, 0],
                        [0, 0],
                        [0.023, 0.751],
                        [0.059, 0.758],
                        [0, 0],
                        [0, 0],
                        [0.563, 1.419],
                        [0, 0],
                        [0, 0],
                        [1.004, 1.127],
                        [0, 0],
                        [0, 0],
                        [1.324, 0.717],
                        [0, 0],
                        [0, 0],
                        [1.396, 0.206],
                        [0, 0],
                        [0, 0],
                        [1.574, -0.315],
                        [0, 0],
                        [0, 0],
                        [1.287, -0.794],
                        [0, 0],
                        [0, 0],
                        [0.936, -1.18],
                        [0, 0],
                      ],
                      v: [
                        [50.287, 24.167],
                        [45.318, 15.069],
                        [46.551, 10.687],
                        [55.523, 5.51],
                        [47.729, -1.352],
                        [47.617, -3.609],
                        [47.387, -5.863],
                        [54.064, -13.813],
                        [44.41, -17.586],
                        [42.533, -21.734],
                        [46.08, -31.471],
                        [35.695, -31.716],
                        [32.537, -34.947],
                        [32.541, -45.335],
                        [22.695, -42.012],
                        [18.623, -43.973],
                        [15.076, -53.73],
                        [6.965, -47.244],
                        [2.469, -47.685],
                        [-4.209, -55.648],
                        [-9.613, -46.771],
                        [-13.994, -45.658],
                        [-22.986, -50.854],
                        [-25.023, -40.672],
                        [-28.758, -38.116],
                        [-38.99, -39.924],
                        [-37.422, -29.655],
                        [-40.061, -25.982],
                        [-50.289, -24.182],
                        [-45.316, -15.089],
                        [-46.549, -10.702],
                        [-55.523, -5.524],
                        [-47.729, 1.332],
                        [-47.615, 3.594],
                        [-47.387, 5.848],
                        [-54.063, 13.799],
                        [-44.412, 17.568],
                        [-42.533, 21.721],
                        [-46.078, 31.456],
                        [-35.699, 31.698],
                        [-32.535, 34.934],
                        [-32.539, 45.318],
                        [-22.699, 41.994],
                        [-18.621, 43.958],
                        [-15.074, 53.715],
                        [-6.969, 47.227],
                        [-2.73, 47.645],
                        [3.979, 55.648],
                        [9.365, 46.802],
                        [13.996, 45.642],
                        [22.984, 50.836],
                        [25.027, 40.655],
                        [28.76, 38.101],
                        [38.986, 39.907],
                        [37.426, 29.638],
                        [40.062, 25.966],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8726.00035541727,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_6",
      nm: "Gear 58",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 13",
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.193, -5.228],
                        [5.229, -0.194],
                        [0.193, 5.231],
                        [-5.228, 0.192],
                      ],
                      o: [
                        [0.191, 5.229],
                        [-5.228, 0.191],
                        [-0.193, -5.227],
                        [5.229, -0.192],
                      ],
                      v: [
                        [9.469, -0.348],
                        [0.348, 9.47],
                        [-9.469, 0.349],
                        [-0.35, -9.468],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.4549019607843137, 0.7607843137254902,
                      0.8588235294117647, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.299, -8.144],
                        [8.144, -0.3],
                        [0.299, 8.143],
                        [-8.143, 0.3],
                      ],
                      o: [
                        [0.299, 8.142],
                        [-8.143, 0.3],
                        [-0.299, -8.144],
                        [8.144, -0.3],
                      ],
                      v: [
                        [14.746, -0.543],
                        [0.543, 14.746],
                        [-14.746, 0.543],
                        [-0.545, -14.747],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.891, 1.825],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.715, 1.159],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.109, 0.148],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.828, -0.89],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.158, -1.715],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.147, -2.11],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.891, -1.825],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.715, -1.16],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.109, -0.146],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.826, 0.891],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.16, 1.714],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.148, 2.11],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.303, -2.094],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.283, -1.624],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.887, -0.753],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.092, 0.303],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.623, 1.284],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.754, 1.886],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.301, 2.094],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.283, 1.622],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.889, 0.754],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.092, -0.302],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.625, -1.284],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.754, -1.887],
                        [0, 0],
                      ],
                      v: [
                        [23.092, 1.304],
                        [22.934, -3.001],
                        [19.873, -2.887],
                        [18.057, -8.789],
                        [20.65, -10.416],
                        [18.361, -14.066],
                        [15.766, -12.437],
                        [11.24, -16.639],
                        [12.676, -19.346],
                        [8.869, -21.363],
                        [7.435, -18.656],
                        [1.418, -20.033],
                        [1.305, -23.093],
                        [-3, -22.934],
                        [-2.889, -19.874],
                        [-8.791, -18.057],
                        [-10.418, -20.651],
                        [-14.066, -18.362],
                        [-12.438, -15.766],
                        [-16.637, -11.242],
                        [-19.346, -12.676],
                        [-21.361, -8.87],
                        [-18.654, -7.436],
                        [-20.031, -1.417],
                        [-23.092, -1.305],
                        [-22.936, 3],
                        [-19.873, 2.887],
                        [-18.057, 8.789],
                        [-20.65, 10.417],
                        [-18.363, 14.065],
                        [-15.766, 12.437],
                        [-11.24, 16.638],
                        [-12.676, 19.346],
                        [-8.869, 21.364],
                        [-7.438, 18.655],
                        [-1.416, 20.031],
                        [-1.305, 23.092],
                        [3.002, 22.934],
                        [2.889, 19.873],
                        [8.789, 18.056],
                        [10.418, 20.65],
                        [14.066, 18.362],
                        [12.435, 15.766],
                        [16.639, 11.241],
                        [19.346, 12.675],
                        [21.361, 8.87],
                        [18.654, 7.436],
                        [20.033, 1.415],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.4549019607843137, 0.7607843137254902,
                      0.8588235294117647, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8827.00035953108,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 12",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.051, 0.051, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.587],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 16.0000006516934, s: [838, 838, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [14.69, -0.888],
                    [0.889, 14.69],
                    [-14.691, 0.888],
                    [-0.887, -14.688],
                  ],
                  o: [
                    [-14.689, 0.887],
                    [-0.887, -14.69],
                    [14.69, -0.888],
                    [0.889, 14.691],
                  ],
                  v: [
                    [1.982, 27.098],
                    [-26.225, 2.105],
                    [-1.23, -26.1],
                    [26.973, -1.109],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.057, 0.925],
                        [0.096, 0.771],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.773, 1.5],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.248, 1.151],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.574, 0.66],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.791, 0.073],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.604, -0.508],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.344, -1.037],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.912, -1.435],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.367, -1.675],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.053, -0.856],
                        [-0.111, -0.836],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.783, -1.497],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.252, -1.143],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.578, -0.653],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.723, -0.07],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.603, 0.508],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.34, 1.036],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.885, 1.374],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.377, 1.671],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-0.047, -0.785],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.557, -1.62],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.072, -1.321],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.451, -0.875],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.717, -0.336],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.699, 0.275],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.488, 0.836],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.108, 1.287],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.6, 1.58],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.012, 0.844],
                        [0.051, 0.857],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.566, 1.618],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.08, 1.314],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.457, 0.867],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.65, 0.312],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.699, -0.274],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.488, -0.836],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.061, -1.237],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.607, -1.575],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.016, -0.911],
                      ],
                      v: [
                        [34.92, -2.118],
                        [34.695, -4.449],
                        [40.369, -5.807],
                        [38.709, -12.754],
                        [33.08, -11.408],
                        [31.08, -16.093],
                        [35.941, -19.307],
                        [32.004, -25.268],
                        [27.17, -22.075],
                        [23.688, -25.788],
                        [27.164, -30.479],
                        [21.424, -34.732],
                        [17.979, -30.083],
                        [13.435, -32.39],
                        [15.094, -37.974],
                        [8.244, -40.009],
                        [6.602, -34.469],
                        [1.334, -35.085],
                        [1, -40.6],
                        [-6.129, -40.171],
                        [-5.797, -34.654],
                        [-10.754, -33.469],
                        [-12.953, -38.539],
                        [-19.506, -35.694],
                        [-17.309, -30.627],
                        [-21.559, -27.812],
                        [-25.359, -31.826],
                        [-30.545, -26.914],
                        [-26.736, -22.892],
                        [-29.769, -18.803],
                        [-34.723, -21.276],
                        [-37.916, -14.885],
                        [-32.965, -12.414],
                        [-34.418, -7.53],
                        [-39.912, -8.161],
                        [-40.727, -1.063],
                        [-35.223, -0.431],
                        [-35.17, 2.116],
                        [-34.916, 4.65],
                        [-40.305, 5.939],
                        [-38.643, 12.887],
                        [-33.262, 11.599],
                        [-31.234, 16.274],
                        [-35.85, 19.324],
                        [-31.91, 25.284],
                        [-27.291, 22.232],
                        [-23.789, 25.925],
                        [-27.086, 30.376],
                        [-21.346, 34.63],
                        [-18.055, 30.188],
                        [-13.498, 32.47],
                        [-15.07, 37.765],
                        [-8.221, 39.8],
                        [-6.648, 34.503],
                        [-1.584, 35.082],
                        [-1.254, 40.6],
                        [5.879, 40.168],
                        [5.545, 34.65],
                        [10.502, 33.466],
                        [12.701, 38.537],
                        [19.256, 35.693],
                        [17.059, 30.624],
                        [21.305, 27.811],
                        [25.107, 31.825],
                        [30.295, 26.912],
                        [26.486, 22.892],
                        [29.41, 18.975],
                        [34.629, 21.581],
                        [37.82, 15.191],
                        [32.643, 12.604],
                        [34.123, 7.73],
                        [39.914, 8.393],
                        [40.727, 1.295],
                        [34.971, 0.635],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.4549019607843137, 0.7607843137254902,
                      0.8588235294117647, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8827.00035953108,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_7",
      nm: "Gear 59",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "sub",
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 59.833, ix: 10 },
            p: { a: 0, k: [0.92, 0.683, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0.536, 0.428, 0], ix: 1, l: 2 },
            s: { a: 0, k: [62.986, 62.986, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-1.109, -7.239],
                    [-0.025, -0.614],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.096, 0.623],
                    [0, 0],
                    [0, 0],
                    [6.813, 1.274],
                  ],
                  v: [
                    [17.086, -2.038],
                    [17.26, -0.182],
                    [5.951, -2.838],
                    [3.584, -15.977],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-7.756, 1.186],
                    [-0.527, 0.03],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0.533, -0.081],
                    [0, 0],
                    [0, 0],
                    [0.713, -7.47],
                  ],
                  v: [
                    [-2.041, -16.083],
                    [-0.451, -16.246],
                    [-4.043, -3.456],
                    [-16.187, -1.104],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [1.199, 7.387],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [0, 0],
                    [-7.051, -1.033],
                  ],
                  v: [
                    [-16.055, 3.194],
                    [-4.744, 6.687],
                    [-1.943, 17.101],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 8",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [7.059, -1.078],
                    [0.035, -0.005],
                    [0, 0],
                    [0, 0],
                  ],
                  o: [
                    [-0.037, 0.006],
                    [0, 0],
                    [0, 0],
                    [-1.443, 6.581],
                  ],
                  v: [
                    [3.039, 17.085],
                    [2.932, 17.097],
                    [6.232, 6.542],
                    [16.877, 4.086],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 9",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [2.256, 14.736],
                        [14.736, -2.257],
                        [-2.256, -14.736],
                        [-14.736, 2.257],
                      ],
                      o: [
                        [-2.258, -14.741],
                        [-14.74, 2.257],
                        [2.258, 14.74],
                        [14.74, -2.257],
                      ],
                      v: [
                        [26.686, -4.083],
                        [-4.086, -26.686],
                        [-26.687, 4.086],
                        [4.084, 26.686],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 12112.0004933319,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "gear 7",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0.499, 0.5, 0], ix: 1, l: 2 },
            s: { a: 0, k: [850, 850, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-2.717, 0],
                    [0, -2.715],
                    [2.715, 0],
                    [0, 2.716],
                  ],
                  o: [
                    [2.715, 0],
                    [0, 2.716],
                    [-2.717, 0],
                    [0, -2.715],
                  ],
                  v: [
                    [19.977, -23.792],
                    [24.893, -18.876],
                    [19.977, -13.959],
                    [15.059, -18.876],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-2.715, 0],
                    [0, -2.716],
                    [2.717, 0],
                    [0, 2.715],
                  ],
                  o: [
                    [2.717, 0],
                    [0, 2.715],
                    [-2.715, 0],
                    [0, -2.716],
                  ],
                  v: [
                    [0.498, -31.292],
                    [5.416, -26.375],
                    [0.498, -21.459],
                    [-4.418, -26.375],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.717, 0],
                    [0, 2.716],
                  ],
                  o: [
                    [2.717, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  v: [
                    [-18.979, -23.792],
                    [-14.061, -18.876],
                    [-18.979, -13.959],
                    [-23.895, -18.876],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.715, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.715, 0],
                    [0, 2.716],
                  ],
                  v: [
                    [-27.002, 5.32],
                    [-31.918, 0.403],
                    [-27.002, -4.513],
                    [-22.086, 0.403],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.717, 0],
                    [0, 2.715],
                    [-2.715, 0],
                    [0, -2.716],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.716],
                    [2.717, 0],
                    [0, 2.715],
                  ],
                  v: [
                    [-18.979, 24.126],
                    [-23.895, 19.21],
                    [-18.979, 14.293],
                    [-14.061, 19.21],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.717, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.717, 0],
                    [0, 2.716],
                  ],
                  v: [
                    [0.498, 32.292],
                    [-4.418, 27.375],
                    [0.498, 22.459],
                    [5.416, 27.375],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [6.137, -0.465],
                    [3.84, 4.759],
                    [0, 0],
                    [0.217, 2.881],
                    [-9.656, 1.669],
                    [0, 0],
                    [-0.766, -10.132],
                    [1.256, -2.819],
                    [0, 0],
                  ],
                  o: [
                    [-6.559, 0.496],
                    [0, 0],
                    [-1.322, -2.365],
                    [-0.756, -9.973],
                    [0, 0],
                    [10.004, -0.042],
                    [0.25, 3.296],
                    [0, 0],
                    [-3.174, 4.748],
                  ],
                  v: [
                    [1.951, 19.686],
                    [-14.484, 12.568],
                    [-16.305, 9.875],
                    [-18.689, 1.947],
                    [-2.801, -18.463],
                    [0.437, -18.744],
                    [19.689, -0.956],
                    [18.086, 8.322],
                    [16.504, 11.177],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.715, 0],
                    [0, 2.715],
                    [-2.717, 0],
                    [0, -2.716],
                  ],
                  o: [
                    [-2.717, 0],
                    [0, -2.716],
                    [2.715, 0],
                    [0, 2.715],
                  ],
                  v: [
                    [19.977, 24.126],
                    [15.059, 19.21],
                    [19.977, 14.293],
                    [24.893, 19.21],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 8",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [2.715, 0],
                    [0, 2.716],
                    [-2.715, 0],
                    [0, -2.715],
                  ],
                  o: [
                    [-2.715, 0],
                    [0, -2.715],
                    [2.715, 0],
                    [0, 2.716],
                  ],
                  v: [
                    [28, 5.32],
                    [23.084, 0.403],
                    [28, -4.513],
                    [32.916, 0.403],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 9",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-0.262, 1.142],
                        [0, 0],
                        [0, 0],
                        [0.043, 0.58],
                        [0.068, 0.572],
                        [0, 0],
                        [0, 0],
                        [0.527, 1.033],
                        [0, 0],
                        [0, 0],
                        [0.846, 0.787],
                        [0, 0],
                        [0, 0],
                        [1.068, 0.452],
                        [0, 0],
                        [0, 0],
                        [1.164, 0.059],
                        [0, 0],
                        [0, 0],
                        [1.1, -0.336],
                        [0, 0],
                        [0, 0],
                        [0.922, -0.699],
                        [0, 0],
                        [0, 0],
                        [0.633, -0.973],
                        [0, 0],
                        [0, 0],
                        [0.264, -1.143],
                        [0, 0],
                        [0, 0],
                        [-0.043, -0.582],
                        [-0.068, -0.571],
                        [0, 0],
                        [0, 0],
                        [-0.527, -1.035],
                        [0, 0],
                        [0, 0],
                        [-0.848, -0.789],
                        [0, 0],
                        [0, 0],
                        [-1.07, -0.453],
                        [0, 0],
                        [0, 0],
                        [-1.096, -0.063],
                        [0, 0],
                        [0, 0],
                        [-1.16, 0.355],
                        [0, 0],
                        [0, 0],
                        [-0.922, 0.698],
                        [0, 0],
                        [0, 0],
                        [-0.629, 0.972],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-0.018, -0.575],
                        [-0.045, -0.583],
                        [0, 0],
                        [0, 0],
                        [-0.432, -1.09],
                        [0, 0],
                        [0, 0],
                        [-0.769, -0.867],
                        [0, 0],
                        [0, 0],
                        [-1.016, -0.549],
                        [0, 0],
                        [0, 0],
                        [-1.137, -0.167],
                        [0, 0],
                        [0, 0],
                        [-1.143, 0.234],
                        [0, 0],
                        [0, 0],
                        [-0.988, 0.61],
                        [0, 0],
                        [0, 0],
                        [-0.719, 0.906],
                        [0, 0],
                        [0, 0],
                        [-0.367, 1.102],
                        [0, 0],
                        [0, 0],
                        [0.017, 0.577],
                        [0.045, 0.581],
                        [0, 0],
                        [0, 0],
                        [0.432, 1.089],
                        [0, 0],
                        [0, 0],
                        [0.77, 0.866],
                        [0, 0],
                        [0, 0],
                        [1.018, 0.55],
                        [0, 0],
                        [0, 0],
                        [1.07, 0.158],
                        [0, 0],
                        [0, 0],
                        [1.209, -0.241],
                        [0, 0],
                        [0, 0],
                        [0.99, -0.609],
                        [0, 0],
                        [0, 0],
                        [0.719, -0.905],
                        [0, 0],
                        [0, 0],
                        [0.365, -1.101],
                      ],
                      v: [
                        [35.738, 8.204],
                        [42.627, 4.23],
                        [36.643, -1.038],
                        [36.557, -2.771],
                        [36.381, -4.501],
                        [41.506, -10.603],
                        [34.096, -13.501],
                        [32.654, -16.687],
                        [35.377, -24.161],
                        [27.404, -24.349],
                        [24.979, -26.83],
                        [24.982, -34.805],
                        [17.424, -32.254],
                        [14.297, -33.759],
                        [11.574, -41.25],
                        [5.348, -36.271],
                        [1.895, -36.608],
                        [-3.232, -42.722],
                        [-7.381, -35.907],
                        [-10.744, -35.053],
                        [-17.646, -39.041],
                        [-19.211, -31.225],
                        [-22.078, -29.263],
                        [-29.934, -30.65],
                        [-28.73, -22.768],
                        [-30.756, -19.947],
                        [-38.609, -18.565],
                        [-34.791, -11.583],
                        [-35.738, -8.216],
                        [-42.627, -4.241],
                        [-36.643, 1.022],
                        [-36.557, 2.76],
                        [-36.381, 4.489],
                        [-41.506, 10.594],
                        [-34.098, 13.488],
                        [-32.654, 16.676],
                        [-35.377, 24.149],
                        [-27.406, 24.335],
                        [-24.979, 26.819],
                        [-24.982, 34.793],
                        [-17.428, 32.24],
                        [-14.297, 33.748],
                        [-11.572, 41.239],
                        [-5.35, 36.258],
                        [-2.098, 36.577],
                        [3.055, 42.722],
                        [7.189, 35.931],
                        [10.746, 35.04],
                        [17.645, 39.027],
                        [19.213, 31.212],
                        [22.08, 29.251],
                        [29.932, 30.638],
                        [28.732, 22.753],
                        [30.756, 19.935],
                        [38.607, 18.554],
                        [34.793, 11.569],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.4549019607843137, 0.7607843137254902,
                      0.8588235294117647, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 12112.0004933319,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_8",
      nm: "Gear 59",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 21",
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 333.026, ix: 10 },
            p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [87.2, 87.2, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.209, -3.567],
                        [3.572, 0.203],
                        [-0.205, 3.569],
                        [-3.57, -0.207],
                      ],
                      o: [
                        [-0.203, 3.577],
                        [-3.574, -0.212],
                        [0.209, -3.575],
                        [3.57, 0.209],
                      ],
                      v: [
                        [6.467, 0.371],
                        [-0.373, 6.472],
                        [-6.469, -0.372],
                        [0.375, -6.467],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0.375, -6.441],
                        [6.441, 0.374],
                        [-0.371, 6.442],
                        [-6.441, -0.374],
                      ],
                      o: [
                        [-0.373, 6.436],
                        [-6.443, -0.37],
                        [0.369, -6.44],
                        [6.439, 0.372],
                      ],
                      v: [
                        [11.662, 0.675],
                        [-0.674, 11.662],
                        [-11.662, -0.675],
                        [0.674, -11.661],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: { a: 0, k: [1, 1, 1, 1], ix: 4 },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0.021, 0.377],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.668, 0.335],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.619, -0.412],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, -0.382],
                        [-0.023, -0.377],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.668, -0.335],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.619, 0.411],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.021, -0.377],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.619, -0.41],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.668, 0.335],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.023, 0.376],
                        [0, 0.382],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.619, 0.41],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.668, -0.335],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [18.502, 1.13],
                        [18.502, -1.132],
                        [38.518, -19.037],
                        [37.133, -21.438],
                        [35.746, -23.842],
                        [10.225, -15.481],
                        [8.285, -16.59],
                        [2.773, -42.876],
                        [0, -42.876],
                        [0, -42.876],
                        [-2.773, -42.876],
                        [-8.285, -16.59],
                        [-10.225, -15.479],
                        [-35.744, -23.839],
                        [-37.131, -21.438],
                        [-38.518, -19.037],
                        [-18.5, -1.133],
                        [-18.557, -0.002],
                        [-18.5, 1.132],
                        [-38.516, 19.036],
                        [-37.131, 21.438],
                        [-35.744, 23.839],
                        [-10.225, 15.478],
                        [-8.285, 16.586],
                        [-2.773, 42.873],
                        [0, 42.876],
                        [2.773, 42.876],
                        [8.285, 16.586],
                        [10.225, 15.477],
                        [35.744, 23.837],
                        [37.133, 21.438],
                        [38.518, 19.036],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 12099.0004928024,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 20",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [506, 506, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [22.29, 0],
                    [0, -22.429],
                    [-22.29, 0],
                    [0, 22.429],
                  ],
                  o: [
                    [-22.29, 0],
                    [0, 22.429],
                    [22.29, 0],
                    [0, -22.429],
                  ],
                  v: [
                    [-0.273, -40.051],
                    [-40.632, 0.561],
                    [-0.273, 41.173],
                    [40.087, 0.561],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [1.166, 5.988],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.842, 4.422],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.643, 1.95],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.986, -1.167],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.422, -3.845],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [1.949, -5.64],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.166, -5.987],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.846, -4.421],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.641, -1.95],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.988, 1.167],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.422, 3.845],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.949, 5.64],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0.688, -5.821],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-2.402, -5.608],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-4.789, -3.563],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.818, -0.688],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.603, 2.404],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-3.565, 4.789],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.689, 5.817],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [2.404, 5.605],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.785, 3.563],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.82, 0.69],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [5.605, -2.404],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.566, -4.786],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [57.514, 6.775],
                        [56.852, -11.023],
                        [65.291, -13.62],
                        [61.637, -25.491],
                        [53.193, -22.888],
                        [43.725, -37.973],
                        [49.734, -44.441],
                        [40.637, -52.894],
                        [34.619, -46.418],
                        [18.877, -54.741],
                        [20.852, -63.354],
                        [8.742, -66.126],
                        [6.771, -57.515],
                        [-11.023, -56.854],
                        [-13.623, -65.293],
                        [-25.494, -61.635],
                        [-22.893, -53.195],
                        [-37.973, -43.726],
                        [-44.443, -49.736],
                        [-52.896, -40.634],
                        [-46.418, -34.62],
                        [-54.74, -18.881],
                        [-63.357, -20.852],
                        [-66.127, -8.742],
                        [-57.514, -6.774],
                        [-56.854, 11.02],
                        [-65.295, 13.622],
                        [-61.639, 25.493],
                        [-53.195, 22.892],
                        [-43.725, 37.97],
                        [-49.738, 44.443],
                        [-40.637, 52.896],
                        [-34.619, 46.417],
                        [-18.879, 54.741],
                        [-20.85, 63.357],
                        [-8.744, 66.126],
                        [-6.775, 57.513],
                        [11.023, 56.852],
                        [13.621, 65.293],
                        [25.49, 61.641],
                        [22.889, 53.198],
                        [37.973, 43.726],
                        [44.441, 49.736],
                        [52.895, 40.636],
                        [46.416, 34.619],
                        [54.74, 18.879],
                        [63.354, 20.852],
                        [66.127, 8.745],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 12099.0004928024,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_9",
      nm: "Gear 60",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.289, 13.081],
                    [0, 0],
                    [-13.415, -3.071],
                    [0, 0],
                    [0.046, -13.763],
                    [0, 0],
                    [11.657, -7.318],
                  ],
                  o: [
                    [-11.661, 7.315],
                    [0, 0],
                    [0.19, -13.761],
                    [0, 0],
                    [13.416, 3.07],
                    [0, 0],
                    [4.043, 13.155],
                    [0, 0],
                  ],
                  v: [
                    [-187.411, 99.33],
                    [-216.403, 88.85],
                    [-233.56, -15.918],
                    [-208.819, -35.355],
                    [-178.895, -28.505],
                    [-154.583, 2.103],
                    [-147.499, 45.755],
                    [-161.346, 82.977],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-26.748, -4.518],
                    [0, 0],
                    [14.375, -7.404],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-15.138, -2.019],
                    [0, 0],
                    [25.272, -14.06],
                  ],
                  v: [
                    [21.265, -121.213],
                    [10.914, -75.999],
                    [-34.408, -68.146],
                    [-59.069, -107.446],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [4.665, -14.489],
                    [0, 0],
                    [-23.497, 16.715],
                  ],
                  o: [
                    [-12.777, 9.78],
                    [0, 0],
                    [7.457, -26.023],
                    [0, 0],
                  ],
                  v: [
                    [-46.098, -60.749],
                    [-72.497, -23.303],
                    [-117.711, -33.654],
                    [-70.759, -100.052],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-4.5, 26.644],
                    [0, 0],
                    [-7.288, -14.298],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-2.005, 15.038],
                    [0, 0],
                    [-13.932, -25.198],
                  ],
                  v: [
                    [-120.765, -20.16],
                    [-75.551, -9.812],
                    [-67.851, 35.222],
                    [-107.152, 59.883],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [23.655, -16.638],
                    [0, 0],
                    [-4.766, 14.511],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [12.92, -9.723],
                    [0, 0],
                    [-7.565, 26.046],
                  ],
                  v: [
                    [71.785, 101.097],
                    [47.126, 61.796],
                    [73.913, 24.403],
                    [119.127, 34.752],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [26.676, 4.612],
                    [0, 0],
                    [-14.367, 7.25],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [15.076, 2.103],
                    [0, 0],
                    [-25.269, 13.886],
                  ],
                  v: [
                    [-20.169, 121.797],
                    [-9.822, 76.583],
                    [35.382, 69.105],
                    [60.042, 108.406],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-14.413, -4.737],
                    [0, 0],
                    [16.63, 23.508],
                  ],
                  o: [
                    [9.703, 12.785],
                    [0, 0],
                    [-25.948, -7.538],
                    [0, 0],
                  ],
                  v: [
                    [-60.497, 46.94],
                    [-23.297, 73.467],
                    [-33.647, 118.681],
                    [-99.799, 71.599],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [12.476, 5.813],
                    [0, 0],
                    [-11.658, 7.315],
                    [0, 0],
                    [-10.086, -9.361],
                    [0, 0],
                    [3.071, -13.417],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-9.913, -9.548],
                    [0, 0],
                    [11.658, -7.318],
                    [0, 0],
                    [12.413, 5.948],
                    [0, 0],
                    [-3.073, 13.418],
                  ],
                  v: [
                    [-86.538, 218.4],
                    [-173.406, 157.372],
                    [-170.232, 126.71],
                    [-144.167, 110.358],
                    [-104.628, 114.077],
                    [-68.405, 139.444],
                    [-51.422, 174.652],
                    [-58.269, 204.573],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 8",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [12.956, -4.643],
                    [0, 0],
                    [-3.071, 13.415],
                    [0, 0],
                    [-13.747, 0.642],
                    [0, 0],
                    [-7.318, -11.661],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-13.763, 0.252],
                    [0, 0],
                    [3.07, -13.417],
                    [0, 0],
                    [12.976, -4.596],
                    [0, 0],
                    [7.315, 11.66],
                  ],
                  v: [
                    [94.691, 215.274],
                    [-7.194, 235.16],
                    [-26.631, 211.223],
                    [-19.721, 181.03],
                    [10.86, 155.468],
                    [51.772, 146.841],
                    [88.663, 159.683],
                    [104.945, 185.633],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 9",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [5.742, -12.511],
                    [0, 0],
                    [7.315, 11.659],
                    [0, 0],
                    [-9.087, 10.336],
                    [0, 0],
                    [-13.418, -3.07],
                  ],
                  o: [
                    [13.418, 3.073],
                    [0, 0],
                    [-9.372, 10.079],
                    [0, 0],
                    [-7.316, -11.657],
                    [0, 0],
                    [5.845, -12.462],
                    [0, 0],
                  ],
                  v: [
                    [204.995, 59.366],
                    [218.949, 87.695],
                    [162.241, 170.647],
                    [131.902, 167.778],
                    [115.399, 141.476],
                    [118.619, 101.486],
                    [140.054, 69.592],
                    [175.075, 52.518],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 10",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-4.413, -13.038],
                    [0, 0],
                    [13.416, 3.071],
                    [0, 0],
                    [0.516, 13.755],
                    [0, 0],
                    [-11.66, 7.315],
                  ],
                  o: [
                    [11.657, -7.315],
                    [0, 0],
                    [0.174, 13.763],
                    [0, 0],
                    [-13.419, -3.07],
                    [0, 0],
                    [-4.236, -13.098],
                    [0, 0],
                  ],
                  v: [
                    [188.931, -98.653],
                    [218.15, -88.249],
                    [235.723, 8.288],
                    [211.645, 27.728],
                    [181.453, 20.818],
                    [156.121, -9.774],
                    [149.369, -45.186],
                    [162.868, -82.3],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 11",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [4.625, -26.777],
                    [0, 0],
                    [7.364, 14.442],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [2.117, -15.177],
                    [0, 0],
                    [14.014, 25.342],
                  ],
                  v: [
                    [122.247, 21.275],
                    [77.033, 10.924],
                    [69.4, -34.567],
                    [108.701, -59.227],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 12",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [14.586, 4.696],
                    [0, 0],
                    [-16.722, -23.641],
                  ],
                  o: [
                    [-9.799, -12.913],
                    [0, 0],
                    [26.125, 7.483],
                    [0, 0],
                  ],
                  v: [
                    [62.047, -46.284],
                    [24.407, -72.944],
                    [34.756, -118.158],
                    [101.347, -70.945],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 13",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-12.318, -6.145],
                    [0, 0],
                    [11.661, -7.315],
                    [0, 0],
                    [9.952, 9.508],
                    [0, 0],
                    [-3.071, 13.416],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [9.82, 9.647],
                    [0, 0],
                    [-11.657, 7.318],
                    [0, 0],
                    [-12.16, -6.446],
                    [0, 0],
                    [3.071, -13.418],
                  ],
                  v: [
                    [95.81, -214.686],
                    [175.095, -156.869],
                    [171.749, -126.033],
                    [145.687, -109.68],
                    [106.396, -113.666],
                    [77.449, -135.155],
                    [60.922, -171.268],
                    [67.832, -201.461],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 14",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-13.152, 4.052],
                    [0, 0],
                    [3.071, -13.418],
                    [0, 0],
                    [13.763, 0.161],
                    [0, 0],
                    [7.318, 11.66],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [13.763, 0.269],
                    [0, 0],
                    [-3.07, 13.418],
                    [0, 0],
                    [-13.26, 3.685],
                    [0, 0],
                    [-7.315, -11.657],
                  ],
                  v: [
                    [-82.345, -219.149],
                    [17.015, -234.123],
                    [36.452, -209.238],
                    [29.601, -179.317],
                    [-1.004, -155.212],
                    [-39.044, -149.785],
                    [-76.456, -164.282],
                    [-92.959, -190.584],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 15",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-7.315, -11.661],
                    [0, 0],
                    [9.782, -9.682],
                    [0, 0],
                    [13.419, 3.07],
                    [0, 0],
                    [-6.212, 12.283],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [7.315, 11.658],
                    [0, 0],
                    [-6.56, 12.1],
                    [0, 0],
                    [-13.415, -3.07],
                    [0, 0],
                    [9.818, -9.649],
                  ],
                  v: [
                    [-120.762, -174.078],
                    [-104.478, -148.125],
                    [-108.964, -109.324],
                    [-134.529, -76.24],
                    [-170.849, -59.824],
                    [-201.041, -66.735],
                    [-214.139, -94.651],
                    [-151.914, -177.736],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 16",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.75, -2.573],
                        [-5.835, -6.88],
                        [0, 0],
                        [7.712, -9.168],
                        [9.562, 11.281],
                        [0, 0],
                        [6.167, -6.948],
                        [2.884, -3.53],
                        [-7.57, -4.984],
                        [0, 0],
                        [4.813, -10.97],
                        [12.353, 8.128],
                        [0, 0],
                        [3.946, -8.424],
                        [1.764, -4.202],
                        [-8.66, -2.645],
                        [0, 0],
                        [3.915, -12.82],
                        [12.29, 3.755],
                        [0, 0],
                        [1.426, -9.182],
                        [0.517, -4.527],
                        [-9.066, -0.098],
                        [0, 0],
                        [0.147, -13.404],
                        [13.405, 0.147],
                        [0, 0],
                        [-1.215, -9.209],
                        [-0.782, -4.505],
                        [-8.722, 2.456],
                        [0, 0],
                        [-3.635, -12.902],
                        [0, 0],
                        [12.902, -3.636],
                        [0, 0],
                        [-3.774, -8.498],
                        [-2.025, -4.105],
                        [-7.674, 4.814],
                        [0, 0],
                        [-7.125, -11.356],
                        [0, 0],
                        [11.356, -7.125],
                        [0, 0],
                        [-5.992, -7.096],
                        [-3.07, -3.351],
                        [-6.014, 6.787],
                        [0, 0],
                        [-10.173, -6.327],
                        [9.804, -11.068],
                        [0, 0],
                        [-7.772, -5.108],
                        [-3.907, -2.348],
                        [-3.851, 8.192],
                        [0, 0],
                        [-12.133, -5.701],
                        [0, 0],
                        [5.701, -12.134],
                        [0, 0],
                        [-8.894, -2.712],
                        [-4.407, -1.157],
                        [-1.386, 8.951],
                        [0, 0],
                        [-13.247, -2.052],
                        [2.051, -13.249],
                        [0, 0],
                        [-9.288, -0.114],
                        [-4.562, 0.131],
                        [1.193, 8.989],
                        [0, 0],
                        [-13.291, 1.765],
                        [0, 0],
                        [-1.763, -13.288],
                        [0, 0],
                        [-8.945, 2.521],
                        [-4.351, 1.418],
                        [3.674, 8.277],
                        [0, 0],
                        [-12.252, 5.436],
                        [-5.437, -12.255],
                        [0, 0],
                        [-7.837, 4.91],
                        [-0.052, 0.029],
                        [-3.752, 2.574],
                        [5.834, 6.884],
                        [0, 0],
                        [-10.225, 8.668],
                        [-8.668, -10.226],
                        [0, 0],
                        [-6.168, 6.947],
                        [-2.886, 3.526],
                        [7.571, 4.983],
                        [0, 0],
                        [-7.369, 11.198],
                        [-11.198, -7.369],
                        [0, 0],
                        [-3.948, 8.424],
                        [-1.767, 4.198],
                        [8.657, 2.647],
                        [0, 0],
                        [-3.916, 12.82],
                        [-12.291, -3.756],
                        [0, 0],
                        [-1.427, 9.182],
                        [-0.518, 4.528],
                        [9.065, 0.098],
                        [0, 0],
                        [1.881, 11.831],
                        [-14.785, -0.161],
                        [0, 0],
                        [1.215, 9.209],
                        [0.78, 4.505],
                        [8.723, -2.459],
                        [0, 0],
                        [5.221, 10.611],
                        [-14.342, 4.043],
                        [0, 0],
                        [3.775, 8.497],
                        [2.025, 4.103],
                        [7.674, -4.813],
                        [0, 0],
                        [7.124, 11.356],
                        [-11.356, 7.124],
                        [0, 0],
                        [5.992, 7.096],
                        [3.073, 3.351],
                        [6.011, -6.788],
                        [0, 0],
                        [10.174, 6.326],
                        [-9.804, 11.068],
                        [0, 0],
                        [7.771, 5.108],
                        [3.911, 2.344],
                        [3.847, -8.195],
                        [0, 0],
                        [11.543, 3.201],
                        [-6.288, 13.38],
                        [0, 0],
                        [8.894, 2.712],
                        [4.405, 1.153],
                        [1.386, -8.953],
                        [0, 0],
                        [11.978, -0.179],
                        [-2.261, 14.61],
                        [0, 0],
                        [9.288, 0.109],
                        [4.562, -0.131],
                        [-1.193, -8.986],
                        [0, 0],
                        [13.288, -1.767],
                        [1.65, 12.426],
                        [0, 0],
                        [8.943, -2.524],
                        [4.348, -1.422],
                        [-3.673, -8.28],
                        [0, 0],
                        [14.983, -3.813],
                        [4.859, 10.948],
                        [0, 0],
                        [7.836, -4.91],
                        [0.049, -0.03],
                      ],
                      o: [
                        [-7.438, 5.104],
                        [0, 0],
                        [7.748, 9.136],
                        [-9.948, 11.831],
                        [0, 0],
                        [-6.005, -7.084],
                        [-3.04, 3.422],
                        [-5.736, 7.016],
                        [0, 0],
                        [10.008, 6.586],
                        [-6.212, 14.157],
                        [0, 0],
                        [-7.769, -5.114],
                        [-1.935, 4.131],
                        [-3.511, 8.345],
                        [0, 0],
                        [12.82, 3.915],
                        [6.831, 10.886],
                        [0, 0],
                        [-8.886, -2.715],
                        [-0.701, 4.497],
                        [-1.033, 9.005],
                        [0, 0],
                        [13.405, 0.147],
                        [-0.144, 13.408],
                        [0, 0],
                        [-9.29, -0.101],
                        [0.598, 4.516],
                        [1.549, 8.929],
                        [0, 0],
                        [12.905, -3.639],
                        [0, 0],
                        [3.639, 12.903],
                        [0, 0],
                        [-8.948, 2.522],
                        [1.846, 4.149],
                        [4.008, 8.122],
                        [0, 0],
                        [11.356, -7.125],
                        [0, 0],
                        [7.125, 11.355],
                        [0, 0],
                        [-7.866, 4.938],
                        [2.951, 3.494],
                        [6.128, 6.684],
                        [0, 0],
                        [7.942, -8.967],
                        [13.127, 8.162],
                        [0, 0],
                        [-6.166, 6.961],
                        [3.817, 2.511],
                        [7.76, 4.66],
                        [0, 0],
                        [5.701, -12.133],
                        [0, 0],
                        [12.133, 5.7],
                        [0, 0],
                        [-3.954, 8.413],
                        [4.355, 1.326],
                        [8.764, 2.294],
                        [0, 0],
                        [2.051, -13.248],
                        [13.247, 2.051],
                        [0, 0],
                        [-1.421, 9.18],
                        [4.554, 0.048],
                        [9.063, -0.261],
                        [0, 0],
                        [-1.763, -13.288],
                        [0, 0],
                        [13.288, -1.764],
                        [0, 0],
                        [1.223, 9.212],
                        [4.383, -1.239],
                        [8.611, -2.814],
                        [0, 0],
                        [-5.437, -12.255],
                        [12.253, -5.436],
                        [0, 0],
                        [3.75, 8.453],
                        [0.051, -0.033],
                        [3.888, -2.44],
                        [7.438, -5.103],
                        [0, 0],
                        [-8.668, -10.224],
                        [10.225, -8.669],
                        [0, 0],
                        [6.008, 7.088],
                        [3.038, -3.422],
                        [5.736, -7.02],
                        [0, 0],
                        [-11.198, -7.371],
                        [7.37, -11.198],
                        [0, 0],
                        [7.769, 5.114],
                        [1.935, -4.13],
                        [3.507, -8.348],
                        [0, 0],
                        [-12.82, -3.916],
                        [-6.831, -10.886],
                        [0, 0],
                        [8.886, 2.715],
                        [0.698, -4.5],
                        [1.033, -9.005],
                        [0, 0],
                        [-11.978, -0.13],
                        [-2.429, -15.266],
                        [0, 0],
                        [9.288, 0.098],
                        [-0.595, -4.519],
                        [-1.551, -8.93],
                        [0, 0],
                        [-11.382, 3.206],
                        [-6.907, -14.041],
                        [0, 0],
                        [8.949, -2.524],
                        [-1.845, -4.149],
                        [-4.008, -8.122],
                        [0, 0],
                        [-11.356, 7.125],
                        [-7.125, -11.353],
                        [0, 0],
                        [7.864, -4.938],
                        [-2.954, -3.494],
                        [-6.125, -6.685],
                        [0, 0],
                        [-7.943, 8.967],
                        [-13.127, -8.163],
                        [0, 0],
                        [6.166, -6.962],
                        [-3.821, -2.511],
                        [-7.761, -4.664],
                        [0, 0],
                        [-5.092, 10.842],
                        [-14.9, -4.136],
                        [0, 0],
                        [3.951, -8.416],
                        [-4.359, -1.329],
                        [-8.764, -2.293],
                        [0, 0],
                        [-1.834, 11.84],
                        [-15.459, 0.232],
                        [0, 0],
                        [1.421, -9.179],
                        [-4.554, -0.054],
                        [-9.065, 0.255],
                        [0, 0],
                        [1.764, 13.288],
                        [-10.619, 6.662],
                        [0, 0],
                        [-1.222, -9.213],
                        [-4.383, 1.234],
                        [-8.611, 2.81],
                        [0, 0],
                        [5.998, 13.516],
                        [-11.609, 2.951],
                        [0, 0],
                        [-3.75, -8.454],
                        [-0.049, 0.032],
                        [-3.889, 2.44],
                      ],
                      v: [
                        [-191.851, -279.966],
                        [-194.794, -257.526],
                        [-189.908, -251.762],
                        [-189.308, -219.874],
                        [-226.641, -219.709],
                        [-231, -224.852],
                        [-254.082, -225.133],
                        [-262.967, -214.705],
                        [-259.53, -192.26],
                        [-253.147, -188.062],
                        [-243.584, -157.633],
                        [-279.361, -146.957],
                        [-284.956, -150.638],
                        [-307.195, -144.381],
                        [-312.746, -131.881],
                        [-303.116, -111.334],
                        [-295.208, -108.919],
                        [-279.015, -78.49],
                        [-294.83, -57.835],
                        [-315.812, -64.243],
                        [-335.382, -51.996],
                        [-337.208, -38.456],
                        [-322.18, -21.428],
                        [-313.937, -21.339],
                        [-289.828, 3.296],
                        [-314.464, 27.405],
                        [-321.189, 27.334],
                        [-336.52, 44.59],
                        [-334.452, 58.124],
                        [-315.238, 70.23],
                        [-307.233, 67.972],
                        [-277.16, 84.817],
                        [-277.16, 84.817],
                        [-294.005, 114.89],
                        [-300.445, 116.705],
                        [-310.287, 137.596],
                        [-304.483, 149.979],
                        [-282.641, 156.169],
                        [-275.638, 151.776],
                        [-242.038, 159.469],
                        [-242.038, 159.469],
                        [-249.728, 193.068],
                        [-255.391, 196.619],
                        [-258.959, 219.424],
                        [-249.924, 229.693],
                        [-227.209, 229.491],
                        [-222.177, 223.807],
                        [-190.697, 218.674],
                        [-185.221, 255.603],
                        [-189.691, 260.652],
                        [-186.664, 283.553],
                        [-175.072, 290.841],
                        [-153.363, 284.233],
                        [-149.825, 276.702],
                        [-117.402, 265.007],
                        [-117.402, 265.007],
                        [-105.706, 297.434],
                        [-108.538, 303.46],
                        [-99.187, 324.579],
                        [-86.041, 328.302],
                        [-67.062, 315.846],
                        [-65.801, 307.705],
                        [-37.984, 287.349],
                        [-17.628, 315.166],
                        [-18.651, 321.775],
                        [-3.754, 339.406],
                        [9.922, 339.291],
                        [24.645, 321.992],
                        [23.555, 313.781],
                        [44.509, 286.411],
                        [44.509, 286.411],
                        [71.878, 307.362],
                        [72.764, 314.036],
                        [92.035, 326.753],
                        [105.136, 322.77],
                        [114.372, 302.031],
                        [111.002, 294.434],
                        [123.396, 262.27],
                        [155.561, 274.664],
                        [158.27, 280.774],
                        [180.241, 287.588],
                        [180.393, 287.493],
                        [191.851, 279.968],
                        [194.792, 257.529],
                        [189.455, 251.232],
                        [192.286, 216.88],
                        [226.639, 219.712],
                        [230.998, 224.852],
                        [254.082, 225.136],
                        [262.968, 214.712],
                        [259.528, 192.264],
                        [252.563, 187.682],
                        [245.601, 153.922],
                        [279.358, 146.959],
                        [284.956, 150.642],
                        [307.195, 144.384],
                        [312.747, 131.887],
                        [303.117, 111.338],
                        [295.206, 108.923],
                        [279.016, 78.493],
                        [294.831, 57.839],
                        [315.812, 64.246],
                        [335.383, 52.002],
                        [337.208, 38.459],
                        [322.181, 21.432],
                        [314.635, 21.35],
                        [290.141, 0.922],
                        [314.464, -27.401],
                        [321.189, -27.328],
                        [336.518, -44.586],
                        [334.452, -58.121],
                        [315.238, -70.224],
                        [307.804, -68.129],
                        [278.804, -80.533],
                        [294.005, -114.886],
                        [300.445, -116.702],
                        [310.287, -137.593],
                        [304.483, -149.975],
                        [282.641, -156.168],
                        [275.636, -151.772],
                        [242.036, -159.465],
                        [249.729, -193.064],
                        [255.392, -196.616],
                        [258.96, -219.421],
                        [249.922, -229.689],
                        [227.21, -229.488],
                        [222.175, -223.803],
                        [190.697, -218.67],
                        [185.219, -255.599],
                        [189.689, -260.648],
                        [186.664, -283.549],
                        [175.069, -290.834],
                        [153.364, -284.226],
                        [150.122, -277.332],
                        [121.367, -263.536],
                        [105.703, -297.427],
                        [108.538, -303.454],
                        [99.188, -324.573],
                        [86.041, -328.297],
                        [67.06, -315.843],
                        [65.908, -308.394],
                        [42.202, -287.055],
                        [17.626, -315.16],
                        [18.651, -321.769],
                        [3.754, -339.399],
                        [-9.922, -339.285],
                        [-24.645, -321.989],
                        [-23.555, -313.775],
                        [-44.509, -286.405],
                        [-70.731, -298.726],
                        [-72.764, -314.035],
                        [-92.035, -326.747],
                        [-105.135, -322.763],
                        [-114.372, -302.025],
                        [-111.002, -294.43],
                        [-127.393, -260.884],
                        [-155.844, -275.3],
                        [-158.27, -280.77],
                        [-180.242, -287.585],
                        [-180.392, -287.489],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_10",
      nm: "Gear 60",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-53.162, -18.626],
                    [0, 0],
                    [-6.401, -3.392],
                    [0, 0],
                    [-3.387, 6.401],
                    [0, 0],
                    [-15.889, -25.322],
                    [-5.128, -26.544],
                    [0, 0],
                    [-0.26, -7.24],
                    [0, 0],
                    [-7.235, 0.269],
                    [0, 0],
                    [44.018, -38.506],
                    [0, 0],
                    [6.141, -3.846],
                    [0, 0],
                    [-3.851, -6.129],
                    [0, 0],
                    [53.286, 18.046],
                    [0, 0],
                    [6.403, 3.391],
                    [0, 0],
                    [3.389, -6.396],
                    [0, 0],
                    [16.331, 26.03],
                    [5.132, 26.529],
                    [0, 0],
                    [0.265, 7.24],
                    [0, 0],
                    [7.235, -0.266],
                    [0, 0],
                    [-45.087, 38.629],
                    [0, 0],
                    [-6.136, 3.851],
                    [0, 0],
                    [3.851, 6.134],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-3.395, 6.4],
                    [0, 0],
                    [6.4, 3.38],
                    [0, 0],
                    [22.832, 16.484],
                    [15.23, 24.276],
                    [0, 0],
                    [-7.24, 0.262],
                    [0, 0],
                    [0.273, 7.23],
                    [0, 0],
                    [-5.454, 55.75],
                    [0, 0],
                    [-3.846, -6.136],
                    [0, 0],
                    [-6.133, 3.847],
                    [0, 0],
                    [-53.634, 24.797],
                    [0, 0],
                    [3.395, -6.398],
                    [0, 0],
                    [-6.393, -3.383],
                    [0, 0],
                    [-23.623, -16.678],
                    [-15.225, -24.26],
                    [0, 0],
                    [7.236, -0.265],
                    [0, 0],
                    [-0.262, -7.231],
                    [0, 0],
                    [5.522, -56.503],
                    [0, 0],
                    [3.846, 6.133],
                    [0, 0],
                    [6.131, -3.848],
                    [0, 0],
                    [53.839, -24.231],
                  ],
                  v: [
                    [75.12, -211.701],
                    [43.311, -151.604],
                    [48.781, -133.808],
                    [82.607, -115.901],
                    [100.403, -121.383],
                    [132.398, -181.824],
                    [191.25, -118.994],
                    [221.577, -42.106],
                    [154.441, -39.635],
                    [141.758, -25.997],
                    [143.169, 12.249],
                    [156.812, 24.917],
                    [224.573, 22.426],
                    [148.992, 169.891],
                    [113.033, 112.586],
                    [94.886, 108.427],
                    [62.471, 128.771],
                    [58.319, 146.92],
                    [94.786, 205.04],
                    [-71.751, 213.791],
                    [-39.496, 152.844],
                    [-44.968, 135.048],
                    [-78.795, 117.143],
                    [-96.585, 122.621],
                    [-129.349, 184.516],
                    [-190.097, 120.291],
                    [-220.421, 43.45],
                    [-150.623, 40.879],
                    [-137.948, 27.239],
                    [-139.357, -11.007],
                    [-152.997, -23.676],
                    [-223.431, -21.084],
                    [-146.106, -170.12],
                    [-109.221, -111.341],
                    [-91.076, -107.188],
                    [-58.659, -127.529],
                    [-54.504, -145.678],
                    [-91.546, -204.711],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0.37, 10.022],
                    [10.02, -0.37],
                    [-0.366, -10.019],
                    [-10.019, 0.37],
                  ],
                  o: [
                    [-0.367, -10.021],
                    [-10.023, 0.37],
                    [0.367, 10.02],
                    [10.022, -0.369],
                  ],
                  v: [
                    [-153.052, 8.648],
                    [-171.86, -8.824],
                    [-189.34, 9.984],
                    [-170.529, 27.46],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 48",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-5.328, -8.494],
                    [-8.494, 5.331],
                    [5.328, 8.494],
                    [8.494, -5.33],
                  ],
                  o: [
                    [5.333, 8.494],
                    [8.494, -5.33],
                    [-5.331, -8.494],
                    [-8.494, 5.331],
                  ],
                  v: [
                    [-107.558, -135.291],
                    [-82.522, -129.564],
                    [-76.796, -154.59],
                    [-101.824, -160.322],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 50",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-5.333, -8.494],
                    [-8.494, 5.324],
                    [5.328, 8.491],
                    [8.499, -5.328],
                  ],
                  o: [
                    [5.328, 8.496],
                    [8.489, -5.331],
                    [-5.333, -8.498],
                    [-8.489, 5.333],
                  ],
                  v: [
                    [80.613, 155.831],
                    [105.638, 161.563],
                    [111.373, 136.535],
                    [86.332, 130.803],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 52",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [8.867, 4.693],
                    [4.693, -8.862],
                    [-8.858, -4.691],
                    [-4.69, 8.861],
                  ],
                  o: [
                    [-8.858, -4.69],
                    [-4.69, 8.864],
                    [8.861, 4.689],
                    [4.693, -8.863],
                  ],
                  v: [
                    [-68.622, 138.83],
                    [-93.159, 146.384],
                    [-85.607, 170.927],
                    [-61.067, 163.374],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 54",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-8.861, -4.697],
                    [-4.696, 8.865],
                    [8.854, 4.693],
                    [4.685, -8.861],
                  ],
                  o: [
                    [8.866, 4.685],
                    [4.693, -8.863],
                    [-8.869, -4.688],
                    [-4.695, 8.865],
                  ],
                  v: [
                    [72.434, -137.587],
                    [96.971, -145.143],
                    [89.427, -169.686],
                    [64.884, -162.134],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 56",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-0.365, -10.022],
                    [-10.023, 0.367],
                    [0.365, 10.018],
                    [10.018, -0.367],
                  ],
                  o: [
                    [0.375, 10.018],
                    [10.023, -0.367],
                    [-0.376, -10.022],
                    [-10.023, 0.369],
                  ],
                  v: [
                    [156.862, -7.404],
                    [175.676, 10.067],
                    [193.157, -8.74],
                    [174.343, -26.22],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 58",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [6.948, -4.358],
                        [7.109, 11.075],
                        [3.695, -2.881],
                        [6.071, -5.296],
                        [8.71, 9.454],
                        [3.186, -3.419],
                        [5.083, -6.073],
                        [9.976, 7.621],
                        [2.607, -3.864],
                        [3.997, -6.676],
                        [10.871, 5.641],
                        [1.969, -4.217],
                        [2.849, -7.084],
                        [11.403, 3.587],
                        [1.297, -4.479],
                        [1.672, -7.313],
                        [11.61, 1.565],
                        [0.596, -4.631],
                        [0.504, -7.384],
                        [11.521, -0.392],
                        [-0.127, -4.676],
                        [-0.66, -7.294],
                        [11.162, -2.251],
                        [-0.847, -4.61],
                        [-1.775, -7.051],
                        [10.54, -3.999],
                        [-1.562, -4.435],
                        [-2.835, -6.633],
                        [9.684, -5.604],
                        [-2.228, -4.15],
                        [-3.815, -6.081],
                        [8.658, -7.049],
                        [-2.806, -3.722],
                        [-4.726, -5.411],
                        [7.516, -8.33],
                        [-3.353, -3.25],
                        [-5.54, -4.63],
                        [6.234, -9.452],
                        [-3.813, -2.694],
                        [-6.242, -3.733],
                        [4.799, -10.388],
                        [-4.184, -2.071],
                        [-6.81, -2.732],
                        [3.255, -11.135],
                        [-4.457, -1.405],
                        [-7.227, -1.647],
                        [1.645, -11.706],
                        [-4.617, -0.696],
                        [-7.508, -0.505],
                        [-0.026, -12.079],
                        [-4.667, 0.022],
                        [-7.626, 0.69],
                        [-1.755, -12.267],
                        [-4.614, 0.75],
                        [-7.562, 1.902],
                        [-3.525, -12.186],
                        [-4.46, 1.481],
                        [-7.292, 3.118],
                        [-5.328, -11.848],
                        [-4.202, 2.168],
                        [-6.828, 4.293],
                        [-7.093, -11.259],
                        [-3.796, 2.777],
                        [-6.193, 5.406],
                        [-8.772, -10.058],
                        [-0.147, -0.18],
                        [-3.352, 3.375],
                        [0.118, 0.096],
                        [-5.315, 6.366],
                        [-10.233, -8.556],
                        [-0.335, -0.308],
                        [-2.809, 3.909],
                        [0.328, 0.194],
                        [-4.252, 7.12],
                        [-11.453, -6.854],
                        [-0.556, -0.376],
                        [-2.18, 4.352],
                        [0.543, 0.219],
                        [-3.087, 7.694],
                        [-12.381, -4.981],
                        [-0.766, -0.366],
                        [-1.479, 4.685],
                        [0.743, 0.17],
                        [-1.85, 8.08],
                        [-13.006, -2.979],
                        [-0.97, -0.302],
                        [-0.729, 4.913],
                        [0.942, 0.063],
                        [-0.556, 8.274],
                        [-13.311, -0.905],
                        [-1.157, -0.188],
                        [0.063, 5.033],
                        [1.094, -0.099],
                        [0.742, 8.252],
                        [-13.294, 1.186],
                        [-1.298, -0.019],
                        [0.871, 5.005],
                        [1.186, -0.299],
                        [2.028, 8.042],
                        [-12.938, 3.251],
                        [-1.364, 0.19],
                        [1.676, 4.831],
                        [1.201, -0.507],
                        [3.255, 7.62],
                        [-12.277, 5.232],
                        [-1.356, 0.413],
                        [2.427, 4.517],
                        [1.11, -0.698],
                        [4.409, 7.021],
                        [-11.304, 7.092],
                        [-1.277, 0.615],
                        [3.066, 4.034],
                        [0.992, -0.863],
                        [5.451, 6.243],
                        [-10.055, 8.772],
                        [-1.16, 0.799],
                        [3.678, 3.499],
                        [0.832, -0.99],
                        [6.361, 5.317],
                        [-8.557, 10.237],
                        [-0.978, 0.934],
                        [4.176, 2.86],
                        [0.619, -1.039],
                        [7.119, 4.255],
                        [-6.852, 11.451],
                        [-0.745, 1.001],
                        [4.543, 2.142],
                        [0.404, -1.009],
                        [7.694, 3.089],
                        [-4.979, 12.38],
                        [-0.496, 0.988],
                        [4.777, 1.389],
                        [0.208, -0.893],
                        [8.085, 1.84],
                        [-2.98, 13.009],
                        [-0.278, 0.904],
                        [4.895, 0.615],
                        [0.05, -0.745],
                        [8.268, 0.554],
                        [-0.908, 13.314],
                        [-0.097, 0.757],
                        [4.882, -0.161],
                        [-0.05, -0.559],
                        [8.255, -0.748],
                        [1.196, 13.288],
                        [0.032, 0.578],
                        [4.751, -0.929],
                        [-0.086, -0.337],
                        [8.032, -2.028],
                        [3.253, 12.944],
                        [0.079, 0.349],
                        [4.507, -1.65],
                        [-0.05, -0.105],
                        [7.623, -3.262],
                        [5.237, 12.27],
                        [0.045, 0.103],
                        [4.166, -2.315],
                      ],
                      o: [
                        [-6.948, 4.361],
                        [-3.871, 2.72],
                        [8.17, 9.905],
                        [-6.078, 5.301],
                        [-3.378, 3.272],
                        [9.247, 8.462],
                        [-5.08, 6.083],
                        [-2.808, 3.744],
                        [10.089, 6.912],
                        [-3.997, 6.683],
                        [-2.188, 4.125],
                        [10.679, 5.302],
                        [-2.853, 7.101],
                        [-1.521, 4.415],
                        [11.058, 3.661],
                        [-1.683, 7.352],
                        [-0.824, 4.594],
                        [11.254, 1.994],
                        [-0.504, 7.437],
                        [-0.103, 4.667],
                        [11.253, 0.318],
                        [0.655, 7.358],
                        [0.615, 4.623],
                        [11.049, -1.351],
                        [1.784, 7.106],
                        [1.326, 4.475],
                        [10.618, -2.971],
                        [2.862, 6.707],
                        [2, 4.21],
                        [9.974, -4.518],
                        [3.871, 6.167],
                        [2.646, 3.898],
                        [9.146, -6.024],
                        [4.803, 5.506],
                        [3.205, 3.44],
                        [8.111, -7.466],
                        [5.643, 4.718],
                        [3.693, 2.892],
                        [6.86, -8.779],
                        [6.357, 3.809],
                        [4.087, 2.285],
                        [5.387, -9.901],
                        [6.944, 2.792],
                        [4.383, 1.62],
                        [3.717, -10.827],
                        [7.397, 1.696],
                        [4.57, 0.926],
                        [1.865, -11.529],
                        [7.691, 0.526],
                        [4.653, 0.213],
                        [-0.147, -11.953],
                        [7.82, -0.692],
                        [4.627, -0.513],
                        [-2.277, -12.058],
                        [7.747, -1.946],
                        [4.509, -1.241],
                        [-4.428, -11.754],
                        [7.479, -3.189],
                        [4.268, -1.935],
                        [-6.553, -11.068],
                        [7.012, -4.391],
                        [3.974, -2.61],
                        [-8.589, -10.013],
                        [6.246, -5.448],
                        [0.154, 0.173],
                        [3.549, -3.226],
                        [-0.115, -0.094],
                        [-10.244, -8.556],
                        [5.317, -6.351],
                        [0.365, 0.302],
                        [3.024, -3.781],
                        [-0.328, -0.186],
                        [-11.455, -6.845],
                        [4.255, -7.109],
                        [0.585, 0.35],
                        [2.403, -4.242],
                        [-0.543, -0.194],
                        [-12.389, -4.972],
                        [3.093, -7.691],
                        [0.803, 0.324],
                        [1.715, -4.606],
                        [-0.74, -0.123],
                        [-13.019, -2.977],
                        [1.852, -8.08],
                        [1.031, 0.237],
                        [0.992, -4.868],
                        [-0.91, 0],
                        [-13.315, -0.904],
                        [0.569, -8.269],
                        [1.214, 0.084],
                        [0.21, -5.016],
                        [-1.049, 0.185],
                        [-13.297, 1.192],
                        [-0.735, -8.256],
                        [1.34, -0.117],
                        [-0.593, -5.021],
                        [-1.109, 0.391],
                        [-12.94, 3.254],
                        [-2.015, -8.039],
                        [1.401, -0.352],
                        [-1.385, -4.88],
                        [-1.094, 0.599],
                        [-12.271, 5.243],
                        [-3.252, -7.627],
                        [1.358, -0.583],
                        [-2.154, -4.592],
                        [-0.994, 0.771],
                        [-11.303, 7.098],
                        [-4.407, -7.022],
                        [1.248, -0.784],
                        [-2.88, -4.243],
                        [-0.871, 0.919],
                        [-10.049, 8.78],
                        [-5.454, -6.248],
                        [1.104, -0.965],
                        [-3.507, -3.724],
                        [-0.698, 1.028],
                        [-8.552, 10.245],
                        [-6.361, -5.317],
                        [0.905, -1.081],
                        [-4.032, -3.098],
                        [-0.506, 1.049],
                        [-6.852, 11.458],
                        [-7.109, -4.256],
                        [0.661, -1.111],
                        [-4.441, -2.39],
                        [-0.315, 1.006],
                        [-4.974, 12.384],
                        [-7.691, -3.094],
                        [0.428, -1.06],
                        [-4.698, -1.638],
                        [-0.147, 0.88],
                        [-2.975, 13.015],
                        [-8.077, -1.857],
                        [0.209, -0.95],
                        [-4.85, -0.868],
                        [-0.01, 0.732],
                        [-0.898, 13.315],
                        [-8.271, -0.568],
                        [0.055, -0.782],
                        [-4.876, -0.089],
                        [0.073, 0.548],
                        [1.193, 13.288],
                        [-8.263, 0.734],
                        [-0.044, -0.585],
                        [-4.771, 0.67],
                        [0.092, 0.333],
                        [3.258, 12.947],
                        [-8.043, 2.015],
                        [-0.086, -0.345],
                        [-4.557, 1.405],
                        [0.05, 0.105],
                        [5.236, 12.278],
                        [-7.628, 3.25],
                        [-0.037, -0.105],
                        [-4.229, 2.08],
                        [6.885, 11.215],
                      ],
                      v: [
                        [-136.572, -217.627],
                        [-161.902, -229.791],
                        [-173.252, -221.381],
                        [-169.359, -194.155],
                        [-195.828, -201.717],
                        [-205.68, -191.679],
                        [-198.075, -165.842],
                        [-224.878, -168.766],
                        [-232.998, -157.356],
                        [-222.004, -133.385],
                        [-248.36, -131.759],
                        [-254.584, -119.243],
                        [-240.562, -97.589],
                        [-265.729, -91.671],
                        [-269.955, -78.33],
                        [-253.291, -59.326],
                        [-276.72, -49.475],
                        [-278.844, -35.634],
                        [-259.881, -19.546],
                        [-281.056, -6.16],
                        [-281.025, 7.858],
                        [-260.159, 20.781],
                        [-278.69, 37.256],
                        [-276.497, 51.114],
                        [-254.133, 60.657],
                        [-269.627, 79.74],
                        [-265.298, 93.105],
                        [-241.943, 99.09],
                        [-254.091, 120.277],
                        [-247.746, 132.824],
                        [-223.89, 135.148],
                        [-232.53, 157.939],
                        [-224.35, 169.377],
                        [-200.415, 167.936],
                        [-205.474, 191.889],
                        [-195.639, 201.924],
                        [-172.103, 196.649],
                        [-173.478, 221.282],
                        [-162.222, 229.661],
                        [-139.646, 220.578],
                        [-137.266, 245.368],
                        [-124.853, 251.896],
                        [-103.848, 239.136],
                        [-97.715, 263.578],
                        [-84.454, 268.115],
                        [-65.586, 251.864],
                        [-55.764, 275.522],
                        [-41.979, 277.953],
                        [-25.804, 258.451],
                        [-12.392, 280.84],
                        [1.59, 281.124],
                        [14.522, 258.733],
                        [31.368, 279.415],
                        [45.23, 277.515],
                        [54.392, 252.71],
                        [74.436, 271.125],
                        [87.893, 267.054],
                        [92.825, 240.516],
                        [115.786, 256.164],
                        [128.495, 250.01],
                        [128.881, 222.463],
                        [154.392, 234.892],
                        [166.047, 226.81],
                        [161.671, 198.992],
                        [188.866, 207.337],
                        [189.299, 207.868],
                        [199.65, 197.96],
                        [199.304, 197.688],
                        [190.385, 170.675],
                        [218.545, 174.664],
                        [219.579, 175.586],
                        [228.33, 164.051],
                        [227.349, 163.504],
                        [214.317, 138.222],
                        [242.758, 137.756],
                        [244.46, 138.849],
                        [251.338, 125.956],
                        [249.706, 125.356],
                        [232.873, 102.424],
                        [260.892, 97.513],
                        [263.245, 98.552],
                        [268.04, 84.615],
                        [265.818, 84.179],
                        [245.601, 64.164],
                        [272.505, 54.927],
                        [275.498, 55.753],
                        [278.061, 41.072],
                        [275.283, 40.99],
                        [252.188, 24.38],
                        [277.319, 11.049],
                        [280.863, 11.475],
                        [281.096, -3.593],
                        [277.885, -3.152],
                        [252.471, -15.941],
                        [275.212, -33.041],
                        [279.17, -33.173],
                        [276.972, -48.207],
                        [273.541, -47.152],
                        [246.443, -55.815],
                        [266.225, -76.254],
                        [270.375, -77.049],
                        [265.795, -91.621],
                        [262.369, -89.938],
                        [234.256, -94.249],
                        [250.596, -117.532],
                        [254.67, -119.001],
                        [247.794, -132.666],
                        [244.646, -130.447],
                        [216.2, -130.308],
                        [228.695, -155.861],
                        [232.491, -157.945],
                        [223.566, -170.365],
                        [220.801, -167.684],
                        [192.73, -163.095],
                        [201.072, -190.291],
                        [204.472, -192.923],
                        [193.701, -203.766],
                        [191.424, -200.732],
                        [164.415, -191.809],
                        [168.4, -219.975],
                        [171.233, -222.986],
                        [158.912, -231.914],
                        [157.249, -228.776],
                        [131.958, -215.741],
                        [131.491, -244.179],
                        [133.619, -247.334],
                        [120.151, -254.149],
                        [119.094, -251.128],
                        [96.162, -234.296],
                        [91.248, -262.314],
                        [92.647, -265.368],
                        [78.429, -269.905],
                        [77.917, -267.241],
                        [57.9, -247.023],
                        [48.669, -273.932],
                        [49.421, -276.71],
                        [34.81, -278.921],
                        [34.726, -276.707],
                        [18.118, -253.613],
                        [4.787, -278.744],
                        [5.021, -281.056],
                        [-9.614, -280.957],
                        [-9.412, -279.304],
                        [-22.203, -253.898],
                        [-39.306, -276.631],
                        [-39.406, -278.371],
                        [-53.7, -275.97],
                        [-53.417, -274.969],
                        [-62.073, -247.867],
                        [-82.521, -267.649],
                        [-82.744, -268.689],
                        [-96.343, -264.108],
                        [-96.196, -263.8],
                        [-100.511, -235.676],
                        [-123.8, -252.013],
                        [-123.918, -252.324],
                        [-136.51, -245.723],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_11",
      nm: "Gear 61",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.14, 0.14, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-52.184, 107.119],
                    [0, 0],
                    [7.627, -13.43],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [10.419, 11.402],
                    [0, 0],
                    [-77.931, -97.252],
                  ],
                  v: [
                    [-285.039, -137.37],
                    [-161.886, -2.477],
                    [-157.227, 39.04],
                    [-247.72, 198.305],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [17.138, 18.774],
                    [-18.771, 17.142],
                    [-17.138, -18.773],
                    [18.767, -17.139],
                  ],
                  o: [
                    [-17.14, -18.768],
                    [18.771, -17.138],
                    [17.145, 18.774],
                    [-18.776, 17.138],
                  ],
                  v: [
                    [-35.085, 32.086],
                    [-32.13, -32.936],
                    [32.889, -29.979],
                    [29.938, 35.045],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [89.784, 86.439],
                    [0, 0],
                    [-10.412, -11.404],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [14.065, -6.381],
                    [0, 0],
                    [-111.412, 42.246],
                  ],
                  v: [
                    [-219.91, 228.76],
                    [-53.091, 153.098],
                    [-12.164, 161.5],
                    [110.996, 296.39],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [72.089, -65.826],
                    [22.283, -12.038],
                    [0, 0],
                    [-14.066, 6.38],
                  ],
                  o: [
                    [4.145, 90.455],
                    [-19.55, 17.848],
                    [0, 0],
                    [-1.716, -15.348],
                    [0, 0],
                  ],
                  v: [
                    [314.375, -13.564],
                    [211.849, 234.28],
                    [148.826, 279.076],
                    [128.492, 97.705],
                    [149.131, 61.379],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-41.529, -45.486],
                    [-10.244, -56.442],
                    [0, 0],
                    [2.247, 20.05],
                    [0, 0],
                  ],
                  o: [
                    [41.529, 45.486],
                    [0, 0],
                    [-19.761, -4.058],
                    [0, 0],
                    [55.278, 15.318],
                  ],
                  v: [
                    [232.131, -211.886],
                    [309.611, -55.62],
                    [139.771, -90.494],
                    [102.86, -130.923],
                    [83.544, -303.224],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-89.702, -12.336],
                    [0, 0],
                    [15.131, 3.108],
                    [0, 0],
                    [-19.545, 17.85],
                  ],
                  o: [
                    [0, 0],
                    [-7.632, 13.425],
                    [0, 0],
                    [14.013, -21.096],
                    [72.101, -65.827],
                  ],
                  v: [
                    [42.091, -311.785],
                    [-47.536, -154.023],
                    [-85.588, -136.768],
                    [-264.366, -173.476],
                    [-214.039, -232.171],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [13.802, -12.602],
                        [0.176, -0.167],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [9.289, -15.827],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.232, -18.099],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.264, -18.611],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.743, -17.517],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-11.712, -14.844],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-15.154, -10.483],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-17.712, -5.561],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-18.632, -0.117],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-17.94, 5.403],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-15.547, 10.492],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.182, 0.167],
                        [-11.407, 14.098],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.867, 17.223],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.497, 18.558],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.381, 3.962],
                        [0, 0],
                        [2.439, 11.027],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [9.337, 16.366],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [6.333, 6.942],
                        [6.73, 6.338],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [16.636, 8.125],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [10.993, 2.4],
                        [0, 0],
                        [3.928, 0.615],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [18.485, -2.657],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [16.986, -8.066],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [-0.188, 0.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-11.859, 14.531],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-7.008, 17.378],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-1.575, 18.566],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [3.927, 18.146],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [9.06, 16.041],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [13.721, 13.015],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [16.829, 8.312],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [18.421, 2.953],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [18.408, -2.567],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [16.6, -7.817],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0.188, -0.167],
                        [13.801, -12.604],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [9.575, -16.186],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [4.324, -18.169],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-0.252, -3.969],
                        [0, 0],
                        [-1.394, -11.163],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-6.58, -17.297],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-5.701, -7.284],
                        [-6.345, -6.941],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-15.452, -10.785],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-10.764, -3.427],
                        [0, 0],
                        [-3.909, -0.743],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-18.619, -0.19],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-17.771, 5.271],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [0, 0],
                        [-15.078, 10.086],
                      ],
                      v: [
                        [-246.193, -268.797],
                        [-246.732, -268.288],
                        [-281.894, -288.464],
                        [-293.56, -276.091],
                        [-305.23, -263.714],
                        [-283.046, -229.838],
                        [-314.783, -184.226],
                        [-330.879, -187.533],
                        [-330.868, -187.513],
                        [-354.404, -192.821],
                        [-361.908, -177.559],
                        [-369.406, -162.294],
                        [-338.266, -136.489],
                        [-355.112, -83.186],
                        [-395.501, -80.053],
                        [-398.17, -63.258],
                        [-400.838, -46.458],
                        [-363.377, -30.942],
                        [-363.846, 24.911],
                        [-401.535, 39.816],
                        [-399.136, 56.656],
                        [-396.735, 73.493],
                        [-356.429, 77.271],
                        [-340.421, 130.847],
                        [-371.971, 156.138],
                        [-364.712, 171.522],
                        [-357.453, 186.907],
                        [-317.861, 178.642],
                        [-286.688, 225.039],
                        [-309.428, 258.582],
                        [-297.961, 271.142],
                        [-286.488, 283.704],
                        [-251.023, 264.098],
                        [-207.644, 299.355],
                        [-219.468, 338.037],
                        [-204.811, 346.659],
                        [-190.148, 355.281],
                        [-162.094, 326.155],
                        [-110.197, 346.966],
                        [-110.093, 387.445],
                        [-93.539, 391.363],
                        [-76.986, 395.283],
                        [-58.724, 359.1],
                        [-3.058, 363.704],
                        [8.993, 402.42],
                        [25.968, 401.285],
                        [42.937, 400.152],
                        [49.721, 360.21],
                        [104.332, 348.271],
                        [127.201, 381.629],
                        [143.087, 375.542],
                        [158.968, 369.456],
                        [155.818, 345.535],
                        [155.836, 345.555],
                        [154.01, 329.219],
                        [202.306, 301.752],
                        [234.031, 326.918],
                        [247.416, 316.42],
                        [260.798, 305.922],
                        [243.893, 269.083],
                        [244.449, 268.587],
                        [282.267, 228.46],
                        [319.986, 243.152],
                        [329.68, 229.177],
                        [339.373, 215.199],
                        [312.424, 185.043],
                        [337.09, 134.851],
                        [377.431, 137.768],
                        [382.575, 121.557],
                        [387.722, 105.343],
                        [353.029, 84.442],
                        [361.755, 29.263],
                        [401.266, 20.137],
                        [401.4, 3.127],
                        [401.535, -13.879],
                        [362.188, -23.637],
                        [361.205, -35.531],
                        [360.047, -45.663],
                        [354.375, -78.971],
                        [389.365, -99.296],
                        [384.483, -115.589],
                        [379.596, -131.882],
                        [339.232, -129.614],
                        [315.344, -180.181],
                        [342.791, -209.913],
                        [333.32, -224.043],
                        [323.855, -238.173],
                        [285.873, -224.079],
                        [267.821, -245.425],
                        [248.201, -265.347],
                        [265.679, -301.889],
                        [252.469, -312.601],
                        [239.258, -323.312],
                        [207.157, -298.676],
                        [158.961, -327.059],
                        [164.886, -367.049],
                        [149.104, -373.391],
                        [133.324, -379.732],
                        [109.91, -346.728],
                        [77.248, -355.403],
                        [67.263, -357.47],
                        [55.51, -359.536],
                        [49.363, -399.604],
                        [32.412, -401.012],
                        [15.462, -402.42],
                        [2.789, -363.899],
                        [-52.953, -360.217],
                        [-70.623, -396.664],
                        [-87.235, -393.011],
                        [-103.847, -389.361],
                        [-104.602, -348.917],
                        [-156.825, -328.908],
                        [-184.412, -358.485],
                        [-199.21, -350.1],
                        [-214.013, -341.715],
                        [-202.797, -302.82],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.4549019607843137, 0.7607843137254902,
                      0.8588235294117647, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_12",
      nm: "Gear 61",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.14, 0.14, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [13.18, 4.185],
                    [-4.18, 13.176],
                    [-13.175, -4.184],
                    [4.188, -13.18],
                  ],
                  o: [
                    [-13.18, -4.178],
                    [4.179, -13.18],
                    [13.179, 4.179],
                    [-4.179, 13.179],
                  ],
                  v: [
                    [-226.292, -46.837],
                    [-242.582, -78.267],
                    [-211.151, -94.558],
                    [-194.86, -63.127],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [62.129, 68.046],
                    [-35.678, 32.579],
                    [-62.127, -68.047],
                    [35.677, -32.579],
                  ],
                  o: [
                    [-62.127, -68.047],
                    [35.681, -32.574],
                    [62.129, 68.046],
                    [-35.682, 32.573],
                  ],
                  v: [
                    [-204.304, 187.713],
                    [-257.297, -8.933],
                    [-79.021, 73.329],
                    [-13.276, 258.333],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [13.501, -2.97],
                    [2.97, 13.505],
                    [-13.504, 2.961],
                    [-2.965, -13.506],
                  ],
                  o: [
                    [-13.509, 2.97],
                    [-2.97, -13.506],
                    [13.505, -2.974],
                    [2.97, 13.504],
                  ],
                  v: [
                    [57.114, 249.974],
                    [27.289, 230.897],
                    [46.361, 201.076],
                    [76.187, 220.149],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-46.054, -14.61],
                    [27.867, -87.828],
                    [46.054, 14.61],
                    [-27.864, 87.828],
                  ],
                  o: [
                    [46.049, 14.615],
                    [-27.863, 87.829],
                    [-46.054, -14.61],
                    [27.865, -87.824],
                  ],
                  v: [
                    [230.74, -116.101],
                    [265.094, 84.643],
                    [121.29, 228.857],
                    [103.393, 33.339],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-9.326, -10.21],
                    [10.211, -9.322],
                    [9.323, 10.21],
                    [-10.21, 9.322],
                  ],
                  o: [
                    [9.321, 10.21],
                    [-10.21, 9.322],
                    [-9.321, -10.21],
                    [10.21, -9.326],
                  ],
                  v: [
                    [191.535, -173.692],
                    [189.924, -138.323],
                    [154.556, -139.928],
                    [156.162, -175.297],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-25.366, -27.782],
                    [27.786, -25.366],
                    [25.367, 27.785],
                    [-27.786, 25.367],
                  ],
                  o: [
                    [25.367, 27.784],
                    [-27.781, 25.367],
                    [-25.366, -27.782],
                    [27.781, -25.367],
                  ],
                  v: [
                    [50.916, -45.305],
                    [46.541, 50.935],
                    [-49.696, 46.556],
                    [-45.32, -49.681],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-89.993, 19.781],
                    [-10.373, -47.189],
                    [89.992, -19.777],
                    [10.373, 47.189],
                  ],
                  o: [
                    [89.988, -19.782],
                    [10.373, 47.192],
                    [-89.998, 19.783],
                    [-10.373, -47.188],
                  ],
                  v: [
                    [-58.863, -270.332],
                    [137.928, -217.901],
                    [-22.443, -104.644],
                    [-215.535, -140.208],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.416, -6.769],
                        [10.985, 11.787],
                        [3.82, -4.279],
                        [6.155, -7.704],
                        [12.554, 9.48],
                        [3.09, -4.813],
                        [4.792, -8.424],
                        [13.68, 6.999],
                        [2.294, -5.227],
                        [3.354, -8.918],
                        [14.323, 4.417],
                        [1.443, -5.51],
                        [1.886, -9.154],
                        [14.519, 1.832],
                        [0.584, -5.677],
                        [0.416, -9.176],
                        [14.331, -0.65],
                        [-0.301, -5.71],
                        [-1.01, -9.009],
                        [13.788, -2.99],
                        [-1.176, -5.609],
                        [-2.39, -8.641],
                        [12.95, -5.151],
                        [-2.036, -5.368],
                        [-3.679, -8.104],
                        [11.82, -7.124],
                        [-2.857, -5.001],
                        [-4.866, -7.37],
                        [10.437, -8.867],
                        [-3.591, -4.509],
                        [-5.931, -6.49],
                        [8.892, -10.381],
                        [-4.195, -3.87],
                        [-6.874, -5.48],
                        [7.232, -11.678],
                        [-4.747, -3.182],
                        [-7.683, -4.367],
                        [5.447, -12.75],
                        [-5.185, -2.416],
                        [-8.333, -3.128],
                        [3.509, -13.564],
                        [-5.487, -1.58],
                        [-8.796, -1.802],
                        [1.489, -14.127],
                        [-5.672, -0.713],
                        [-9.064, -0.405],
                        [-0.571, -14.455],
                        [-5.714, 0.171],
                        [-9.154, 1.03],
                        [-2.674, -14.548],
                        [-5.613, 1.044],
                        [-9.035, 2.495],
                        [-4.792, -14.39],
                        [-5.397, 1.91],
                        [-8.688, 3.945],
                        [-6.915, -13.905],
                        [-5.055, 2.758],
                        [-8.1, 5.351],
                        [-9.008, -13.104],
                        [-4.588, 3.533],
                        [-7.29, 6.665],
                        [-11.007, -12.012],
                        [-3.971, 4.171],
                        [-6.272, 7.865],
                        [-12.762, -10.189],
                        [-0.208, -0.183],
                        [-3.299, 4.801],
                        [0.158, 0.092],
                        [-5.006, 8.825],
                        [-14.197, -8.066],
                        [-0.475, -0.301],
                        [-2.524, 5.325],
                        [0.435, 0.163],
                        [-3.562, 9.505],
                        [-15.295, -5.751],
                        [-0.755, -0.329],
                        [-1.669, 5.719],
                        [0.704, 0.142],
                        [-2.039, 9.943],
                        [-16.004, -3.291],
                        [-1.007, -0.274],
                        [-0.756, 5.969],
                        [0.935, 0.046],
                        [-0.459, 10.136],
                        [-16.314, -0.742],
                        [-1.233, -0.146],
                        [0.196, 6.077],
                        [1.143, -0.129],
                        [1.139, 10.089],
                        [-16.232, 1.819],
                        [-1.435, 0.033],
                        [1.178, 6.044],
                        [1.302, -0.353],
                        [2.699, 9.781],
                        [-15.752, 4.334],
                        [-1.567, 0.263],
                        [2.14, 5.835],
                        [1.359, -0.614],
                        [4.196, 9.243],
                        [-14.877, 6.749],
                        [-1.602, 0.526],
                        [3.073, 5.456],
                        [1.335, -0.872],
                        [5.593, 8.467],
                        [-13.643, 8.993],
                        [-1.543, 0.793],
                        [3.908, 4.912],
                        [1.185, -1.084],
                        [6.849, 7.495],
                        [-12.063, 11.014],
                        [-1.406, 1.017],
                        [4.579, 4.188],
                        [1.001, -1.255],
                        [7.938, 6.328],
                        [-10.193, 12.762],
                        [-1.217, 1.214],
                        [5.192, 3.411],
                        [0.784, -1.376],
                        [8.829, 5.013],
                        [-8.065, 14.197],
                        [-0.968, 1.343],
                        [5.65, 2.527],
                        [0.521, -1.388],
                        [9.5, 3.562],
                        [-5.748, 15.286],
                        [-0.68, 1.368],
                        [5.938, 1.589],
                        [0.268, -1.302],
                        [9.938, 2.036],
                        [-3.29, 16],
                        [-0.388, 1.292],
                        [6.056, 0.626],
                        [0.046, -1.126],
                        [10.135, 0.447],
                        [-0.742, 16.321],
                        [-0.138, 1.151],
                        [6.032, -0.329],
                        [-0.109, -0.913],
                        [10.075, -1.143],
                        [1.818, 16.236],
                        [0.055, 0.934],
                        [5.843, -1.26],
                        [-0.184, -0.658],
                        [9.78, -2.707],
                        [4.341, 15.745],
                        [0.164, 0.692],
                        [5.522, -2.156],
                        [-0.176, -0.392],
                        [9.234, -4.2],
                        [6.744, 14.872],
                        [0.166, 0.405],
                        [5.067, -2.974],
                        [-0.076, -0.113],
                        [8.472, -5.592],
                        [8.992, 13.639],
                        [0.075, 0.108],
                        [4.509, -3.696],
                      ],
                      o: [
                        [-7.416, 6.769],
                        [-4.067, 4.122],
                        [12.004, 10.143],
                        [-6.16, 7.716],
                        [-3.353, 4.68],
                        [12.983, 8.17],
                        [-4.788, 8.438],
                        [-2.56, 5.122],
                        [13.663, 6.118],
                        [-3.354, 8.925],
                        [-1.736, 5.447],
                        [14.023, 4.05],
                        [-1.881, 9.181],
                        [-0.863, 5.652],
                        [14.118, 1.993],
                        [-0.418, 9.222],
                        [0.017, 5.713],
                        [13.989, -0.062],
                        [1.014, 9.064],
                        [0.892, 5.643],
                        [13.617, -2.077],
                        [2.393, 8.722],
                        [1.752, 5.435],
                        [13.013, -4.041],
                        [3.704, 8.174],
                        [2.574, 5.096],
                        [12.141, -5.897],
                        [4.917, 7.453],
                        [3.328, 4.638],
                        [11.027, -7.621],
                        [6.006, 6.581],
                        [4.041, 4.117],
                        [9.702, -9.254],
                        [6.995, 5.585],
                        [4.609, 3.441],
                        [8.138, -10.766],
                        [7.833, 4.45],
                        [5.08, 2.675],
                        [6.339, -12.079],
                        [8.491, 3.199],
                        [5.422, 1.856],
                        [4.326, -13.104],
                        [8.976, 1.848],
                        [5.636, 0.988],
                        [2.114, -13.855],
                        [9.287, 0.425],
                        [5.706, 0.117],
                        [-0.271, -14.289],
                        [9.376, -1.052],
                        [5.65, -0.763],
                        [-2.785, -14.368],
                        [9.267, -2.548],
                        [5.464, -1.631],
                        [-5.381, -14.025],
                        [8.909, -4.041],
                        [5.156, -2.478],
                        [-7.912, -13.191],
                        [8.317, -5.472],
                        [4.718, -3.262],
                        [-10.311, -11.898],
                        [7.483, -6.823],
                        [4.218, -4.013],
                        [-12.542, -10.181],
                        [6.336, -7.929],
                        [0.222, 0.174],
                        [3.575, -4.657],
                        [-0.158, -0.088],
                        [-14.206, -8.066],
                        [5.018, -8.816],
                        [0.504, 0.288],
                        [2.815, -5.218],
                        [-0.435, -0.151],
                        [-15.298, -5.743],
                        [3.573, -9.488],
                        [0.779, 0.297],
                        [1.969, -5.634],
                        [-0.693, -0.112],
                        [-16.008, -3.282],
                        [2.044, -9.939],
                        [1.037, 0.217],
                        [1.063, -5.922],
                        [-0.918, 0.009],
                        [-16.323, -0.733],
                        [0.464, -10.139],
                        [1.291, 0.059],
                        [0.131, -6.077],
                        [-1.101, 0.2],
                        [-16.236, 1.822],
                        [-1.127, -10.086],
                        [1.477, -0.166],
                        [-0.844, -6.086],
                        [-1.221, 0.45],
                        [-15.753, 4.343],
                        [-2.694, -9.784],
                        [1.593, -0.433],
                        [-1.811, -5.914],
                        [-1.252, 0.713],
                        [-14.878, 6.756],
                        [-4.19, -9.238],
                        [1.61, -0.725],
                        [-2.74, -5.577],
                        [-1.186, 0.963],
                        [-13.635, 9],
                        [-5.584, -8.475],
                        [1.506, -0.997],
                        [-3.6, -5.063],
                        [-1.03, 1.146],
                        [-12.062, 11.027],
                        [-6.845, -7.491],
                        [1.334, -1.219],
                        [-4.396, -4.483],
                        [-0.847, 1.293],
                        [-10.189, 12.779],
                        [-7.929, -6.334],
                        [1.121, -1.406],
                        [-5.037, -3.72],
                        [-0.617, 1.392],
                        [-8.062, 14.21],
                        [-8.817, -5.009],
                        [0.855, -1.501],
                        [-5.531, -2.844],
                        [-0.381, 1.372],
                        [-5.748, 15.299],
                        [-9.493, -3.575],
                        [0.559, -1.485],
                        [-5.868, -1.906],
                        [-0.159, 1.28],
                        [-3.281, 16.004],
                        [-9.943, -2.048],
                        [0.283, -1.372],
                        [-6.021, -0.947],
                        [0.017, 1.093],
                        [-0.734, 16.324],
                        [-10.139, -0.467],
                        [0.05, -1.188],
                        [-6.027, 0.012],
                        [0.15, 0.884],
                        [1.827, 16.233],
                        [-10.089, 1.123],
                        [-0.108, -0.956],
                        [-5.89, 0.96],
                        [0.204, 0.646],
                        [4.341, 15.749],
                        [-9.785, 2.69],
                        [-0.185, -0.691],
                        [-5.601, 1.856],
                        [0.184, 0.38],
                        [6.757, 14.881],
                        [-9.246, 4.183],
                        [-0.18, -0.4],
                        [-5.18, 2.69],
                        [0.08, 0.117],
                        [8.995, 13.648],
                        [-8.475, 5.586],
                        [-0.07, -0.117],
                        [-4.646, 3.428],
                        [10.744, 12.007],
                      ],
                      v: [
                        [-212.094, -232.294],
                        [-245.264, -241.408],
                        [-257.097, -228.795],
                        [-246.452, -196.855],
                        [-279.985, -200.176],
                        [-289.657, -185.932],
                        [-274.848, -156.474],
                        [-307.772, -154.134],
                        [-315.054, -138.614],
                        [-296.569, -112.146],
                        [-327.958, -104.427],
                        [-332.722, -87.993],
                        [-311.101, -64.975],
                        [-340.116, -52.346],
                        [-342.289, -35.354],
                        [-318.064, -16.106],
                        [-344.133, 0.883],
                        [-343.657, 18.02],
                        [-317.298, 33.252],
                        [-339.878, 54.002],
                        [-336.775, 70.881],
                        [-308.814, 81.883],
                        [-327.537, 105.778],
                        [-321.86, 121.985],
                        [-292.841, 128.593],
                        [-307.33, 154.968],
                        [-299.192, 170.117],
                        [-269.755, 172.218],
                        [-279.753, 200.392],
                        [-269.367, 214.119],
                        [-240.121, 211.7],
                        [-245.552, 241.041],
                        [-233.198, 253.031],
                        [-204.687, 246.058],
                        [-205.537, 276.021],
                        [-191.498, 285.952],
                        [-164.305, 274.453],
                        [-160.576, 304.424],
                        [-145.182, 312.057],
                        [-119.978, 296.178],
                        [-111.69, 325.516],
                        [-95.316, 330.663],
                        [-72.802, 310.701],
                        [-60.073, 338.804],
                        [-43.109, 341.365],
                        [-23.936, 317.667],
                        [-6.933, 344.009],
                        [10.201, 343.926],
                        [25.425, 316.904],
                        [46.471, 340.935],
                        [63.37, 338.219],
                        [74.057, 308.424],
                        [98.864, 329.649],
                        [115.147, 324.331],
                        [120.757, 292.45],
                        [148.93, 310.247],
                        [164.254, 302.401],
                        [164.383, 269.356],
                        [195.463, 283.182],
                        [209.424, 272.993],
                        [203.868, 239.731],
                        [237.31, 249.124],
                        [249.584, 236.841],
                        [238.227, 204.292],
                        [272.807, 208.392],
                        [273.44, 208.937],
                        [283.743, 194.741],
                        [283.268, 194.491],
                        [266.618, 163.906],
                        [301.411, 162.551],
                        [302.857, 163.439],
                        [310.87, 147.628],
                        [309.576, 147.185],
                        [288.344, 119.583],
                        [322.502, 112.801],
                        [324.792, 113.748],
                        [330.256, 96.714],
                        [328.158, 96.351],
                        [302.87, 72.407],
                        [335.545, 60.366],
                        [338.606, 61.104],
                        [341.334, 43.269],
                        [338.56, 43.228],
                        [309.835, 23.542],
                        [340.223, 6.533],
                        [344.002, 6.872],
                        [343.886, -11.367],
                        [340.52, -10.859],
                        [309.072, -25.815],
                        [336.424, -47.366],
                        [340.783, -47.632],
                        [337.771, -65.825],
                        [333.997, -64.596],
                        [300.597, -74.443],
                        [324.241, -100.01],
                        [328.983, -101.035],
                        [323.053, -118.661],
                        [319.149, -116.646],
                        [284.618, -121.151],
                        [303.972, -150.097],
                        [308.797, -151.961],
                        [300.093, -168.511],
                        [296.335, -165.737],
                        [261.529, -164.778],
                        [276.123, -196.396],
                        [280.707, -199.053],
                        [269.438, -214.009],
                        [266.13, -210.652],
                        [231.9, -204.262],
                        [241.359, -237.77],
                        [245.477, -241.112],
                        [232.013, -254.116],
                        [229.272, -250.287],
                        [196.457, -238.622],
                        [200.561, -273.198],
                        [204.08, -277.114],
                        [188.736, -287.82],
                        [186.659, -283.666],
                        [156.075, -267.016],
                        [154.719, -301.805],
                        [157.469, -306.059],
                        [140.682, -314.113],
                        [139.358, -309.972],
                        [111.752, -288.738],
                        [104.971, -322.888],
                        [106.843, -327.159],
                        [89.134, -332.422],
                        [88.516, -328.552],
                        [64.58, -303.261],
                        [52.535, -335.934],
                        [53.557, -339.917],
                        [35.435, -342.274],
                        [35.401, -338.954],
                        [15.711, -310.226],
                        [-1.297, -340.618],
                        [-0.993, -344.126],
                        [-19.078, -343.596],
                        [-18.689, -340.91],
                        [-33.642, -309.463],
                        [-55.196, -336.819],
                        [-55.426, -339.655],
                        [-73.026, -336.335],
                        [-72.422, -334.392],
                        [-82.273, -300.988],
                        [-107.844, -324.632],
                        [-108.346, -326.709],
                        [-125.037, -320.686],
                        [-124.478, -319.543],
                        [-128.979, -285.009],
                        [-157.928, -304.361],
                        [-158.424, -305.567],
                        [-173.802, -297.075],
                        [-173.564, -296.738],
                        [-172.613, -261.92],
                        [-204.228, -276.505],
                        [-204.437, -276.851],
                        [-218.162, -266.153],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_13",
      nm: "Gear 62",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.14, 0.14, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-12.782, -0.582],
                    [0, 0],
                    [0.577, -12.777],
                    [0, 0],
                    [12.777, 0.582],
                    [0, 0],
                    [-0.589, 12.777],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [12.783, 0.583],
                    [0, 0],
                    [-0.582, 12.777],
                    [0, 0],
                    [-12.776, -0.583],
                    [0, 0],
                    [0.577, -12.777],
                  ],
                  v: [
                    [242.328, -15.665],
                    [299.116, -13.086],
                    [321.3, 11.202],
                    [320.943, 18.95],
                    [296.656, 41.123],
                    [239.862, 38.544],
                    [217.69, 14.256],
                    [218.041, 6.507],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-8.626, -9.445],
                    [0, 0],
                    [9.445, -8.626],
                    [0, 0],
                    [8.625, 9.445],
                    [0, 0],
                    [-9.446, 8.626],
                  ],
                  o: [
                    [9.446, -8.626],
                    [0, 0],
                    [8.626, 9.445],
                    [0, 0],
                    [-9.451, 8.626],
                    [0, 0],
                    [-8.62, -9.445],
                    [0, 0],
                  ],
                  v: [
                    [202.051, -220.259],
                    [234.908, -218.767],
                    [240.134, -213.042],
                    [238.641, -180.186],
                    [196.66, -141.854],
                    [163.799, -143.347],
                    [158.572, -149.071],
                    [160.065, -181.928],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-12.777, -0.583],
                    [0, 0],
                    [0.577, -12.776],
                    [0, 0],
                    [12.771, 0.582],
                    [0, 0],
                    [-0.582, 12.777],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [12.777, 0.583],
                    [0, 0],
                    [-0.588, 12.777],
                    [0, 0],
                    [-12.777, -0.577],
                    [0, 0],
                    [0.576, -12.783],
                  ],
                  v: [
                    [11.207, -320.294],
                    [18.95, -319.943],
                    [41.128, -295.656],
                    [38.549, -238.862],
                    [14.262, -216.685],
                    [6.512, -217.041],
                    [-15.666, -241.329],
                    [-13.08, -298.116],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-9.451, 8.625],
                    [0, 0],
                    [-8.621, -9.446],
                    [0, 0],
                    [9.445, -8.625],
                    [0, 0],
                    [8.625, 9.445],
                  ],
                  o: [
                    [-8.626, -9.446],
                    [0, 0],
                    [9.445, -8.626],
                    [0, 0],
                    [8.62, 9.446],
                    [0, 0],
                    [-9.445, 8.626],
                    [0, 0],
                  ],
                  v: [
                    [-220.26, -201.051],
                    [-218.762, -233.907],
                    [-213.037, -239.134],
                    [-180.186, -237.641],
                    [-141.849, -195.655],
                    [-143.342, -162.799],
                    [-149.072, -157.572],
                    [-181.928, -159.065],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [12.777, 0.583],
                    [0, 0],
                    [-0.588, 12.777],
                    [0, 0],
                    [-12.783, -0.582],
                    [0, 0],
                    [0.577, -12.777],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-12.777, -0.583],
                    [0, 0],
                    [0.577, -12.777],
                    [0, 0],
                    [12.783, 0.577],
                    [0, 0],
                    [-0.583, 12.777],
                  ],
                  v: [
                    [-241.324, 16.665],
                    [-298.117, 14.087],
                    [-320.289, -10.207],
                    [-319.938, -17.95],
                    [-295.65, -40.128],
                    [-238.863, -37.543],
                    [-216.68, -13.255],
                    [-217.036, -5.513],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [8.631, 9.446],
                    [0, 0],
                    [-9.446, 8.619],
                    [0, 0],
                    [-8.62, -9.446],
                    [0, 0],
                    [9.446, -8.626],
                  ],
                  o: [
                    [-9.446, 8.626],
                    [0, 0],
                    [-8.619, -9.452],
                    [0, 0],
                    [9.446, -8.626],
                    [0, 0],
                    [8.631, 9.446],
                    [0, 0],
                  ],
                  v: [
                    [-201.046, 221.26],
                    [-233.908, 219.767],
                    [-239.135, 214.043],
                    [-237.642, 181.186],
                    [-195.65, 142.854],
                    [-162.799, 144.348],
                    [-157.572, 150.071],
                    [-159.066, 182.929],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [72.297, 79.186],
                    [-79.187, 72.297],
                    [-72.297, -79.181],
                    [79.18, -72.297],
                  ],
                  o: [
                    [-72.297, -79.181],
                    [79.18, -72.291],
                    [72.297, 79.186],
                    [-79.182, 72.298],
                  ],
                  v: [
                    [-142.873, 131.4],
                    [-130.4, -142.878],
                    [143.877, -130.405],
                    [131.406, 143.872],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [12.783, 0.583],
                    [0, 0],
                    [-0.576, 12.782],
                    [0, 0],
                    [-12.783, -0.583],
                    [0, 0],
                    [0.578, -12.777],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-12.783, -0.583],
                    [0, 0],
                    [0.582, -12.783],
                    [0, 0],
                    [12.782, 0.582],
                    [0, 0],
                    [-0.582, 12.777],
                  ],
                  v: [
                    [-10.207, 321.295],
                    [-17.945, 320.944],
                    [-40.129, 296.651],
                    [-37.543, 239.863],
                    [-13.25, 217.686],
                    [-5.513, 218.036],
                    [16.67, 242.323],
                    [14.086, 299.117],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 8",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [8.625, 9.451],
                    [0, 0],
                    [-9.451, 8.625],
                    [0, 0],
                    [-8.62, -9.446],
                    [0, 0],
                    [9.452, -8.62],
                  ],
                  o: [
                    [-9.446, 8.62],
                    [0, 0],
                    [-8.625, -9.445],
                    [0, 0],
                    [9.446, -8.626],
                    [0, 0],
                    [8.619, 9.446],
                    [0, 0],
                  ],
                  v: [
                    [214.042, 240.135],
                    [181.186, 238.636],
                    [142.854, 196.655],
                    [144.353, 163.8],
                    [150.076, 158.573],
                    [182.928, 160.066],
                    [221.266, 202.046],
                    [219.766, 234.902],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 9",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-12.913, -5.198],
                        [0, 0],
                        [0, 0],
                        [-13.648, -2.732],
                        [0, 0],
                        [0, 0],
                        [-13.92, -0.182],
                        [0, 0],
                        [0, 0],
                        [-13.71, 2.376],
                        [0, 0],
                        [0, 0],
                        [-13.043, 4.853],
                        [0, 0],
                        [0, 0],
                        [-11.929, 7.172],
                        [0, 0],
                        [0, 0],
                        [-10.407, 9.242],
                        [0, 0],
                        [0, 0],
                        [-8.529, 10.995],
                        [0, 0],
                        [0, 0],
                        [-6.369, 12.376],
                        [0, 0],
                        [0, 0],
                        [-3.986, 13.337],
                        [0, 0],
                        [0, 0],
                        [-1.459, 13.84],
                        [0, 0],
                        [0, 0],
                        [1.098, 13.875],
                        [0, 0],
                        [0, 0],
                        [3.632, 13.439],
                        [0, 0],
                        [0, 0],
                        [6.041, 12.545],
                        [0, 0],
                        [0, 0],
                        [8.247, 11.217],
                        [0, 0],
                        [0, 0],
                        [10.164, 9.508],
                        [0, 0],
                        [0, 0],
                        [11.742, 7.479],
                        [0, 0],
                        [0, 0],
                        [12.907, 5.198],
                        [0, 0],
                        [0, 0],
                        [13.643, 2.737],
                        [0, 0],
                        [0, 0],
                        [13.92, 0.181],
                        [0, 0],
                        [0, 0],
                        [13.723, -2.382],
                        [0, 0],
                        [0, 0],
                        [13.043, -4.858],
                        [0, 0],
                        [0, 0],
                        [11.935, -7.172],
                        [0, 0],
                        [0, 0],
                        [10.407, -9.242],
                        [0, 0],
                        [0, 0],
                        [8.535, -10.996],
                        [0, 0],
                        [0, 0],
                        [6.368, -12.376],
                        [0, 0],
                        [0, 0],
                        [3.982, -13.332],
                        [0, 0],
                        [0, 0],
                        [1.466, -13.841],
                        [0, 0],
                        [0, 0],
                        [-1.108, -13.875],
                        [0, 0],
                        [0, 0],
                        [-3.637, -13.433],
                        [0, 0],
                        [0, 0],
                        [-6.04, -12.54],
                        [0, 0],
                        [0, 0],
                        [-8.24, -11.216],
                        [0, 0],
                        [0, 0],
                        [-10.165, -9.514],
                        [0, 0],
                        [0, 0],
                        [-11.737, -7.483],
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                        [-4.004, -13.332],
                        [0, 0],
                        [0, 0],
                        [-6.386, -12.37],
                        [0, 0],
                        [0, 0],
                        [-8.546, -10.978],
                        [0, 0],
                        [0, 0],
                        [-10.419, -9.225],
                        [0, 0],
                        [0, 0],
                        [-11.934, -7.155],
                        [0, 0],
                        [0, 0],
                        [-13.049, -4.842],
                        [0, 0],
                        [0, 0],
                        [-13.722, -2.352],
                        [0, 0],
                        [0, 0],
                        [-13.92, 0.204],
                        [0, 0],
                        [0, 0],
                        [-13.643, 2.754],
                        [0, 0],
                        [0, 0],
                        [-12.901, 5.215],
                        [0, 0],
                        [0, 0],
                        [-11.725, 7.5],
                        [0, 0],
                        [0, 0],
                        [-10.146, 9.524],
                        [0, 0],
                        [0, 0],
                        [-8.225, 11.228],
                        [0, 0],
                        [0, 0],
                        [-6.019, 12.551],
                        [0, 0],
                        [0, 0],
                        [-3.614, 13.439],
                        [0, 0],
                        [0, 0],
                        [-1.081, 13.875],
                        [0, 0],
                        [0, 0],
                        [1.487, 13.841],
                        [0, 0],
                        [0, 0],
                        [4.005, 13.331],
                        [0, 0],
                        [0, 0],
                        [6.392, 12.364],
                        [0, 0],
                        [0, 0],
                        [8.552, 10.984],
                        [0, 0],
                        [0, 0],
                        [10.418, 9.225],
                        [0, 0],
                        [0, 0],
                        [11.94, 7.15],
                        [0, 0],
                        [0, 0],
                        [13.055, 4.836],
                        [0, 0],
                        [0, 0],
                        [13.717, 2.359],
                        [0, 0],
                        [0, 0],
                        [13.92, -0.203],
                        [0, 0],
                        [0, 0],
                        [13.643, -2.761],
                        [0, 0],
                        [0, 0],
                        [12.908, -5.216],
                        [0, 0],
                        [0, 0],
                        [11.727, -7.501],
                        [0, 0],
                        [0, 0],
                        [10.146, -9.531],
                        [0, 0],
                        [0, 0],
                        [8.224, -11.232],
                        [0, 0],
                        [0, 0],
                        [6.019, -12.551],
                        [0, 0],
                        [0, 0],
                        [3.608, -13.445],
                        [0, 0],
                        [0, 0],
                        [1.08, -13.88],
                        [0, 0],
                        [0, 0],
                        [-1.482, -13.835],
                      ],
                      v: [
                        [222.762, 268.306],
                        [257.875, 282.435],
                        [246.986, 246.19],
                        [268.271, 222.802],
                        [305.381, 230.245],
                        [288.017, 196.614],
                        [304.646, 169.714],
                        [342.485, 170.211],
                        [319.238, 140.341],
                        [330.642, 110.85],
                        [367.933, 104.379],
                        [339.59, 79.294],
                        [345.381, 48.208],
                        [380.846, 34.995],
                        [348.379, 15.544],
                        [348.356, -16.08],
                        [380.795, -35.583],
                        [345.303, -48.733],
                        [339.471, -79.814],
                        [367.774, -104.944],
                        [330.472, -111.358],
                        [319.023, -140.832],
                        [342.225, -170.736],
                        [304.386, -170.183],
                        [287.717, -197.055],
                        [305.03, -230.714],
                        [267.926, -223.214],
                        [246.607, -246.568],
                        [257.439, -282.836],
                        [222.349, -268.645],
                        [197.1, -287.683],
                        [201.088, -325.319],
                        [169.203, -304.929],
                        [140.889, -319.001],
                        [137.885, -356.733],
                        [110.289, -330.828],
                        [79.87, -339.46],
                        [69.988, -375.992],
                        [47.625, -345.461],
                        [16.137, -348.357],
                        [-0.289, -382.452],
                        [-16.669, -348.328],
                        [-48.151, -345.388],
                        [-70.561, -375.885],
                        [-80.386, -339.335],
                        [-110.793, -330.658],
                        [-138.43, -356.519],
                        [-141.37, -318.786],
                        [-169.668, -304.669],
                        [-201.58, -325.014],
                        [-197.541, -287.384],
                        [-222.757, -268.305],
                        [-257.87, -282.439],
                        [-246.982, -246.189],
                        [-268.266, -222.807],
                        [-305.382, -230.245],
                        [-288.018, -196.613],
                        [-304.641, -169.718],
                        [-342.486, -170.21],
                        [-319.239, -140.346],
                        [-330.643, -110.849],
                        [-367.928, -104.384],
                        [-339.59, -79.293],
                        [-345.377, -48.207],
                        [-380.846, -35],
                        [-348.38, -15.543],
                        [-348.357, 16.075],
                        [-380.789, 35.577],
                        [-345.303, 48.733],
                        [-339.466, 79.814],
                        [-367.77, 104.944],
                        [-330.467, 111.353],
                        [-319.024, 140.833],
                        [-342.227, 170.737],
                        [-304.381, 170.182],
                        [-287.712, 197.056],
                        [-305.025, 230.709],
                        [-267.927, 223.215],
                        [-246.604, 246.563],
                        [-257.44, 282.831],
                        [-222.35, 268.645],
                        [-197.101, 287.684],
                        [-201.082, 325.319],
                        [-169.199, 304.93],
                        [-140.878, 319.002],
                        [-137.881, 356.728],
                        [-110.289, 330.829],
                        [-79.865, 339.454],
                        [-69.984, 375.993],
                        [-47.619, 345.461],
                        [-16.132, 348.352],
                        [0.294, 382.452],
                        [16.668, 348.329],
                        [48.15, 345.388],
                        [70.561, 375.886],
                        [80.391, 339.336],
                        [110.799, 330.659],
                        [138.434, 356.518],
                        [141.375, 318.787],
                        [169.668, 304.669],
                        [201.585, 325.015],
                        [197.541, 287.378],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_14",
      nm: "Gear 62",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.14, 0.14, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-10.024, -10.98],
                    [10.979, -10.023],
                    [10.024, 10.979],
                    [-10.98, 10.024],
                  ],
                  o: [
                    [10.024, 10.979],
                    [-10.979, 10.024],
                    [-10.023, -10.98],
                    [10.979, -10.023],
                  ],
                  v: [
                    [101.113, 75.964],
                    [99.38, 113.995],
                    [61.349, 112.262],
                    [63.083, 74.231],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [10.024, 10.979],
                    [-10.979, 10.024],
                    [-10.023, -10.979],
                    [10.979, -10.024],
                  ],
                  o: [
                    [-10.031, -10.986],
                    [10.98, -10.031],
                    [10.024, 10.978],
                    [-10.979, 10.024],
                  ],
                  v: [
                    [-111.721, 102.719],
                    [-109.995, 64.688],
                    [-71.964, 66.415],
                    [-73.69, 104.445],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-10.023, -10.979],
                    [10.979, -10.024],
                    [10.023, 10.98],
                    [-10.979, 10.023],
                  ],
                  o: [
                    [10.024, 10.98],
                    [-10.979, 10.023],
                    [-10.024, -10.978],
                    [10.98, -10.024],
                  ],
                  v: [
                    [110.663, -97.113],
                    [108.931, -59.082],
                    [70.899, -60.808],
                    [72.632, -98.838],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-25.893, -28.36],
                    [28.361, -25.892],
                    [25.892, 28.361],
                    [-28.36, 25.892],
                  ],
                  o: [
                    [25.898, 28.36],
                    [-28.359, 25.898],
                    [-25.892, -28.359],
                    [28.36, -25.892],
                  ],
                  v: [
                    [52.04, -46.808],
                    [47.575, 51.424],
                    [-50.656, 46.959],
                    [-46.192, -51.272],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-10.024, -10.979],
                    [10.986, -10.023],
                    [10.031, 10.979],
                    [-10.979, 10.024],
                  ],
                  o: [
                    [10.023, 10.979],
                    [-10.979, 10.024],
                    [-10.017, -10.98],
                    [10.979, -10.024],
                  ],
                  v: [
                    [-62.413, -106.663],
                    [-64.147, -68.632],
                    [-102.178, -70.358],
                    [-100.444, -108.389],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [-9.069, -7.456],
                        [0, 0],
                        [7.746, -13.533],
                        [14.871, 12.224],
                        [0, 0],
                        [6.281, -10.328],
                        [2.873, -5.192],
                        [-10.859, -4.62],
                        [0, 0],
                        [3.615, -15.167],
                        [17.713, 7.541],
                        [0, 0],
                        [3.092, -11.701],
                        [1.288, -5.793],
                        [-11.701, -1.373],
                        [0, 0],
                        [2.031, -17.325],
                        [16.609, 1.945],
                        [0, 0],
                        [-0.313, -12.09],
                        [-0.39, -5.921],
                        [-11.637, 1.982],
                        [0, 0],
                        [-2.935, -17.204],
                        [17.197, -2.929],
                        [0, 0],
                        [-3.7, -11.51],
                        [-2.052, -5.589],
                        [-10.604, 5.178],
                        [0, 0],
                        [-7.661, -15.677],
                        [0, 0],
                        [15.684, -7.654],
                        [0, 0],
                        [-6.813, -10.002],
                        [-3.545, -4.782],
                        [-8.716, 7.951],
                        [0, 0],
                        [-11.765, -12.889],
                        [0, 0],
                        [12.882, -11.771],
                        [0, 0],
                        [-9.324, -7.689],
                        [-4.711, -3.579],
                        [-6.119, 10.088],
                        [0, 0],
                        [-14.502, -5.736],
                        [9.982, -16.455],
                        [0, 0],
                        [-11.143, -4.732],
                        [-5.547, -2.101],
                        [-3.027, 11.39],
                        [0, 0],
                        [-16.865, -4.484],
                        [0, 0],
                        [4.484, -16.864],
                        [0, 0],
                        [-12.025, -1.407],
                        [-5.906, -0.445],
                        [0.305, 11.785],
                        [0, 0],
                        [-17.438, 0.453],
                        [-0.452, -17.445],
                        [0, 0],
                        [-11.92, 2.016],
                        [-5.814, 1.224],
                        [3.615, 11.233],
                        [0, 0],
                        [-16.603, 5.349],
                        [0, 0],
                        [-5.349, -16.61],
                        [0, 0],
                        [-10.873, 5.313],
                        [-5.242, 2.837],
                        [6.628, 9.755],
                        [0, 0],
                        [-14.432, 9.813],
                        [-9.813, -14.432],
                        [0, 0],
                        [-8.899, 8.114],
                        [-0.057, 0.049],
                        [-4.201, 4.167],
                        [9.076, 7.456],
                        [0, 0],
                        [-11.085, 13.477],
                        [-13.484, -11.078],
                        [0, 0],
                        [-6.283, 10.336],
                        [-2.871, 5.186],
                        [10.852, 4.619],
                        [0, 0],
                        [-6.834, 16.059],
                        [-16.051, -6.834],
                        [0, 0],
                        [-3.1, 11.708],
                        [-1.279, 5.786],
                        [11.707, 1.38],
                        [0, 0],
                        [-2.037, 17.332],
                        [-16.617, -1.953],
                        [0, 0],
                        [0.303, 12.09],
                        [0.389, 5.921],
                        [11.629, -1.98],
                        [0, 0],
                        [5.164, 14.714],
                        [-18.973, 3.232],
                        [0, 0],
                        [3.699, 11.517],
                        [2.045, 5.589],
                        [10.604, -5.178],
                        [0, 0],
                        [9.146, 12.373],
                        [-17.424, 8.51],
                        [0, 0],
                        [6.805, 9.995],
                        [3.551, 4.789],
                        [8.707, -7.952],
                        [0, 0],
                        [11.766, 12.882],
                        [-12.89, 11.764],
                        [0, 0],
                        [9.324, 7.696],
                        [4.712, 3.579],
                        [6.119, -10.096],
                        [0, 0],
                        [14.502, 5.729],
                        [-9.981, 16.455],
                        [0, 0],
                        [11.135, 4.732],
                        [5.553, 2.095],
                        [3.028, -11.39],
                        [0, 0],
                        [15.528, 1.422],
                        [-4.938, 18.605],
                        [0, 0],
                        [12.02, 1.407],
                        [5.907, 0.453],
                        [-0.304, -11.793],
                        [0, 0],
                        [15.302, -3.014],
                        [0.495, 19.242],
                        [0, 0],
                        [11.92, -2.017],
                        [5.809, -1.231],
                        [-3.615, -11.234],
                        [0, 0],
                        [16.61, -5.348],
                        [5.002, 15.534],
                        [0, 0],
                        [10.866, -5.306],
                        [5.242, -2.83],
                        [-6.636, -9.749],
                        [0, 0],
                        [18.301, -8.361],
                        [8.765, 12.896],
                        [0, 0],
                        [8.893, -8.114],
                        [0.057, -0.049],
                        [4.202, -4.174],
                      ],
                      o: [
                        [0, 0],
                        [12.047, 9.897],
                        [-9.989, 17.473],
                        [0, 0],
                        [-9.338, -7.683],
                        [-3.092, 5.094],
                        [-5.716, 10.321],
                        [0, 0],
                        [14.348, 6.104],
                        [-4.662, 19.582],
                        [0, 0],
                        [-11.135, -4.747],
                        [-1.52, 5.744],
                        [-2.553, 11.503],
                        [0, 0],
                        [17.331, 2.037],
                        [11.277, 12.358],
                        [0, 0],
                        [-12.012, -1.408],
                        [0.148, 5.928],
                        [0.777, 11.77],
                        [0, 0],
                        [17.205, -2.929],
                        [2.929, 17.197],
                        [0, 0],
                        [-11.92, 2.03],
                        [1.81, 5.646],
                        [4.061, 11.078],
                        [0, 0],
                        [15.677, -7.661],
                        [0, 0],
                        [7.654, 15.684],
                        [0, 0],
                        [-10.872, 5.313],
                        [3.325, 4.889],
                        [7.024, 9.473],
                        [0, 0],
                        [12.883, -11.771],
                        [0, 0],
                        [11.764, 12.883],
                        [0, 0],
                        [-8.928, 8.149],
                        [4.592, 3.791],
                        [9.402, 7.138],
                        [0, 0],
                        [8.085, -13.328],
                        [18.712, 7.407],
                        [0, 0],
                        [-6.281, 10.35],
                        [5.475, 2.335],
                        [11.028, 4.167],
                        [0, 0],
                        [4.484, -16.865],
                        [0, 0],
                        [16.865, 4.485],
                        [0, 0],
                        [-3.105, 11.694],
                        [5.886, 0.686],
                        [11.758, 0.905],
                        [0, 0],
                        [-0.453, -17.444],
                        [17.445, -0.452],
                        [0, 0],
                        [0.312, 12.09],
                        [5.85, -0.991],
                        [11.545, -2.434],
                        [0, 0],
                        [-5.348, -16.609],
                        [0, 0],
                        [16.603, -5.348],
                        [0, 0],
                        [3.707, 11.517],
                        [5.327, -2.604],
                        [10.371, -5.604],
                        [0, 0],
                        [-9.813, -14.432],
                        [14.432, -9.812],
                        [0, 0],
                        [6.771, 9.953],
                        [0.057, -0.057],
                        [4.414, -4.033],
                        [8.342, -8.27],
                        [0, 0],
                        [-13.477, -11.078],
                        [11.078, -13.482],
                        [0, 0],
                        [9.344, 7.676],
                        [3.098, -5.086],
                        [5.717, -10.321],
                        [0, 0],
                        [-16.051, -6.834],
                        [6.834, -16.051],
                        [0, 0],
                        [11.143, 4.739],
                        [1.521, -5.744],
                        [2.555, -11.51],
                        [0, 0],
                        [-17.332, -2.037],
                        [-11.283, -12.351],
                        [0, 0],
                        [12.012, 1.415],
                        [-0.156, -5.921],
                        [-0.771, -11.771],
                        [0, 0],
                        [-15.371, 2.618],
                        [-6.658, -18.994],
                        [0, 0],
                        [11.92, -2.03],
                        [-1.811, -5.646],
                        [-4.061, -11.078],
                        [0, 0],
                        [-13.83, 6.756],
                        [-12.111, -16.377],
                        [0, 0],
                        [10.873, -5.313],
                        [-3.326, -4.889],
                        [-7.018, -9.473],
                        [0, 0],
                        [-12.889, 11.764],
                        [-11.764, -12.89],
                        [0, 0],
                        [8.928, -8.156],
                        [-4.598, -3.792],
                        [-9.395, -7.139],
                        [0, 0],
                        [-8.086, 13.335],
                        [-18.712, -7.407],
                        [0, 0],
                        [6.282, -10.35],
                        [-5.476, -2.327],
                        [-11.021, -4.167],
                        [0, 0],
                        [-4.004, 15.067],
                        [-20.041, -1.832],
                        [0, 0],
                        [3.105, -11.693],
                        [-5.893, -0.687],
                        [-11.758, -0.898],
                        [0, 0],
                        [0.402, 15.592],
                        [-19.744, 3.892],
                        [0, 0],
                        [-0.318, -12.083],
                        [-5.844, 0.99],
                        [-11.545, 2.434],
                        [0, 0],
                        [5.354, 16.609],
                        [-12.048, 11.001],
                        [0, 0],
                        [-3.707, -11.517],
                        [-5.326, 2.603],
                        [-10.377, 5.603],
                        [0, 0],
                        [10.823, 15.917],
                        [-14.184, 6.487],
                        [0, 0],
                        [-6.771, -9.961],
                        [-0.057, 0.05],
                        [-4.414, 4.025],
                        [-8.34, 8.262],
                      ],
                      v: [
                        [-309.356, -284.534],
                        [-301.759, -278.287],
                        [-293.574, -237.589],
                        [-341.347, -228.697],
                        [-348.13, -234.264],
                        [-377.757, -229.263],
                        [-386.706, -213.841],
                        [-377.085, -185.898],
                        [-367.938, -182],
                        [-348.618, -145.257],
                        [-391.954, -123.263],
                        [-399.978, -126.673],
                        [-427.001, -113.494],
                        [-431.21, -96.191],
                        [-414.097, -72.117],
                        [-403.407, -70.865],
                        [-375.593, -35.657],
                        [-391.042, -5.521],
                        [-419.403, -8.853],
                        [-441.622, 11.379],
                        [-440.815, 29.15],
                        [-417.606, 47.464],
                        [-407.03, 45.661],
                        [-370.421, 71.609],
                        [-396.369, 108.21],
                        [-404.999, 109.682],
                        [-420.619, 135.347],
                        [-414.825, 152.205],
                        [-387.399, 163.241],
                        [-377.672, 158.487],
                        [-335.241, 173.067],
                        [-335.241, 173.067],
                        [-349.828, 215.498],
                        [-357.652, 219.325],
                        [-365.398, 248.364],
                        [-355.091, 262.874],
                        [-325.67, 265.724],
                        [-317.726, 258.473],
                        [-272.903, 260.51],
                        [-272.903, 260.51],
                        [-274.94, 305.333],
                        [-281.371, 311.197],
                        [-280.636, 341.227],
                        [-266.679, 352.284],
                        [-237.632, 346.745],
                        [-232.51, 338.292],
                        [-193.383, 324.397],
                        [-177.784, 370.422],
                        [-182.333, 377.928],
                        [-173.136, 406.549],
                        [-156.597, 413.192],
                        [-130.323, 399.681],
                        [-127.55, 389.218],
                        [-88.741, 366.701],
                        [-88.741, 366.694],
                        [-66.224, 405.502],
                        [-68.446, 413.885],
                        [-51.56, 438.758],
                        [-33.86, 440.463],
                        [-12.446, 420.104],
                        [-12.722, 409.386],
                        [18.163, 376.845],
                        [50.704, 407.739],
                        [50.931, 416.44],
                        [74.106, 435.554],
                        [91.601, 432.229],
                        [106.435, 406.656],
                        [103.124, 396.391],
                        [123.597, 356.464],
                        [123.604, 356.464],
                        [163.524, 376.937],
                        [166.212, 385.278],
                        [193.851, 397.078],
                        [209.704, 388.928],
                        [216.708, 360.221],
                        [210.624, 351.28],
                        [219.021, 307.2],
                        [263.101, 315.597],
                        [267.988, 322.792],
                        [297.714, 326.414],
                        [297.884, 326.258],
                        [310.808, 313.956],
                        [309.358, 284.535],
                        [301.06, 277.708],
                        [296.702, 233.055],
                        [341.354, 228.691],
                        [348.132, 234.265],
                        [377.759, 229.256],
                        [386.714, 213.842],
                        [377.093, 185.892],
                        [367.104, 181.641],
                        [350.339, 140.023],
                        [391.956, 123.257],
                        [399.978, 126.674],
                        [427.009, 113.488],
                        [431.21, 96.192],
                        [414.099, 72.111],
                        [403.409, 70.859],
                        [375.601, 35.651],
                        [391.05, 5.522],
                        [419.411, 8.846],
                        [441.632, -11.385],
                        [440.817, -29.156],
                        [417.614, -47.471],
                        [407.929, -45.816],
                        [371.81, -66.281],
                        [396.378, -108.216],
                        [405.009, -109.688],
                        [420.628, -135.353],
                        [414.833, -152.205],
                        [387.407, -163.24],
                        [378.374, -158.826],
                        [338.354, -167.972],
                        [349.829, -215.503],
                        [357.661, -219.324],
                        [365.407, -248.363],
                        [355.093, -262.879],
                        [325.679, -265.73],
                        [317.733, -258.472],
                        [272.911, -260.509],
                        [274.949, -305.332],
                        [281.372, -311.197],
                        [280.644, -341.233],
                        [266.679, -352.283],
                        [237.64, -346.744],
                        [232.511, -338.297],
                        [193.392, -324.396],
                        [177.785, -370.421],
                        [182.341, -377.927],
                        [173.145, -406.556],
                        [156.599, -413.192],
                        [130.331, -399.68],
                        [127.785, -390.094],
                        [94.161, -365.746],
                        [66.226, -405.509],
                        [68.454, -413.885],
                        [51.567, -438.757],
                        [33.868, -440.47],
                        [12.454, -420.103],
                        [12.71, -410.298],
                        [-12.694, -377.46],
                        [-50.696, -407.738],
                        [-50.923, -416.446],
                        [-74.104, -435.56],
                        [-91.593, -432.228],
                        [-106.427, -406.655],
                        [-103.123, -396.39],
                        [-123.596, -356.464],
                        [-160.042, -366.148],
                        [-166.204, -385.277],
                        [-193.843, -397.083],
                        [-209.696, -388.934],
                        [-216.699, -360.227],
                        [-210.622, -351.279],
                        [-223.809, -304.511],
                        [-263.601, -316.353],
                        [-267.979, -322.791],
                        [-297.706, -326.413],
                        [-297.876, -326.257],
                        [-310.8, -313.955],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.4549019607843137, 0.7607843137254902,
                      0.8588235294117647, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_15",
      nm: "Gear 64",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.062, 0.062, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 31.0000012626559, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [25.146, -82.733],
                    [2.557, -6.125],
                    [0, 0],
                    [56.79, 42.498],
                    [0, 0],
                  ],
                  o: [
                    [-1.977, 6.488],
                    [0, 0],
                    [25.411, -66.012],
                    [0, 0],
                    [66.767, 47.678],
                  ],
                  v: [
                    [187.45, 54.999],
                    [180.638, 73.918],
                    [147.479, 55.231],
                    [94.553, -128.614],
                    [113.398, -162.042],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-82.063, -24.946],
                    [-6.783, -2.935],
                    [0, 0],
                    [43.323, -55.905],
                    [0, 0],
                  ],
                  o: [
                    [7.235, 2.197],
                    [0, 0],
                    [-65.766, -26.592],
                    [0, 0],
                    [47.912, -65.673],
                  ],
                  v: [
                    [56.577, -190.157],
                    [77.556, -182.356],
                    [58.661, -148.849],
                    [-126.034, -98.965],
                    [-159.259, -117.696],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-25.174, 82.842],
                    [-3.273, 7.343],
                    [0, 0],
                    [-58.227, -43.568],
                    [0, 0],
                  ],
                  o: [
                    [2.4, -7.884],
                    [0, 0],
                    [-28.478, 67.156],
                    [0, 0],
                    [-66.945, -47.643],
                  ],
                  v: [
                    [-188.585, -59.29],
                    [-179.925, -82.046],
                    [-146.973, -63.472],
                    [-95.933, 125.371],
                    [-114.285, 157.939],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [83.511, 25.377],
                    [6.695, 2.885],
                    [0, 0],
                    [-42.713, 59.134],
                    [0, 0],
                  ],
                  o: [
                    [-7.138, -2.172],
                    [0, 0],
                    [67.425, 27.259],
                    [0, 0],
                    [-47.383, 68.046],
                  ],
                  v: [
                    [-57.712, 185.873],
                    [-78.415, 178.2],
                    [-60.04, 145.607],
                    [127.957, 91.515],
                    [160.712, 109.987],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [-10.53, 34.633],
                        [-0.601, 2.168],
                        [0, 0],
                        [-1.738, 14.327],
                        [-0.099, 14.097],
                        [0, 0],
                        [15.296, 33.586],
                        [0, 0],
                        [7.875, 12.092],
                        [8.985, 10.864],
                        [0, 0],
                        [34.28, 16.393],
                        [0, 0],
                        [13.811, 4.197],
                        [13.869, 2.545],
                        [0, 0],
                        [35.352, -9.093],
                        [0, 0],
                        [13.271, -5.662],
                        [12.249, -6.959],
                        [0, 0],
                        [21.97, -30.489],
                        [0, 0],
                        [6.536, -12.873],
                        [4.914, -13.212],
                        [0, 0],
                        [0.697, -2.286],
                        [-2.557, -33.856],
                        [0, 0],
                        [-3.273, -14.063],
                        [-4.729, -13.282],
                        [0, 0],
                        [-26.091, -26.842],
                        [0, 0],
                        [-11.552, -8.671],
                        [-12.159, -7.131],
                        [0, 0],
                        [-18.364, -5.579],
                        [-18.108, -1.798],
                        [0, 0],
                        [-14.411, 0.777],
                        [-13.902, 2.349],
                        [0, 0],
                        [-30.761, 21.032],
                        [0, 0],
                        [-10.539, 9.861],
                        [-9.141, 10.73],
                      ],
                      o: [
                        [21.174, -26.837],
                        [0.658, -2.163],
                        [0, 0],
                        [3.272, -13.713],
                        [1.73, -14.328],
                        [0, 0],
                        [-1.237, -37.661],
                        [0, 0],
                        [-6.311, -12.608],
                        [-7.883, -12.092],
                        [0, 0],
                        [-24.332, -27.265],
                        [0, 0],
                        [-12.938, -5.603],
                        [-13.812, -4.198],
                        [0, 0],
                        [-37.562, -5.451],
                        [0, 0],
                        [-13.517, 4.025],
                        [-13.282, 5.658],
                        [0, 0],
                        [-31.33, 19.332],
                        [0, 0],
                        [-7.758, 11.772],
                        [-6.527, 12.873],
                        [0, 0],
                        [-0.756, 2.256],
                        [-10.449, 34.392],
                        [0, 0],
                        [1.623, 14.003],
                        [3.266, 14.057],
                        [0, 0],
                        [13.734, 34.564],
                        [0, 0],
                        [10.245, 9.683],
                        [11.54, 8.666],
                        [0, 0],
                        [16.28, 8.778],
                        [18.05, 5.486],
                        [0, 0],
                        [14.066, 0.836],
                        [14.422, -0.782],
                        [0, 0],
                        [36.708, -7.679],
                        [0, 0],
                        [11.323, -8.406],
                        [10.538, -9.86],
                        [0, 0],
                      ],
                      v: [
                        [220.964, 172.13],
                        [269.291, 79.528],
                        [271.168, 73.035],
                        [361.009, 83.917],
                        [368.567, 41.849],
                        [371.272, -0.806],
                        [281.371, -11.693],
                        [256.021, -119.563],
                        [332.255, -169.251],
                        [311.011, -206.336],
                        [285.658, -240.754],
                        [209.424, -191.065],
                        [120.997, -257.514],
                        [147.502, -344.71],
                        [107.384, -359.46],
                        [65.84, -369.532],
                        [39.335, -282.331],
                        [-70.991, -276.355],
                        [-106.815, -360.36],
                        [-147.028, -345.871],
                        [-185.318, -326.888],
                        [-149.516, -242.906],
                        [-230.47, -167.654],
                        [-311.699, -208.877],
                        [-333.188, -171.93],
                        [-350.313, -132.771],
                        [-269.252, -91.635],
                        [-271.424, -84.817],
                        [-282.856, 18.293],
                        [-371.273, 38.859],
                        [-363.979, 80.978],
                        [-351.935, 121.986],
                        [-263.619, 101.444],
                        [-203.328, 194.583],
                        [-257.662, 266.931],
                        [-225.004, 294.511],
                        [-189.419, 318.176],
                        [-135.253, 246.056],
                        [-83.24, 267.712],
                        [-28.898, 278.564],
                        [-24.03, 368.617],
                        [18.701, 368.755],
                        [61.19, 364.007],
                        [56.323, 273.98],
                        [158.561, 230.353],
                        [220.296, 296.339],
                        [253.13, 268.97],
                        [282.619, 238.034],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_16",
      nm: "Gear 64",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 2",
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-189.139, 189.14],
                        [-132.328, 132.328],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 10",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [132.327, -132.33],
                        [189.142, -189.142],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 9",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 2,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-267.482, 0],
                        [-187.138, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 8",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 3,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [187.142, 0],
                        [267.485, 0],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 7",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 4,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [189.142, 189.14],
                        [132.327, 132.328],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 6",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 5,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [-132.328, -132.33],
                        [-189.139, -189.142],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 5",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 6,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.001, 267.484],
                        [0.001, 187.14],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 4",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 7,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, 0],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [0, 0],
                      ],
                      v: [
                        [0.001, -187.142],
                        [0.001, -267.486],
                      ],
                      c: false,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 3",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 8,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -101.506],
                        [101.507, 0],
                        [0, 101.504],
                        [-101.506, 0],
                      ],
                      o: [
                        [0, 101.504],
                        [-101.506, 0],
                        [0, -101.506],
                        [101.507, 0],
                      ],
                      v: [
                        [183.79, 0],
                        [0.001, 183.791],
                        [-183.792, 0],
                        [0.001, -183.792],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 2",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 9,
              mn: "ADBE Vector Group",
              hd: false,
            },
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [0, -123.643],
                        [123.645, 0],
                        [0, 123.641],
                        [-123.643, 0],
                      ],
                      o: [
                        [0, 123.641],
                        [-123.643, 0],
                        [0, -123.643],
                        [123.645, 0],
                      ],
                      v: [
                        [223.874, 0],
                        [0.001, 223.872],
                        [-223.874, 0],
                        [0.001, -223.874],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 10,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500.501, 500.499, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0.501, 0.499, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.158, 0.158, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [140.894, 0],
                    [0, 140.892],
                    [-140.892, 0],
                    [0, -140.895],
                  ],
                  o: [
                    [-140.892, 0],
                    [0, -140.895],
                    [140.894, 0],
                    [0, 140.892],
                  ],
                  v: [
                    [0.501, 255.607],
                    [-254.608, 0.5],
                    [0.501, -254.609],
                    [255.611, 0.5],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [1.668, 5.754],
                        [0, 0],
                        [5.758, -1.67],
                        [0, 0],
                        [16.862, 16.31],
                        [0, 0],
                        [5.247, 2.886],
                        [0, 0],
                        [2.887, -5.249],
                        [0, 0],
                        [23.981, -0.393],
                        [0, 0],
                        [5.754, -1.669],
                        [0, 0],
                        [-1.667, -5.755],
                        [0, 0],
                        [16.31, -16.863],
                        [0, 0],
                        [2.891, -5.251],
                        [0, 0],
                        [-5.251, -2.887],
                        [0, 0],
                        [-0.395, -23.98],
                        [0, 0],
                        [-1.672, -5.754],
                        [0, 0],
                        [-5.753, 1.669],
                        [0, 0],
                        [-16.862, -16.309],
                        [0, 0],
                        [-5.25, -2.886],
                        [0, 0],
                        [-2.89, 5.249],
                        [0, 0],
                        [-23.979, 0.393],
                        [0, 0],
                        [-5.758, 1.672],
                        [0, 0],
                        [1.672, 5.756],
                        [0, 0],
                        [-16.31, 16.863],
                        [0, 0],
                        [-2.888, 5.251],
                        [0, 0],
                        [5.251, 2.889],
                        [0, 0],
                        [0.39, 23.981],
                        [0, 0],
                      ],
                      o: [
                        [0, 0],
                        [-1.671, -5.756],
                        [0, 0],
                        [-12.508, -20.465],
                        [0, 0],
                        [2.888, -5.251],
                        [0, 0],
                        [-5.252, -2.887],
                        [0, 0],
                        [-22.802, -5.51],
                        [0, 0],
                        [-1.672, -5.754],
                        [0, 0],
                        [-5.754, 1.672],
                        [0, 0],
                        [-20.465, 12.51],
                        [0, 0],
                        [-5.25, -2.888],
                        [0, 0],
                        [-2.891, 5.251],
                        [0, 0],
                        [-5.511, 22.803],
                        [0, 0],
                        [-5.754, 1.669],
                        [0, 0],
                        [1.671, 5.755],
                        [0, 0],
                        [12.511, 20.465],
                        [0, 0],
                        [-2.89, 5.251],
                        [0, 0],
                        [5.245, 2.887],
                        [0, 0],
                        [22.802, 5.51],
                        [0, 0],
                        [1.672, 5.753],
                        [0, 0],
                        [5.754, -1.67],
                        [0, 0],
                        [20.464, -12.507],
                        [0, 0],
                        [5.252, 2.889],
                        [0, 0],
                        [2.886, -5.251],
                        [0, 0],
                        [5.51, -22.802],
                        [0, 0],
                        [5.754, -1.67],
                      ],
                      v: [
                        [327.214, -28.881],
                        [291.827, -150.796],
                        [278.382, -158.192],
                        [240.519, -147.202],
                        [196.223, -202.509],
                        [215.229, -237.061],
                        [210.953, -251.795],
                        [99.725, -312.982],
                        [84.989, -308.705],
                        [65.982, -274.153],
                        [-4.447, -281.953],
                        [-15.437, -319.817],
                        [-28.88, -327.212],
                        [-150.797, -291.826],
                        [-158.191, -278.382],
                        [-147.198, -240.52],
                        [-202.507, -196.223],
                        [-237.058, -215.229],
                        [-251.796, -210.952],
                        [-312.979, -99.724],
                        [-308.702, -84.989],
                        [-274.149, -65.981],
                        [-281.95, 4.446],
                        [-319.815, 15.437],
                        [-327.21, 28.879],
                        [-291.824, 150.794],
                        [-278.383, 158.191],
                        [-240.517, 147.2],
                        [-196.22, 202.506],
                        [-215.227, 237.059],
                        [-210.95, 251.793],
                        [-99.72, 312.98],
                        [-84.986, 308.703],
                        [-65.979, 274.151],
                        [4.447, 281.952],
                        [15.437, 319.816],
                        [28.883, 327.209],
                        [150.796, 291.824],
                        [158.19, 278.38],
                        [147.202, 240.516],
                        [202.511, 196.221],
                        [237.06, 215.228],
                        [251.796, 210.95],
                        [312.982, 99.722],
                        [308.702, 84.985],
                        [274.152, 65.979],
                        [281.954, -4.448],
                        [319.819, -15.438],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_17",
      nm: "Gear 65",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.14, 0.14, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [92.295, -33.139],
                    [33.143, 92.293],
                    [-92.289, 33.142],
                    [-33.142, -92.292],
                  ],
                  o: [
                    [-92.291, 33.141],
                    [-33.135, -92.291],
                    [92.289, -33.138],
                    [33.143, 92.293],
                  ],
                  v: [
                    [61.24, 168.18],
                    [-165.875, 61.072],
                    [-58.764, -166.043],
                    [168.35, -58.933],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [14.562, -2.256],
                        [0, 0],
                        [29.208, 21.705],
                        [0, 0],
                        [19.549, 9.218],
                        [8.702, -11.892],
                        [0, 0],
                        [36.979, -5.383],
                        [0, 0],
                        [20.339, -7.301],
                        [0, 0],
                        [-2.26, -14.56],
                        [0, 0],
                        [21.592, -28.766],
                        [0, 0],
                        [9.223, -19.544],
                        [-11.885, -8.702],
                        [0, 0],
                        [-5.202, -36.905],
                        [0, 0],
                        [-7.303, -20.338],
                        [-14.561, 2.254],
                        [0, 0],
                        [-28.949, -21.806],
                        [0, 0],
                        [-19.549, -9.214],
                        [-8.703, 11.891],
                        [0, 0],
                        [-37.434, 5.348],
                        [0, 0],
                        [-20.343, 7.302],
                        [0, 0],
                        [2.252, 14.562],
                        [0, 0],
                        [-21.938, 29.396],
                        [0, 0],
                        [-9.216, 19.543],
                        [11.891, 8.703],
                        [0, 0],
                        [5.541, 37.511],
                        [0, 0],
                        [7.303, 20.34],
                      ],
                      o: [
                        [0, 0],
                        [-19.525, -32.208],
                        [0, 0],
                        [3.686, -17.148],
                        [-19.548, -9.218],
                        [0, 0],
                        [-35.085, -8.554],
                        [0, 0],
                        [-9.516, -14.735],
                        [0, 0],
                        [-20.334, 7.303],
                        [0, 0],
                        [-31.824, 19.287],
                        [0, 0],
                        [-17.152, -3.684],
                        [-9.215, 19.547],
                        [0, 0],
                        [-8.658, 34.978],
                        [0, 0],
                        [-14.735, 9.521],
                        [7.303, 20.341],
                        [0, 0],
                        [19.287, 32.188],
                        [0, 0],
                        [-3.686, 17.144],
                        [19.539, 9.222],
                        [0, 0],
                        [35.445, 8.904],
                        [0, 0],
                        [9.525, 14.727],
                        [0, 0],
                        [20.341, -7.303],
                        [0, 0],
                        [32.578, -19.528],
                        [0, 0],
                        [17.146, 3.689],
                        [9.221, -19.548],
                        [0, 0],
                        [8.794, -35.552],
                        [0, 0],
                        [14.726, -9.521],
                        [-7.3, -20.338],
                      ],
                      v: [
                        [337.016, -162.223],
                        [246.545, -148.799],
                        [172.484, -230.087],
                        [194.672, -319.498],
                        [175.895, -371.794],
                        [123.597, -353.015],
                        [68.612, -278.87],
                        [-40.395, -284.105],
                        [-88.266, -363.568],
                        [-138.521, -387.273],
                        [-138.521, -387.273],
                        [-162.217, -337.015],
                        [-148.607, -245.252],
                        [-229.109, -172.236],
                        [-319.492, -194.67],
                        [-371.793, -175.891],
                        [-353.017, -123.594],
                        [-278.473, -68.315],
                        [-284.114, 40.412],
                        [-363.567, 88.266],
                        [-387.271, 138.524],
                        [-337.012, 162.228],
                        [-245.783, 148.691],
                        [-172.479, 230.095],
                        [-194.668, 319.503],
                        [-175.885, 371.794],
                        [-123.592, 353.02],
                        [-69.115, 279.555],
                        [41.156, 285.357],
                        [88.267, 363.575],
                        [138.526, 387.272],
                        [138.526, 387.272],
                        [162.229, 337.014],
                        [148.881, 247.081],
                        [231.083, 172.727],
                        [319.503, 194.669],
                        [371.797, 175.894],
                        [353.021, 123.595],
                        [279.955, 69.411],
                        [285.316, -41.131],
                        [363.577, -88.267],
                        [387.271, -138.523],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_18",
      nm: "Gear 65",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 2",
          parent: 2,
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [0, 0, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [54.702, 0],
                        [6.946, -1.287],
                        [-11.5, -61.688],
                        [-54.709, 0],
                        [0, 0],
                        [-6.939, 1.294],
                        [11.498, 61.694],
                      ],
                      o: [
                        [-6.992, 0],
                        [-61.681, 11.506],
                        [10.051, 53.865],
                        [0, 0],
                        [6.993, 0],
                        [61.688, -11.507],
                        [-10.051, -53.86],
                      ],
                      v: [
                        [1.486, -118.619],
                        [-19.517, -116.669],
                        [-110.522, 16.077],
                        [1.222, 109.041],
                        [1.222, 109.041],
                        [22.218, 107.09],
                        [113.231, -25.656],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "st",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 3,
                  },
                  o: { a: 0, k: 100, ix: 4 },
                  w: { a: 0, k: 20, ix: 5 },
                  lc: 1,
                  lj: 1,
                  ml: 4,
                  bm: 0,
                  nm: "Stroke 1",
                  mn: "ADBE Vector Graphic - Stroke",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
        {
          ddd: 0,
          ind: 2,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.14, 0.14, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [150.619, -28.092],
                    [28.084, 150.613],
                    [-150.619, 28.084],
                    [-28.085, -150.611],
                  ],
                  o: [
                    [-150.613, 28.085],
                    [-28.092, -150.618],
                    [150.613, -28.091],
                    [28.092, 150.62],
                  ],
                  v: [
                    [52.71, 268.427],
                    [-270.859, 46.569],
                    [-49.003, -277],
                    [274.567, -55.15],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [9.729, 40.204],
                        [0.662, 2.486],
                        [0, 0],
                        [6.607, 15.117],
                        [8.083, 13.93],
                        [0, 0],
                        [34.549, 24.186],
                        [0, 0],
                        [14.775, 7.329],
                        [15.146, 5.482],
                        [0, 0],
                        [43.258, -3.754],
                        [0, 0],
                        [16.032, -3.88],
                        [15.129, -5.537],
                        [0, 0],
                        [29.523, -29.469],
                        [0, 0],
                        [9.784, -13.275],
                        [8.028, -13.968],
                        [0, 0],
                        [3.936, -42.763],
                        [0, 0],
                        [-1.042, -16.459],
                        [-2.83, -15.856],
                        [0, 0],
                        [-0.646, -2.653],
                        [-22.163, -31.845],
                        [0, 0],
                        [-11.38, -11.94],
                        [-12.36, -10.331],
                        [0, 0],
                        [-41.258, -11.289],
                        [0, 0],
                        [-16.393, -1.837],
                        [-16.108, 0.035],
                        [0, 0],
                        [-21.309, 5.157],
                        [-18.866, 8.732],
                        [0, 0],
                        [-13.733, 9.13],
                        [-12.318, 10.38],
                        [0, 0],
                        [-18.078, 38.556],
                        [0, 0],
                        [-4.652, 15.819],
                        [-2.761, 15.868],
                        [0, 0],
                      ],
                      o: [
                        [-0.609, -2.503],
                        [0, 0],
                        [-4.74, -15.393],
                        [-6.607, -15.11],
                        [0, 0],
                        [-23.073, -36.354],
                        [0, 0],
                        [-13.528, -8.743],
                        [-14.773, -7.329],
                        [0, 0],
                        [-39.771, -12.722],
                        [0, 0],
                        [-15.982, 1.998],
                        [-16.025, 3.882],
                        [0, 0],
                        [-40.144, 16.435],
                        [0, 0],
                        [-10.965, 11.8],
                        [-9.785, 13.275],
                        [0, 0],
                        [-19.622, 37.207],
                        [0, 0],
                        [-0.813, 16.093],
                        [1.042, 16.457],
                        [0, 0],
                        [0.576, 2.654],
                        [9.663, 39.916],
                        [0, 0],
                        [9.718, 12.847],
                        [11.373, 11.939],
                        [0, 0],
                        [33.586, 26.057],
                        [0, 0],
                        [15.709, 3.594],
                        [16.393, 1.835],
                        [0, 0],
                        [21.115, -0.8],
                        [20.953, -5.073],
                        [0, 0],
                        [14.34, -7.342],
                        [13.732, -9.129],
                        [0, 0],
                        [31.684, -28.861],
                        [0, 0],
                        [6.263, -14.841],
                        [4.65, -15.827],
                        [0, 0],
                        [5.272, -38.705],
                      ],
                      v: [
                        [317.499, -77.09],
                        [315.585, -84.571],
                        [410.333, -125.985],
                        [393.364, -171.787],
                        [371.271, -215.342],
                        [276.463, -173.899],
                        [188.91, -265.374],
                        [235.126, -358.53],
                        [192.688, -382.703],
                        [147.767, -401.875],
                        [101.55, -308.72],
                        [-24.054, -322.827],
                        [-48.563, -424.045],
                        [-96.616, -415.284],
                        [-143.351, -401.093],
                        [-118.839, -299.874],
                        [-223.988, -229.966],
                        [-307.996, -291.876],
                        [-339.174, -254.284],
                        [-365.851, -213.369],
                        [-281.874, -151.482],
                        [-317.9, -30.429],
                        [-421.779, -23.875],
                        [-421.497, 24.965],
                        [-415.635, 73.456],
                        [-311.973, 66.915],
                        [-310.149, 74.882],
                        [-261.568, 183.021],
                        [-336.67, 254.568],
                        [-305.06, 291.799],
                        [-269.403, 325.179],
                        [-194.393, 253.713],
                        [-80.993, 310.418],
                        [-92.501, 413.159],
                        [-44.351, 421.363],
                        [4.417, 424.01],
                        [15.888, 321.582],
                        [79.657, 312.723],
                        [139.45, 291.877],
                        [196.493, 377.697],
                        [238.647, 353.03],
                        [277.708, 323.712],
                        [220.679, 237.911],
                        [296.009, 135.638],
                        [395.08, 164.77],
                        [411.515, 118.783],
                        [422.592, 71.212],
                        [323.659, 42.116],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_19",
      nm: "Gear 66",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: { a: 0, k: [100, 100, 100], ix: 6, l: 2 },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-20.645, 52.029],
                    [0, 0],
                    [2.354, -17.943],
                    [0, 0],
                    [-0.1, -10.14],
                    [0, 0],
                    [-7.151, -22.065],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-6.684, 17.013],
                    [0, 0],
                    [-1.402, 10.045],
                    [0, 0],
                    [0.111, 22.9],
                    [0, 0],
                    [-17.474, -53.306],
                  ],
                  v: [
                    [-222.188, -88.066],
                    [-207.301, -81.36],
                    [-220.884, -28.718],
                    [-200.781, -27.803],
                    [-202.732, 2.521],
                    [-222.813, 1.608],
                    [-211.893, 69.481],
                    [-227.193, 75.276],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-2.383, 2.177],
                    [-5.55, 4.042],
                    [0, 0],
                    [-7.238, 3.364],
                    [0, 0],
                    [9.674, -8.837],
                    [6.648, -11.979],
                    [0, 0],
                    [-9.411, 9.773],
                    [0, 0],
                  ],
                  o: [
                    [5.188, -4.731],
                    [0, 0],
                    [6.748, -4.612],
                    [0, 0],
                    [-11.246, 5.369],
                    [-10.68, 9.747],
                    [0, 0],
                    [6.193, -11.453],
                    [0, 0],
                    [2.249, -2.267],
                  ],
                  v: [
                    [-125.922, -137.964],
                    [-109.792, -151.093],
                    [-82.691, -121.41],
                    [-61.666, -133.333],
                    [-55.273, -116.459],
                    [-86.855, -95.179],
                    [-112.813, -62.317],
                    [-129.275, -69.737],
                    [-105.896, -101.763],
                    [-132.862, -131.298],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [-10.037, -1.483],
                    [0, 0],
                    [-8.127, -2.962],
                    [0, 0],
                    [25.922, -7.41],
                    [0, 0],
                    [-12.66, 0.383],
                  ],
                  o: [
                    [10.143, -0.144],
                    [0, 0],
                    [8.453, 1.493],
                    [0, 0],
                    [-25.371, -8.962],
                    [0, 0],
                    [12.262, -3.642],
                    [0, 0],
                  ],
                  v: [
                    [-2.246, -186.85],
                    [28.074, -184.852],
                    [26.258, -144.81],
                    [51.168, -138.074],
                    [43.76, -121.632],
                    [-35.207, -124.042],
                    [-41.604, -140.935],
                    [-4.057, -146.992],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-4.124, -8.897],
                    [0, 0],
                    [8.839, 9.678],
                    [11.981, 6.646],
                    [0, 0],
                    [-8.663, -7.735],
                    [0, 0],
                    [-3.452, -3.78],
                    [-3.085, -3.978],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-5.369, -11.246],
                    [-9.745, -10.676],
                    [0, 0],
                    [9.879, 5.34],
                    [0, 0],
                    [3.68, 3.429],
                    [3.452, 3.78],
                    [0, 0],
                    [6.017, 8.12],
                  ],
                  v: [
                    [134.329, -61.663],
                    [117.457, -55.272],
                    [96.175, -86.857],
                    [63.313, -112.811],
                    [70.75, -129.312],
                    [98.687, -109.72],
                    [128.225, -136.687],
                    [138.962, -125.92],
                    [148.712, -114.251],
                    [119.175, -87.28],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [5.188, -4.732],
                    [2.465, -2.029],
                    [0, 0],
                    [10.527, -4.891],
                    [0, 0],
                    [-9.68, 8.836],
                    [-6.643, 11.978],
                    [0, 0],
                    [6.157, -7.448],
                    [0, 0],
                  ],
                  o: [
                    [-2.379, 2.176],
                    [0, 0],
                    [-9.406, 7.536],
                    [0, 0],
                    [11.24, -5.369],
                    [10.674, -9.745],
                    [0, 0],
                    [-4.476, 8.278],
                    [0, 0],
                    [-4.527, 5.158],
                  ],
                  v: [
                    [126.916, 138.961],
                    [119.648, 145.267],
                    [92.688, 115.732],
                    [62.665, 134.343],
                    [56.273, 117.456],
                    [87.855, 96.177],
                    [113.807, 63.315],
                    [130.264, 70.728],
                    [114.355, 94.406],
                    [141.456, 124.093],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [0, 0],
                    [10.083, 1.063],
                    [0, 0],
                    [10.633, 3.871],
                    [0, 0],
                    [-25.921, 7.411],
                    [0, 0],
                    [10.002, -0.842],
                  ],
                  o: [
                    [-10.137, 0.566],
                    [0, 0],
                    [-11.135, -1.357],
                    [0, 0],
                    [25.365, 8.959],
                    [0, 0],
                    [-9.709, 2.883],
                    [0, 0],
                  ],
                  v: [
                    [11.145, 187.587],
                    [-19.234, 186.824],
                    [-17.424, 146.97],
                    [-50.162, 139.048],
                    [-42.76, 122.632],
                    [36.206, 125.039],
                    [42.592, 141.902],
                    [12.961, 147.546],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [4.125, 8.896],
                    [0, 0],
                    [-8.832, -9.678],
                    [-11.981, -6.643],
                    [0, 0],
                    [8.664, 7.737],
                    [0, 0],
                    [3.453, 3.78],
                    [3.085, 3.976],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [5.368, 11.246],
                    [9.751, 10.676],
                    [0, 0],
                    [-9.885, -5.337],
                    [0, 0],
                    [-3.687, -3.432],
                    [-3.452, -3.779],
                    [0, 0],
                    [-6.022, -8.12],
                  ],
                  v: [
                    [-133.33, 62.661],
                    [-116.457, 56.272],
                    [-95.182, 87.854],
                    [-62.314, 113.808],
                    [-69.744, 130.297],
                    [-97.688, 110.718],
                    [-127.225, 137.686],
                    [-137.963, 126.917],
                    [-147.713, 115.251],
                    [-118.176, 88.281],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-1.483, 10.04],
                    [0, 0],
                    [-2.968, 8.126],
                    [0, 0],
                    [-7.413, -25.918],
                    [0, 0],
                    [0.386, 12.656],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [1.489, -8.457],
                    [0, 0],
                    [-8.962, 25.369],
                    [0, 0],
                    [-3.64, -12.265],
                    [0, 0],
                    [-0.146, -10.142],
                  ],
                  v: [
                    [-184.851, -27.079],
                    [-144.809, -25.259],
                    [-138.062, -50.166],
                    [-121.634, -42.762],
                    [-124.041, 36.205],
                    [-140.93, 42.599],
                    [-146.994, 5.056],
                    [-186.848, 3.241],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 8",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-25.985, -28.469],
                    [28.469, -25.991],
                    [25.992, 28.465],
                    [-28.462, 25.991],
                  ],
                  o: [
                    [25.991, 28.465],
                    [-28.462, 25.988],
                    [-25.99, -28.466],
                    [28.468, -25.991],
                  ],
                  v: [
                    [52.038, -46.559],
                    [47.557, 52.043],
                    [-51.045, 47.56],
                    [-46.563, -51.043],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 9",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [3.873, -10.627],
                    [0, 0],
                    [7.407, 25.918],
                    [0, 0],
                    [-0.84, -9.996],
                    [0, 0],
                    [1.063, -10.09],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [8.956, -25.369],
                    [0, 0],
                    [2.88, 9.709],
                    [0, 0],
                    [0.567, 10.133],
                    [0, 0],
                    [-1.361, 11.135],
                  ],
                  v: [
                    [139.055, 51.161],
                    [122.633, 43.759],
                    [125.04, -35.205],
                    [141.906, -41.593],
                    [147.543, -11.968],
                    [187.585, -10.146],
                    [186.826, 20.233],
                    [146.971, 18.419],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 10",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [20.195, 22.118],
                    [10.282, 23.862],
                    [0, 0],
                    [-12.42, -15.58],
                    [0, 0],
                    [-3.44, -3.774],
                    [-3.657, -3.453],
                    [0, 0],
                    [-18.969, -9.666],
                    [0, 0],
                  ],
                  o: [
                    [-18.502, -20.266],
                    [0, 0],
                    [7.636, 17.555],
                    [0, 0],
                    [3.107, 3.955],
                    [3.447, 3.771],
                    [0, 0],
                    [16.048, 15.37],
                    [0, 0],
                    [-25.231, -12.75],
                  ],
                  v: [
                    [-176.449, 162.054],
                    [-219.593, 95.338],
                    [-204.316, 89.552],
                    [-174.271, 139.501],
                    [-159.496, 126.009],
                    [-149.676, 137.611],
                    [-139.014, 148.445],
                    [-153.788, 161.938],
                    [-100.918, 199.487],
                    [-107.625, 214.372],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 11",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [52.029, 20.648],
                    [0, 0],
                    [-20.627, -1.975],
                    [0, 0],
                    [-10.137, 0.488],
                    [0, 0],
                    [-19.523, 6.33],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [19.484, 7.661],
                    [0, 0],
                    [10.09, 1.017],
                    [0, 0],
                    [20.267, -0.809],
                    [0, 0],
                    [-53.309, 17.471],
                  ],
                  v: [
                    [-88.065, 223.183],
                    [-81.354, 208.285],
                    [-20.871, 222.785],
                    [-19.959, 202.712],
                    [10.42, 203.515],
                    [9.502, 223.623],
                    [69.482, 212.9],
                    [75.277, 228.194],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 12",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [23.858, -10.284],
                    [0, 0],
                    [-16.778, 14.062],
                    [0, 0],
                    [-2.384, 2.176],
                    [-4.574, 5.114],
                    [0, 0],
                    [-8.856, 17.375],
                    [0, 0],
                    [22.119, -20.193],
                  ],
                  o: [
                    [0, 0],
                    [19.19, -8.346],
                    [0, 0],
                    [2.454, -2.039],
                    [5.176, -4.727],
                    [0, 0],
                    [13.641, -14.973],
                    [0, 0],
                    [-12.748, 25.234],
                    [-20.271, 18.505],
                  ],
                  v: [
                    [95.34, 220.591],
                    [89.55, 205.314],
                    [143.822, 171.74],
                    [130.351, 156.983],
                    [137.612, 150.671],
                    [152.234, 135.896],
                    [165.754, 150.7],
                    [199.468, 101.907],
                    [214.371, 108.623],
                    [162.055, 177.445],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 13",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [20.651, -52.029],
                    [0, 0],
                    [-1.98, 20.628],
                    [0, 0],
                    [0.485, 10.133],
                    [0, 0],
                    [6.332, 19.527],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [7.664, -19.486],
                    [0, 0],
                    [1.017, -10.089],
                    [0, 0],
                    [-0.813, -20.266],
                    [0, 0],
                    [17.473, 53.309],
                  ],
                  v: [
                    [223.181, 89.064],
                    [208.301, 82.357],
                    [222.789, 21.866],
                    [202.71, 20.954],
                    [203.516, -9.421],
                    [223.625, -8.507],
                    [212.922, -68.496],
                    [228.193, -74.279],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 14",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-20.19, -22.118],
                    [-10.287, -23.861],
                    [0, 0],
                    [12.414, 15.578],
                    [0, 0],
                    [3.446, 3.773],
                    [3.656, 3.453],
                    [0, 0],
                    [18.969, 9.666],
                    [0, 0],
                  ],
                  o: [
                    [18.508, 20.268],
                    [0, 0],
                    [-7.636, -17.558],
                    [0, 0],
                    [-3.108, -3.955],
                    [-3.44, -3.774],
                    [0, 0],
                    [-16.053, -15.367],
                    [0, 0],
                    [25.231, 12.75],
                  ],
                  v: [
                    [177.443, -161.057],
                    [220.592, -94.341],
                    [205.292, -88.546],
                    [175.27, -138.501],
                    [160.496, -125.012],
                    [150.669, -136.61],
                    [140.008, -147.448],
                    [154.787, -160.94],
                    [101.912, -198.476],
                    [108.624, -213.372],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 15",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-52.029, -20.648],
                    [0, 0],
                    [17.94, 2.359],
                    [0, 0],
                    [10.136, -0.093],
                    [0, 0],
                    [22.064, -7.153],
                    [0, 0],
                  ],
                  o: [
                    [0, 0],
                    [-17.013, -6.685],
                    [0, 0],
                    [-10.043, -1.401],
                    [0, 0],
                    [-22.906, 0.105],
                    [0, 0],
                    [53.309, -17.474],
                  ],
                  v: [
                    [89.064, -222.183],
                    [82.347, -207.276],
                    [29.716, -220.888],
                    [28.799, -200.78],
                    [-1.521, -202.737],
                    [-0.61, -222.81],
                    [-68.488, -211.912],
                    [-74.278, -227.194],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 16",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-23.859, 10.284],
                    [0, 0],
                    [14.325, -10.846],
                    [0, 0],
                    [5.177, -4.724],
                    [2.255, -2.261],
                    [0, 0],
                    [10.47, -20.555],
                    [0, 0],
                    [-22.118, 20.196],
                  ],
                  o: [
                    [0, 0],
                    [-15.913, 6.923],
                    [0, 0],
                    [-5.51, 4.093],
                    [-2.383, 2.177],
                    [0, 0],
                    [-17.163, 17.062],
                    [0, 0],
                    [12.753, -25.231],
                    [20.267, -18.504],
                  ],
                  v: [
                    [-94.34, -219.594],
                    [-88.551, -204.315],
                    [-134.089, -177.701],
                    [-120.57, -162.898],
                    [-136.613, -149.674],
                    [-143.559, -143.014],
                    [-157.031, -157.771],
                    [-198.463, -100.907],
                    [-213.377, -107.626],
                    [-161.056, -176.448],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 17",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [3.33, -3.301],
                        [-7.186, -5.906],
                        [0, 0],
                        [6.129, -10.721],
                        [11.777, 9.681],
                        [0, 0],
                        [4.977, -8.184],
                        [2.278, -4.107],
                        [-8.6, -3.66],
                        [0, 0],
                        [2.868, -12.014],
                        [14.021, 5.973],
                        [0, 0],
                        [2.447, -9.272],
                        [1.017, -4.586],
                        [-9.266, -1.09],
                        [0, 0],
                        [1.612, -13.723],
                        [13.156, 1.545],
                        [0, 0],
                        [-0.245, -9.578],
                        [-0.304, -4.685],
                        [-9.213, 1.569],
                        [0, 0],
                        [-2.32, -13.623],
                        [13.623, -2.319],
                        [0, 0],
                        [-2.932, -9.12],
                        [-1.617, -4.426],
                        [-8.394, 4.101],
                        [0, 0],
                        [-6.069, -12.417],
                        [0, 0],
                        [12.42, -6.065],
                        [0, 0],
                        [-5.393, -7.922],
                        [-2.809, -3.789],
                        [-6.899, 6.298],
                        [0, 0],
                        [-9.318, -10.206],
                        [0, 0],
                        [10.205, -9.318],
                        [0, 0],
                        [-7.385, -6.094],
                        [-3.733, -2.834],
                        [-4.844, 7.995],
                        [0, 0],
                        [-11.486, -4.545],
                        [7.904, -13.034],
                        [0, 0],
                        [-8.82, -3.748],
                        [-4.399, -1.662],
                        [-2.396, 9.023],
                        [0, 0],
                        [-13.355, -3.549],
                        [0, 0],
                        [3.546, -13.357],
                        [0, 0],
                        [-9.517, -1.115],
                        [-4.68, -0.359],
                        [0.239, 9.336],
                        [0, 0],
                        [-13.816, 0.359],
                        [-0.356, -13.816],
                        [0, 0],
                        [-9.441, 1.598],
                        [-4.604, 0.973],
                        [2.868, 8.898],
                        [0, 0],
                        [-13.156, 4.235],
                        [0, 0],
                        [-4.235, -13.153],
                        [0, 0],
                        [-8.611, 4.207],
                        [-4.153, 2.243],
                        [5.246, 7.724],
                        [0, 0],
                        [-11.427, 7.772],
                        [-7.77, -11.43],
                        [0, 0],
                        [-7.046, 6.426],
                        [-0.041, 0.041],
                        [-3.33, 3.301],
                        [7.186, 5.906],
                        [0, 0],
                        [-8.775, 10.676],
                        [-10.68, -8.774],
                        [0, 0],
                        [-4.977, 8.182],
                        [-2.271, 4.109],
                        [8.594, 3.657],
                        [0, 0],
                        [-5.416, 12.715],
                        [-12.719, -5.413],
                        [0, 0],
                        [-2.454, 9.269],
                        [-1.018, 4.586],
                        [9.271, 1.09],
                        [0, 0],
                        [-1.607, 13.726],
                        [-13.156, -1.542],
                        [0, 0],
                        [0.246, 9.575],
                        [0.311, 4.688],
                        [9.213, -1.568],
                        [0, 0],
                        [4.084, 11.655],
                        [-15.026, 2.56],
                        [0, 0],
                        [2.927, 9.12],
                        [1.624, 4.426],
                        [8.396, -4.102],
                        [0, 0],
                        [7.244, 9.8],
                        [-13.805, 6.741],
                        [0, 0],
                        [5.398, 7.922],
                        [2.804, 3.789],
                        [6.893, -6.297],
                        [0, 0],
                        [9.318, 10.206],
                        [-10.206, 9.318],
                        [0, 0],
                        [7.385, 6.091],
                        [3.732, 2.833],
                        [4.849, -7.991],
                        [0, 0],
                        [11.479, 4.542],
                        [-7.904, 13.034],
                        [0, 0],
                        [8.821, 3.75],
                        [4.395, 1.662],
                        [2.395, -9.02],
                        [0, 0],
                        [12.298, 1.125],
                        [-3.914, 14.733],
                        [0, 0],
                        [9.522, 1.113],
                        [4.686, 0.356],
                        [-0.246, -9.335],
                        [0, 0],
                        [12.117, -2.386],
                        [0.396, 15.239],
                        [0, 0],
                        [9.441, -1.598],
                        [4.604, -0.97],
                        [-2.863, -8.897],
                        [0, 0],
                        [13.15, -4.238],
                        [3.961, 12.304],
                        [0, 0],
                        [8.611, -4.206],
                        [4.154, -2.24],
                        [-5.252, -7.723],
                        [0, 0],
                        [14.5, -6.625],
                        [6.947, 10.215],
                        [0, 0],
                        [7.046, -6.426],
                        [0.047, -0.041],
                      ],
                      o: [
                        [-6.607, 6.549],
                        [0, 0],
                        [9.541, 7.843],
                        [-7.916, 13.833],
                        [0, 0],
                        [-7.402, -6.082],
                        [-2.454, 4.031],
                        [-4.528, 8.173],
                        [0, 0],
                        [11.362, 4.837],
                        [-3.692, 15.505],
                        [0, 0],
                        [-8.827, -3.754],
                        [-1.203, 4.545],
                        [-2.027, 9.111],
                        [0, 0],
                        [13.724, 1.612],
                        [8.933, 9.786],
                        [0, 0],
                        [-9.517, -1.116],
                        [0.116, 4.691],
                        [0.614, 9.326],
                        [0, 0],
                        [13.623, -2.322],
                        [2.318, 13.621],
                        [0, 0],
                        [-9.441, 1.609],
                        [1.438, 4.472],
                        [3.22, 8.771],
                        [0, 0],
                        [12.421, -6.064],
                        [0, 0],
                        [6.069, 12.417],
                        [0, 0],
                        [-8.611, 4.206],
                        [2.635, 3.87],
                        [5.563, 7.504],
                        [0, 0],
                        [10.206, -9.317],
                        [0, 0],
                        [9.317, 10.206],
                        [0, 0],
                        [-7.069, 6.456],
                        [3.639, 3],
                        [7.448, 5.654],
                        [0, 0],
                        [6.408, -10.56],
                        [14.82, 5.863],
                        [0, 0],
                        [-4.972, 8.196],
                        [4.342, 1.843],
                        [8.733, 3.301],
                        [0, 0],
                        [3.546, -13.354],
                        [0, 0],
                        [13.354, 3.549],
                        [0, 0],
                        [-2.459, 9.263],
                        [4.668, 0.543],
                        [9.312, 0.713],
                        [0, 0],
                        [-0.355, -13.813],
                        [13.816, -0.359],
                        [0, 0],
                        [0.251, 9.572],
                        [4.632, -0.786],
                        [9.144, -1.931],
                        [0, 0],
                        [-4.236, -13.153],
                        [0, 0],
                        [13.156, -4.238],
                        [0, 0],
                        [2.932, 9.122],
                        [4.218, -2.062],
                        [8.214, -4.436],
                        [0, 0],
                        [-7.77, -11.428],
                        [11.428, -7.771],
                        [0, 0],
                        [5.363, 7.885],
                        [0.047, -0.038],
                        [3.5, -3.189],
                        [6.602, -6.547],
                        [0, 0],
                        [-10.673, -8.777],
                        [8.773, -10.677],
                        [0, 0],
                        [7.396, 6.082],
                        [2.453, -4.031],
                        [4.528, -8.174],
                        [0, 0],
                        [-12.719, -5.412],
                        [5.416, -12.719],
                        [0, 0],
                        [8.822, 3.756],
                        [1.203, -4.548],
                        [2.021, -9.11],
                        [0, 0],
                        [-13.729, -1.609],
                        [-8.934, -9.786],
                        [0, 0],
                        [9.517, 1.116],
                        [-0.122, -4.694],
                        [-0.613, -9.324],
                        [0, 0],
                        [-12.17, 2.074],
                        [-5.275, -15.04],
                        [0, 0],
                        [9.441, -1.607],
                        [-1.437, -4.472],
                        [-3.214, -8.774],
                        [0, 0],
                        [-10.953, 5.352],
                        [-9.593, -12.97],
                        [0, 0],
                        [8.611, -4.203],
                        [-2.635, -3.871],
                        [-5.567, -7.501],
                        [0, 0],
                        [-10.205, 9.318],
                        [-9.317, -10.206],
                        [0, 0],
                        [7.069, -6.456],
                        [-3.64, -3.003],
                        [-7.443, -5.652],
                        [0, 0],
                        [-6.402, 10.559],
                        [-14.821, -5.863],
                        [0, 0],
                        [4.973, -8.196],
                        [-4.335, -1.843],
                        [-8.733, -3.298],
                        [0, 0],
                        [-3.166, 11.935],
                        [-15.872, -1.451],
                        [0, 0],
                        [2.466, -9.266],
                        [-4.662, -0.546],
                        [-9.312, -0.713],
                        [0, 0],
                        [0.321, 12.344],
                        [-15.639, 3.079],
                        [0, 0],
                        [-0.245, -9.572],
                        [-4.627, 0.783],
                        [-9.148, 1.931],
                        [0, 0],
                        [4.235, 13.156],
                        [-9.547, 8.714],
                        [0, 0],
                        [-2.939, -9.119],
                        [-4.217, 2.059],
                        [-8.219, 4.437],
                        [0, 0],
                        [8.57, 12.605],
                        [-11.233, 5.135],
                        [0, 0],
                        [-5.362, -7.884],
                        [-0.041, 0.041],
                        [-3.493, 3.19],
                      ],
                      v: [
                        [-246.16, -248.655],
                        [-245.016, -225.352],
                        [-238.998, -220.406],
                        [-232.514, -188.17],
                        [-270.352, -181.128],
                        [-275.721, -185.541],
                        [-299.188, -181.575],
                        [-306.28, -169.364],
                        [-298.656, -147.229],
                        [-291.412, -144.145],
                        [-276.112, -115.043],
                        [-310.428, -97.624],
                        [-316.784, -100.329],
                        [-338.189, -89.884],
                        [-341.52, -76.185],
                        [-327.972, -57.116],
                        [-319.501, -56.123],
                        [-297.477, -28.239],
                        [-309.709, -4.374],
                        [-332.172, -7.012],
                        [-349.768, 9.016],
                        [-349.132, 23.087],
                        [-330.752, 37.592],
                        [-322.369, 36.164],
                        [-293.381, 56.716],
                        [-313.928, 85.704],
                        [-320.768, 86.87],
                        [-333.137, 107.197],
                        [-328.551, 120.546],
                        [-306.83, 129.286],
                        [-299.124, 125.521],
                        [-265.515, 137.07],
                        [-265.521, 137.07],
                        [-277.07, 170.677],
                        [-283.269, 173.706],
                        [-289.402, 196.706],
                        [-281.236, 208.197],
                        [-257.938, 210.458],
                        [-251.646, 204.709],
                        [-216.144, 206.324],
                        [-216.144, 206.324],
                        [-217.762, 241.821],
                        [-222.851, 246.468],
                        [-222.271, 270.257],
                        [-211.213, 279.009],
                        [-188.213, 274.621],
                        [-184.152, 267.929],
                        [-153.166, 256.925],
                        [-140.811, 293.377],
                        [-144.415, 299.321],
                        [-137.131, 321.991],
                        [-124.026, 327.249],
                        [-103.223, 316.546],
                        [-101.021, 308.259],
                        [-70.285, 290.427],
                        [-70.285, 290.427],
                        [-52.45, 321.164],
                        [-54.215, 327.798],
                        [-40.842, 347.497],
                        [-26.82, 348.853],
                        [-9.861, 332.726],
                        [-10.084, 324.234],
                        [14.383, 298.462],
                        [40.152, 322.932],
                        [40.333, 329.822],
                        [58.689, 344.962],
                        [72.546, 342.327],
                        [84.289, 322.069],
                        [81.672, 313.94],
                        [97.889, 282.32],
                        [97.889, 282.32],
                        [129.506, 298.535],
                        [131.639, 305.14],
                        [153.528, 314.489],
                        [166.083, 308.034],
                        [171.633, 285.297],
                        [166.813, 278.214],
                        [173.461, 243.305],
                        [208.367, 249.956],
                        [212.24, 255.651],
                        [235.784, 258.518],
                        [235.918, 258.395],
                        [246.16, 248.653],
                        [245.009, 225.349],
                        [238.437, 219.948],
                        [234.984, 184.581],
                        [270.352, 181.125],
                        [275.721, 185.538],
                        [299.182, 181.575],
                        [306.273, 169.362],
                        [298.656, 147.23],
                        [290.746, 143.861],
                        [277.467, 110.901],
                        [310.428, 97.622],
                        [316.783, 100.327],
                        [338.189, 89.884],
                        [341.52, 76.182],
                        [327.965, 57.113],
                        [319.5, 56.12],
                        [297.471, 28.236],
                        [309.709, 4.371],
                        [332.172, 7.009],
                        [349.768, -9.016],
                        [349.125, -23.089],
                        [330.746, -37.595],
                        [323.076, -36.286],
                        [294.473, -52.498],
                        [313.928, -85.707],
                        [320.762, -86.872],
                        [333.136, -107.2],
                        [328.544, -120.546],
                        [306.823, -129.285],
                        [299.672, -125.792],
                        [267.974, -133.033],
                        [277.064, -170.679],
                        [283.262, -173.708],
                        [289.396, -196.708],
                        [281.235, -208.2],
                        [257.938, -210.458],
                        [251.639, -204.712],
                        [216.143, -206.324],
                        [217.756, -241.823],
                        [222.844, -246.471],
                        [222.266, -270.257],
                        [211.207, -279.011],
                        [188.207, -274.624],
                        [184.146, -267.932],
                        [153.166, -256.925],
                        [140.805, -293.377],
                        [144.408, -299.321],
                        [137.124, -321.994],
                        [124.025, -327.252],
                        [103.223, -316.549],
                        [101.201, -308.957],
                        [74.573, -289.67],
                        [52.449, -321.164],
                        [54.208, -327.798],
                        [40.836, -347.497],
                        [26.814, -348.852],
                        [9.861, -332.726],
                        [10.06, -324.956],
                        [-10.055, -298.947],
                        [-40.158, -322.932],
                        [-40.34, -329.825],
                        [-58.695, -344.962],
                        [-72.547, -342.33],
                        [-84.295, -322.072],
                        [-81.678, -313.943],
                        [-97.889, -282.32],
                        [-126.761, -289.991],
                        [-131.639, -305.143],
                        [-153.529, -314.489],
                        [-166.084, -308.037],
                        [-171.633, -285.3],
                        [-166.813, -278.214],
                        [-177.266, -241.172],
                        [-208.777, -250.555],
                        [-212.247, -255.654],
                        [-235.791, -258.521],
                        [-235.925, -258.398],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
    {
      id: "comp_20",
      nm: "Gear 67",
      layers: [
        {
          ddd: 0,
          ind: 1,
          ty: 4,
          nm: "Shape Layer 1",
          sr: 1,
          ks: {
            o: { a: 0, k: 100, ix: 11 },
            r: { a: 0, k: 0, ix: 10 },
            p: { a: 0, k: [500, 500, 0], ix: 2, l: 2 },
            a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
            s: {
              a: 1,
              k: [
                {
                  i: { x: [0.14, 0.14, 0.667], y: [1, 1, 1] },
                  o: {
                    x: [0.167, 0.167, 0.167],
                    y: [0.167, 0.167, 16.667],
                  },
                  t: 0,
                  s: [0, 0, 100],
                },
                { t: 30.0000012219251, s: [100, 100, 100] },
              ],
              ix: 6,
              l: 2,
            },
          },
          ao: 0,
          hasMask: true,
          masksProperties: [
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-16.457, -8.713],
                    [19.351, -36.56],
                    [19.785, 10.472],
                    [-18.205, 34.388],
                  ],
                  o: [
                    [19.783, 10.47],
                    [-19.352, 36.556],
                    [-16.455, -8.711],
                    [18.203, -34.388],
                  ],
                  v: [
                    [212.236, 24.37],
                    [206.143, 109.468],
                    [139.194, 162.358],
                    [139.907, 74.407],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 1",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-38.881, 1.43],
                    [-0.683, -18.606],
                    [41.335, -1.525],
                    [0.826, 22.371],
                  ],
                  o: [
                    [38.881, -1.429],
                    [0.825, 22.364],
                    [-41.334, 1.517],
                    [-0.685, -18.604],
                  ],
                  v: [
                    [6.376, 158.196],
                    [85.872, 195.815],
                    [9.132, 233.088],
                    [-70.148, 201.552],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 2",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [21.985, 35.035],
                    [-18.957, 11.895],
                    [-20.68, -32.956],
                    [15.773, -9.897],
                  ],
                  o: [
                    [-21.986, -35.038],
                    [15.771, -9.896],
                    [20.678, 32.956],
                    [-18.957, 11.895],
                  ],
                  v: [
                    [-196.431, 124.279],
                    [-208.764, 39.858],
                    [-132.952, 84.448],
                    [-125.783, 172.106],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 3",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-21.987, -35.035],
                    [18.965, -11.899],
                    [20.672, 32.958],
                    [-15.775, 9.895],
                  ],
                  o: [
                    [21.983, 35.037],
                    [-15.77, 9.895],
                    [-20.684, -32.954],
                    [18.957, -11.895],
                  ],
                  v: [
                    [197.592, -122.96],
                    [209.921, -38.536],
                    [134.115, -83.13],
                    [126.944, -170.786],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 4",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-41.334, 1.523],
                    [-0.821, -22.373],
                    [38.886, -1.423],
                    [0.678, 18.608],
                  ],
                  o: [
                    [41.336, -1.519],
                    [0.685, 18.604],
                    [-38.877, 1.438],
                    [-0.823, -22.365],
                  ],
                  v: [
                    [-7.973, -231.768],
                    [71.306, -200.232],
                    [-5.219, -156.882],
                    [-84.713, -194.497],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 6",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-19.785, -10.472],
                    [18.21, -34.388],
                    [16.456, 8.716],
                    [-19.347, 36.558],
                  ],
                  o: [
                    [16.456, 8.709],
                    [-18.192, 34.39],
                    [-19.782, -10.47],
                    [19.354, -36.558],
                  ],
                  v: [
                    [-138.036, -161.04],
                    [-138.754, -73.091],
                    [-211.076, -23.052],
                    [-204.985, -108.148],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 7",
            },
            {
              inv: false,
              mode: "s",
              pt: {
                a: 0,
                k: {
                  i: [
                    [-30.956, -49.335],
                    [49.333, -30.954],
                    [30.956, 49.336],
                    [-49.335, 30.956],
                  ],
                  o: [
                    [30.956, 49.335],
                    [-49.335, 30.959],
                    [-30.956, -49.335],
                    [49.331, -30.954],
                  ],
                  v: [
                    [89.907, -55.392],
                    [56.633, 89.986],
                    [-88.748, 56.71],
                    [-55.469, -88.671],
                  ],
                  c: true,
                },
                ix: 1,
              },
              o: { a: 0, k: 100, ix: 3 },
              x: { a: 0, k: 0, ix: 4 },
              nm: "Mask 5",
            },
          ],
          shapes: [
            {
              ty: "gr",
              it: [
                {
                  ind: 0,
                  ty: "sh",
                  ix: 1,
                  ks: {
                    a: 0,
                    k: {
                      i: [
                        [7.428, -4.661],
                        [7.604, 11.847],
                        [3.953, -3.083],
                        [6.493, -5.663],
                        [9.313, 10.11],
                        [3.408, -3.659],
                        [5.435, -6.498],
                        [10.668, 8.149],
                        [2.789, -4.134],
                        [4.273, -7.143],
                        [11.625, 6.031],
                        [2.105, -4.51],
                        [3.044, -7.576],
                        [12.193, 3.839],
                        [1.388, -4.79],
                        [1.789, -7.824],
                        [12.418, 1.676],
                        [0.636, -4.953],
                        [0.538, -7.898],
                        [12.317, -0.418],
                        [-0.137, -5.001],
                        [-0.706, -7.8],
                        [11.936, -2.406],
                        [-0.909, -4.931],
                        [-1.898, -7.537],
                        [11.273, -4.279],
                        [-1.669, -4.741],
                        [-3.031, -7.095],
                        [10.356, -5.996],
                        [-2.384, -4.439],
                        [-4.081, -6.504],
                        [9.26, -7.537],
                        [-3, -3.983],
                        [-5.053, -5.785],
                        [8.036, -8.91],
                        [-3.586, -3.476],
                        [-5.924, -4.953],
                        [6.665, -10.108],
                        [-4.079, -2.883],
                        [-6.676, -3.992],
                        [5.134, -11.108],
                        [-4.472, -2.214],
                        [-7.28, -2.922],
                        [3.482, -11.908],
                        [-4.763, -1.501],
                        [-7.731, -1.763],
                        [1.765, -12.516],
                        [-4.938, -0.746],
                        [-8.027, -0.538],
                        [-0.026, -12.921],
                        [-4.989, 0.022],
                        [-8.153, 0.737],
                        [-1.878, -13.119],
                        [-4.934, 0.8],
                        [-8.086, 2.035],
                        [-3.776, -13.032],
                        [-4.773, 1.58],
                        [-7.799, 3.336],
                        [-5.7, -12.668],
                        [-4.5, 2.318],
                        [-7.307, 4.589],
                        [-7.587, -12.039],
                        [-4.059, 2.967],
                        [-6.622, 5.783],
                        [-9.383, -10.753],
                        [-0.154, -0.192],
                        [-3.587, 3.612],
                        [0.124, 0.1],
                        [-5.683, 6.807],
                        [-10.946, -9.151],
                        [-0.362, -0.329],
                        [-3, 4.182],
                        [0.35, 0.205],
                        [-4.543, 7.613],
                        [-12.246, -7.33],
                        [-0.597, -0.403],
                        [-2.329, 4.657],
                        [0.579, 0.233],
                        [-3.303, 8.229],
                        [-13.241, -5.325],
                        [-0.819, -0.392],
                        [-1.58, 5.009],
                        [0.8, 0.188],
                        [-1.977, 8.642],
                        [-13.908, -3.185],
                        [-1.037, -0.323],
                        [-0.78, 5.255],
                        [1.003, 0.069],
                        [-0.595, 8.847],
                        [-14.234, -0.968],
                        [-1.235, -0.201],
                        [0.065, 5.378],
                        [1.17, -0.106],
                        [0.795, 8.829],
                        [-14.216, 1.268],
                        [-1.388, -0.018],
                        [0.931, 5.351],
                        [1.264, -0.318],
                        [2.163, 8.603],
                        [-13.838, 3.479],
                        [-1.46, 0.203],
                        [1.788, 5.166],
                        [1.281, -0.542],
                        [3.486, 8.151],
                        [-13.131, 5.598],
                        [-1.449, 0.44],
                        [2.593, 4.833],
                        [1.19, -0.748],
                        [4.718, 7.509],
                        [-12.087, 7.583],
                        [-1.364, 0.658],
                        [3.283, 4.318],
                        [1.057, -0.924],
                        [5.83, 6.674],
                        [-10.753, 9.382],
                        [-1.237, 0.854],
                        [3.933, 3.746],
                        [0.887, -1.059],
                        [6.807, 5.685],
                        [-9.151, 10.947],
                        [-1.046, 1],
                        [4.462, 3.059],
                        [0.665, -1.111],
                        [7.611, 4.549],
                        [-7.326, 12.246],
                        [-0.795, 1.072],
                        [4.861, 2.29],
                        [0.436, -1.074],
                        [8.227, 3.301],
                        [-5.323, 13.242],
                        [-0.534, 1.052],
                        [5.109, 1.486],
                        [0.218, -0.957],
                        [8.642, 1.968],
                        [-3.186, 13.91],
                        [-0.301, 0.967],
                        [5.231, 0.658],
                        [0.051, -0.798],
                        [8.845, 0.593],
                        [-0.97, 14.237],
                        [-0.102, 0.811],
                        [5.223, -0.174],
                        [-0.055, -0.597],
                        [8.829, -0.798],
                        [1.275, 14.213],
                        [0.03, 0.619],
                        [5.083, -0.991],
                        [-0.089, -0.362],
                        [8.593, -2.168],
                        [3.48, 13.843],
                        [0.08, 0.375],
                        [4.82, -1.767],
                        [-0.048, -0.111],
                        [8.151, -3.491],
                        [5.602, 13.124],
                        [0.048, 0.107],
                        [4.452, -2.473],
                      ],
                      o: [
                        [-7.43, 4.663],
                        [-4.14, 2.911],
                        [8.738, 10.594],
                        [-6.498, 5.669],
                        [-3.612, 3.501],
                        [9.888, 9.047],
                        [-5.436, 6.502],
                        [-3.002, 4],
                        [10.79, 7.393],
                        [-4.275, 7.145],
                        [-2.338, 4.41],
                        [11.422, 5.669],
                        [-3.05, 7.592],
                        [-1.628, 4.722],
                        [11.825, 3.916],
                        [-1.797, 7.862],
                        [-0.878, 4.914],
                        [12.034, 2.131],
                        [-0.54, 7.949],
                        [-0.111, 4.992],
                        [12.032, 0.342],
                        [0.697, 7.866],
                        [0.658, 4.948],
                        [11.816, -1.444],
                        [1.907, 7.603],
                        [1.416, 4.785],
                        [11.355, -3.172],
                        [3.063, 7.175],
                        [2.14, 4.504],
                        [10.666, -4.83],
                        [4.138, 6.595],
                        [2.83, 4.17],
                        [9.781, -6.443],
                        [5.138, 5.89],
                        [3.425, 3.681],
                        [8.672, -7.984],
                        [6.036, 5.046],
                        [3.948, 3.094],
                        [7.332, -9.391],
                        [6.798, 4.073],
                        [4.371, 2.442],
                        [5.761, -10.587],
                        [7.426, 2.987],
                        [4.689, 1.732],
                        [3.979, -11.577],
                        [7.912, 1.815],
                        [4.89, 0.989],
                        [1.996, -12.329],
                        [8.226, 0.562],
                        [4.979, 0.225],
                        [-0.152, -12.784],
                        [8.365, -0.741],
                        [4.948, -0.549],
                        [-2.436, -12.892],
                        [8.286, -2.081],
                        [4.815, -1.325],
                        [-4.741, -12.574],
                        [7.998, -3.408],
                        [4.563, -2.068],
                        [-7.01, -11.838],
                        [7.495, -4.7],
                        [4.246, -2.789],
                        [-9.189, -10.708],
                        [6.681, -5.826],
                        [0.163, 0.188],
                        [3.798, -3.447],
                        [-0.122, -0.098],
                        [-10.954, -9.152],
                        [5.688, -6.796],
                        [0.389, 0.322],
                        [3.235, -4.044],
                        [-0.349, -0.198],
                        [-12.252, -7.323],
                        [4.554, -7.6],
                        [0.628, 0.377],
                        [2.569, -4.539],
                        [-0.58, -0.205],
                        [-13.25, -5.319],
                        [3.308, -8.221],
                        [0.858, 0.347],
                        [1.839, -4.924],
                        [-0.789, -0.133],
                        [-13.919, -3.179],
                        [1.98, -8.64],
                        [1.099, 0.253],
                        [1.057, -5.206],
                        [-0.977, 0.002],
                        [-14.241, -0.965],
                        [0.61, -8.844],
                        [1.297, 0.091],
                        [0.227, -5.365],
                        [-1.124, 0.194],
                        [-14.221, 1.273],
                        [-0.787, -8.827],
                        [1.436, -0.125],
                        [-0.632, -5.367],
                        [-1.186, 0.416],
                        [-13.843, 3.479],
                        [-2.16, -8.594],
                        [1.497, -0.377],
                        [-1.485, -5.221],
                        [-1.171, 0.641],
                        [-13.124, 5.606],
                        [-3.476, -8.156],
                        [1.453, -0.623],
                        [-2.304, -4.911],
                        [-1.065, 0.828],
                        [-12.086, 7.591],
                        [-4.713, -7.508],
                        [1.338, -0.841],
                        [-3.077, -4.537],
                        [-0.929, 0.985],
                        [-10.751, 9.394],
                        [-5.829, -6.683],
                        [1.183, -1.031],
                        [-3.752, -3.983],
                        [-0.746, 1.101],
                        [-9.147, 10.956],
                        [-6.801, -5.685],
                        [0.967, -1.157],
                        [-4.313, -3.312],
                        [-0.543, 1.122],
                        [-7.327, 12.254],
                        [-7.604, -4.552],
                        [0.712, -1.188],
                        [-4.748, -2.554],
                        [-0.336, 1.074],
                        [-5.317, 13.248],
                        [-8.226, -3.312],
                        [0.457, -1.131],
                        [-5.029, -1.752],
                        [-0.155, 0.943],
                        [-3.182, 13.917],
                        [-8.642, -1.985],
                        [0.228, -1.018],
                        [-5.186, -0.928],
                        [-0.012, 0.784],
                        [-0.963, 14.241],
                        [-8.844, -0.605],
                        [0.055, -0.837],
                        [-5.212, -0.096],
                        [0.078, 0.584],
                        [1.277, 14.213],
                        [-8.831, 0.789],
                        [-0.052, -0.625],
                        [-5.103, 0.717],
                        [0.098, 0.355],
                        [3.486, 13.845],
                        [-8.601, 2.155],
                        [-0.091, -0.366],
                        [-4.872, 1.503],
                        [0.05, 0.111],
                        [5.604, 13.13],
                        [-8.158, 3.476],
                        [-0.042, -0.111],
                        [-4.523, 2.225],
                        [7.363, 11.997],
                      ],
                      v: [
                        [-146.053, -232.734],
                        [-173.142, -245.745],
                        [-185.28, -236.748],
                        [-181.116, -207.633],
                        [-209.423, -215.721],
                        [-219.958, -204.985],
                        [-211.824, -177.352],
                        [-240.488, -180.479],
                        [-249.173, -168.277],
                        [-237.415, -142.642],
                        [-265.602, -140.903],
                        [-272.259, -127.52],
                        [-257.261, -104.36],
                        [-284.175, -98.035],
                        [-288.697, -83.769],
                        [-270.877, -63.444],
                        [-295.933, -52.909],
                        [-298.201, -38.105],
                        [-277.922, -20.898],
                        [-300.567, -6.587],
                        [-300.535, 8.404],
                        [-278.218, 22.227],
                        [-298.038, 39.842],
                        [-295.691, 54.663],
                        [-271.775, 64.867],
                        [-288.346, 85.279],
                        [-283.716, 99.568],
                        [-258.741, 105.97],
                        [-271.731, 128.629],
                        [-264.944, 142.047],
                        [-239.431, 144.533],
                        [-248.674, 168.904],
                        [-239.926, 181.139],
                        [-214.33, 179.596],
                        [-219.738, 205.214],
                        [-209.22, 215.943],
                        [-184.051, 210.304],
                        [-185.52, 236.645],
                        [-173.481, 245.609],
                        [-149.339, 235.896],
                        [-146.796, 262.405],
                        [-133.522, 269.384],
                        [-111.057, 255.739],
                        [-104.499, 281.88],
                        [-90.32, 286.73],
                        [-70.139, 269.351],
                        [-59.639, 294.65],
                        [-44.896, 297.252],
                        [-27.595, 276.396],
                        [-13.253, 300.34],
                        [1.698, 300.643],
                        [15.53, 276.698],
                        [33.546, 298.814],
                        [48.369, 296.784],
                        [58.168, 270.255],
                        [79.608, 289.951],
                        [94, 285.599],
                        [99.271, 257.214],
                        [123.828, 273.949],
                        [137.42, 267.368],
                        [137.832, 237.911],
                        [165.112, 251.201],
                        [177.578, 242.559],
                        [172.897, 212.808],
                        [201.979, 221.73],
                        [202.441, 222.299],
                        [213.511, 211.703],
                        [213.141, 211.416],
                        [203.602, 182.527],
                        [233.72, 186.793],
                        [234.825, 187.778],
                        [244.179, 175.441],
                        [243.135, 174.859],
                        [229.192, 147.819],
                        [259.61, 147.32],
                        [261.434, 148.492],
                        [268.788, 134.699],
                        [267.043, 134.061],
                        [249.042, 109.535],
                        [279.005, 104.284],
                        [281.521, 105.395],
                        [286.648, 90.491],
                        [284.271, 90.022],
                        [262.654, 68.619],
                        [291.427, 58.742],
                        [294.626, 59.625],
                        [297.369, 43.925],
                        [294.396, 43.836],
                        [269.696, 26.075],
                        [296.571, 11.816],
                        [300.36, 12.274],
                        [300.613, -3.837],
                        [297.178, -3.367],
                        [270.002, -17.048],
                        [294.316, -35.333],
                        [298.552, -35.475],
                        [296.203, -51.551],
                        [292.536, -50.425],
                        [263.557, -59.69],
                        [284.709, -81.549],
                        [289.147, -82.396],
                        [284.25, -97.98],
                        [280.587, -96.183],
                        [250.518, -100.791],
                        [267.995, -125.692],
                        [272.351, -127.261],
                        [264.999, -141.877],
                        [261.63, -139.502],
                        [231.212, -139.354],
                        [244.573, -166.678],
                        [248.631, -168.909],
                        [239.087, -182.191],
                        [236.135, -179.324],
                        [206.111, -174.417],
                        [215.031, -203.501],
                        [218.668, -206.317],
                        [207.148, -217.913],
                        [204.717, -214.667],
                        [175.83, -205.125],
                        [180.095, -235.242],
                        [183.121, -238.467],
                        [169.947, -248.013],
                        [168.162, -244.657],
                        [141.122, -230.716],
                        [140.619, -261.13],
                        [142.896, -264.505],
                        [128.493, -271.792],
                        [127.362, -268.564],
                        [102.838, -250.56],
                        [97.585, -280.525],
                        [99.084, -283.789],
                        [83.873, -288.644],
                        [83.328, -285.793],
                        [61.922, -264.172],
                        [52.045, -292.947],
                        [52.854, -295.919],
                        [37.227, -298.285],
                        [37.139, -295.917],
                        [19.376, -271.219],
                        [5.122, -298.093],
                        [5.368, -300.569],
                        [-10.283, -300.46],
                        [-10.065, -298.695],
                        [-23.745, -271.523],
                        [-42.033, -295.836],
                        [-42.142, -297.697],
                        [-57.429, -295.128],
                        [-57.126, -294.058],
                        [-66.384, -265.076],
                        [-88.248, -286.231],
                        [-88.487, -287.343],
                        [-103.03, -282.44],
                        [-102.877, -282.113],
                        [-107.49, -252.035],
                        [-132.393, -269.508],
                        [-132.52, -269.839],
                        [-145.985, -262.784],
                      ],
                      c: true,
                    },
                    ix: 2,
                  },
                  nm: "Path 1",
                  mn: "ADBE Vector Shape - Group",
                  hd: false,
                },
                {
                  ty: "fl",
                  c: {
                    a: 0,
                    k: [
                      0.054901960784313725, 0.3764705882352941,
                      0.4823529411764706, 1,
                    ],
                    ix: 4,
                  },
                  o: { a: 0, k: 100, ix: 5 },
                  r: 1,
                  bm: 0,
                  nm: "Fill 1",
                  mn: "ADBE Vector Graphic - Fill",
                  hd: false,
                },
                {
                  ty: "tr",
                  p: { a: 0, k: [0, 0], ix: 2 },
                  a: { a: 0, k: [0, 0], ix: 1 },
                  s: { a: 0, k: [100, 100], ix: 3 },
                  r: { a: 0, k: 0, ix: 6 },
                  o: { a: 0, k: 100, ix: 7 },
                  sk: { a: 0, k: 0, ix: 4 },
                  sa: { a: 0, k: 0, ix: 5 },
                  nm: "Transform",
                },
              ],
              nm: "Shape 1",
              np: 3,
              cix: 2,
              bm: 0,
              ix: 1,
              mn: "ADBE Vector Group",
              hd: false,
            },
          ],
          ip: 0,
          op: 8092.00032959392,
          st: 0,
          bm: 0,
        },
      ],
    },
  ],
  layers: [
    {
      ddd: 0,
      ind: 1,
      ty: 3,
      nm: "Null 266",
      sr: 1,
      ks: {
        o: { a: 0, k: 0, ix: 11 },
        r: { a: 0, k: 0, ix: 10 },
        p: { a: 0, k: [903.341, 530.704, 0], ix: 2, l: 2 },
        a: { a: 0, k: [0, 0, 0], ix: 1, l: 2 },
        s: { a: 0, k: [108, 108, 100], ix: 6, l: 2 },
      },
      ao: 0,
      ip: 0,
      op: 150.000006109625,
      st: -150.000006109625,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 2,
      ty: 0,
      nm: "Gear 55",
      parent: 1,
      refId: "comp_0",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-171.396, 28.91, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [37, 37, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -135.000005498663,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 3,
      ty: 0,
      nm: "Gear 56",
      parent: 1,
      refId: "comp_1",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [720] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [450.172, -206.501, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [18.524, 18.524, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -92.0000037472368,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 4,
      ty: 0,
      nm: "Gear 56",
      parent: 1,
      refId: "comp_2",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-297.361, -187.916, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [24.771, 24.771, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -120.0000048877,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 5,
      ty: 0,
      nm: "Gear 57",
      parent: 1,
      refId: "comp_3",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [247.801, 210.631, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [25.958, 25.958, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -106.000004317469,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 6,
      ty: 0,
      nm: "Gear 57",
      parent: 1,
      refId: "comp_4",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-427.782, -11.585, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [32.153, 32.153, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -118.000004806239,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 7,
      ty: 0,
      nm: "Gear 58",
      parent: 1,
      refId: "comp_5",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [115.442, 12.207, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [41.6, 41.6, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -137.000005580124,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 8,
      ty: 0,
      nm: "Gear 58",
      parent: 1,
      refId: "comp_6",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [720] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [415.442, 9.207, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [44, 44, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -131.000005335739,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 9,
      ty: 0,
      nm: "Gear 59",
      parent: 1,
      refId: "comp_7",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-32.558, -199.793, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [33.2, 33.2, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -123.000005009893,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 10,
      ty: 0,
      nm: "Gear 59",
      parent: 1,
      refId: "comp_8",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [80.942, -350.793, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [22, 22, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -127.000005172816,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 11,
      ty: 0,
      nm: "Gear 60",
      parent: 1,
      refId: "comp_9",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-184.558, -324.293, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [21.8, 21.8, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -94.0000038286985,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 12,
      ty: 0,
      nm: "Gear 60",
      parent: 1,
      refId: "comp_10",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-448.058, -180.793, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [15.9, 15.9, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -96.0000039101602,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 13,
      ty: 0,
      nm: "Gear 61",
      parent: 1,
      refId: "comp_11",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [84.442, 232.207, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [16.4, 16.4, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -116.000004724777,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 14,
      ty: 0,
      nm: "Gear 61",
      parent: 1,
      refId: "comp_12",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [390.849, 217.762, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [10.444, 10.444, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -99.0000040323527,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 15,
      ty: 0,
      nm: "Gear 61",
      parent: 1,
      refId: "comp_12",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [333.442, 412.207, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [16, 16, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -99.0000040323527,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 16,
      ty: 0,
      nm: "Gear 62",
      parent: 1,
      refId: "comp_13",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [251.442, 350.957, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [12.4, 12.4, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -124.000005050624,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 17,
      ty: 0,
      nm: "Gear 62",
      parent: 1,
      refId: "comp_14",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [342.442, 309.207, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [11, 11, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -122.000004969162,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 18,
      ty: 0,
      nm: "Gear 64",
      parent: 1,
      refId: "comp_15",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [720] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-54.558, -380.793, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [17.8, 17.8, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -119.000004846969,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 19,
      ty: 0,
      nm: "Gear 64",
      parent: 1,
      refId: "comp_16",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [1080] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-334.058, 125.707, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [14, 14, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -114.000004643315,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 20,
      ty: 0,
      nm: "Gear 65",
      parent: 1,
      refId: "comp_17",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [1440] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-169.558, -139.293, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [7.1, 7.1, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -104.000004236007,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 21,
      ty: 0,
      nm: "Gear 65",
      parent: 1,
      refId: "comp_18",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [720] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [-19.558, 160.207, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [12.8, 12.8, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -107.000004358199,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 22,
      ty: 0,
      nm: "Gear 66",
      parent: 1,
      refId: "comp_19",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [-360] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [255.442, -225.793, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [36.8, 36.8, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -129.000005254278,
      bm: 0,
    },
    {
      ddd: 0,
      ind: 23,
      ty: 0,
      nm: "Gear 67",
      parent: 1,
      refId: "comp_20",
      sr: 1,
      ks: {
        o: { a: 0, k: 100, ix: 11 },
        r: {
          a: 1,
          k: [
            {
              i: { x: [0.833], y: [0.833] },
              o: { x: [0.167], y: [0.167] },
              t: 0,
              s: [0],
            },
            { t: 149.000006068894, s: [720] },
          ],
          ix: 10,
        },
        p: { a: 0, k: [109.442, -156.793, 0], ix: 2, l: 2 },
        a: { a: 0, k: [500, 500, 0], ix: 1, l: 2 },
        s: { a: 0, k: [8.45, 8.45, 100], ix: 6, l: 2 },
      },
      ao: 0,
      w: 1000,
      h: 1000,
      ip: 0,
      op: 150.000006109625,
      st: -97.000003950891,
      bm: 0,
    },
  ],
  //@ts-ignore
  markers: [],
}

export default brainThinking
