import "../../icons/styling-icons.sass"

import * as React from "react"

import window from "../../../assets/meddream-icons/window.svg"
import windowactive from "../../../assets/meddream-icons/windowactive.svg"
// import dropdownIcon from "../../../assets/meddream-icons/dropdownicon.svg"
// import dropdownIconActive from "../../../assets/meddream-icons/dropdowniconactive.svg"
// import mouse from "../../../assets/meddream-icons/mouse.svg"

interface IWindow {
  windowIcon: boolean
  handleActiveIcon: any
  dropdown: string
  handleDropdown: any
}

const WindowIcon = (props: IWindow) => {
  const { dropdown, handleDropdown, windowIcon, handleActiveIcon } = props
  const globalViewport: any = global

  const handleMeasurementChange = () => {
    if (windowIcon) {
      handleActiveIcon("")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 0)
    } else {
      handleActiveIcon("window")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 1)
    }
  }

  return (
    <div className={`container-icons ${windowIcon && "active"}`}>
      <div className="study-top-btn" onClick={() => handleMeasurementChange()}>
        <img
          width="30px"
          height="30px"
          src={windowIcon ? windowactive : window}
          alt="window"
        />
        <h5 style={{ color: windowIcon ? "#2CA895" : "#666" }}>Window</h5>
      </div>

      {/* <div className="study-top-dropdown">
        <img
          onClick={() => handleDropdown()}
          className="top-btn-drop-down"
          width="15px"
          height="15px"
          src={windowIcon ? dropdownIconActive : dropdownIcon}
          alt="drop-down"
        />
      </div>
      <div className="dropdown-container">
        {dropdown && (
          <div>
            <p>Hello</p>
          </div>
        )}
      </div> */}
    </div>
  )
}

export default WindowIcon
