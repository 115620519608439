import "./actions-buttons.sass"

import * as React from "react"

import { Link } from "react-router-dom"

const ActionsButtons = ({ match, row }: any) => {
  return (
    <div className="actions-button-container">
      <Link
        to={
          row.task_type === "Adjudicate"
            ? `/project/${match.params.project_id}/adjudicate/${row.id}`
            : `/project/${match.params.project_id}/task/${row.id}`
        }
      >
        <button className="view">
          {row.status === "Completed" ||
          row.status === "Rejected" ||
          row.status === "done"
            ? "View"
            : row.task_type === "Read"
            ? "Read"
            : "Adjudicate"}
        </button>
      </Link>
    </div>
  )
}

export default ActionsButtons
