import * as React from "react"
import * as Yup from "yup"

import { IS_FETCHING, toolTipText } from "../../../constants/constants"
import { addNewSite, getRoles } from "../../../actions/apiAction"
import { useDispatch, useSelector } from "react-redux"

import CustomInput from "../../forms/custom-fields/input"
import CustomSelect from "../../forms/custom-fields/select"
import Fetching from "../../../components/common/fetching-table/fetching-table"
import { Grid } from "@material-ui/core"
import { Link } from "react-router-dom"
import LoaderPoints from "../../../components/common/loader-points/loader-points"
import Tooltip from "@material-ui/core/Tooltip"
import { compose } from "recompose"
import { reduxForm } from "redux-form"
import { useEffect } from "react"
import { useFormik } from "formik"

const SitesAdd = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state)

  useEffect(() => {
    dispatch({
      type: IS_FETCHING,
    })
    if (state.current.project.data.project_type) {
      dispatch(getRoles(state.current.project.data.project_type))
    }
  }, [state.current.project.data.project_type])

  const formik = useFormik({
    initialValues: {
      imagining_site: "",
      display_name: "",
      number_subject: 0,
      data_location: "us",
    },
    validationSchema: Yup.object({
      imagining_site: Yup.string()
        .email("Invalid email format")
        .required("Imagining Site is required"),
      display_name: Yup.string()
        .required("Required!")
        .max(2, "Not allowed more than 100")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      number_subject: Yup.string()
        .email("Invalid email format")
        .required("Mail is required"),
    }),
    onSubmit: (values) => onSubmit(values),
    validateOnChange: true,
  })

  const onSubmit = (values: any) => {
    const params = {
      ...values,
      imagining_site: values.imagining_site,
      display_name: values.display_name,
      number_subject: values.number_subject,
      data_location: values.data_location,
    }

    if (!Object.keys(formik.errors).length) {
      dispatch(addNewSite(params, props.history, props.match.params.project_id))
    }
  }

  return (
    <Grid container>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <div className="container-form">
          <h1>
            Add an Imagining Site
            <LoaderPoints isloading={state.project_config.isFetching} />
          </h1>
          <Fetching isFetching={state.project_config.isFetching}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container className="form-container-create-edit">
                <CustomInput
                  label={"Imagining Site *"}
                  name={"imagining_site"}
                  placeholder={"Type your site name e.g Barcelona Hospital"}
                  type={"text"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />
                <CustomInput
                  label={"Display Name / Abbreviation *"}
                  name={"display_name"}
                  placeholder={"Type an abbreviation e.g BRIC-H"}
                  type={"text"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                  advice={`Max. 2 characters`}
                />
                <CustomInput
                  label={"Number of Subjects *"}
                  name={"number_subject"}
                  placeholder={"Provide the potential amount of subjects"}
                  type={"number"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />

                {state.project_config.data.data_locations[0].value && (
                  <CustomSelect
                    label={"Data Storage Location *"}
                    name={"data_location"}
                    onChange={formik.handleChange}
                    classname={"form-item"}
                    formik={formik}
                    defaultValue={
                      state.project_config.data.data_locations[0].value
                    }
                    options={state.project_config.data.data_locations}
                  />
                )}
              </Grid>

              <Grid container className="buttons-container">
                <Link to={`/project/${props.match.params.project_id}/sites`}>
                  <button className={"btn quaternary"}>Cancel</button>
                </Link>

                {Object.keys(formik.errors).length ? (
                  <Tooltip title={toolTipText} placement="bottom-start">
                    <button
                      className="btn inactive denial"
                      type="submit"
                      disabled={props.submitting}
                    >
                      Add Sites
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    className="btn primary"
                    type="submit"
                    disabled={props.submitting}
                  >
                    Add Sites
                  </button>
                )}
              </Grid>
            </form>
          </Fetching>
        </div>
      </Grid>
    </Grid>
  )
}

export default compose(
  reduxForm({
    form: "SitesAdd",
  })
)(SitesAdd)
