import { IError } from "../utils/interfaces"
import { callAPI } from "../middlewares/call-api"

export const sessionLogout = () => {
  return (dispatch: any) => {
    localStorage.removeItem("flags")
    return (
      dispatch(callAPI("/logout", "POST"))
        .then(() => {
          // in the case there is a user previously logged
          window.location.replace(`${process.env.REACT_APP_LOGIN_URL}`)
        })
        .catch((error: IError) => console.error(error))
        // logout endpoint will give error if no user is present so then() is jumped
        .finally(() =>
          window.location.replace(`${process.env.REACT_APP_LOGIN_URL}`)
        )
    )
  }
}
