import "./study-card.sass"

import * as React from "react"

import { useEffect, useState } from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Tooltip from "@material-ui/core/Tooltip"
import { useSelector } from "react-redux"

const mockSites = [
  {
    name: "Amsterdam",
    uploaded_data: {
      pass: 15,
      fail: 0,
    },
    target: 30,
  },
  {
    name: "Barcelona",
    uploaded_data: {
      pass: 15,
      fail: 0,
    },
    target: 45,
  },
  {
    name: "Chicago",
    uploaded_data: {
      pass: 15,
      fail: 0,
    },
    target: 45,
  },
]

const toolTipText =
  "Data acquisition shows the progress of data uploaded per site towards the target number of sessions"

const StudyAcquisitionCard = () => {
  const [project, setProject] = useState<any>({
    acquisiton: {
      target: 0,
      subject: 0,
    },
  })
  const state = useSelector((state: any) => state)

  useEffect(() => {
    setProject(createDataAcquisition(state.current.project.data))
  }, [state.current.project.data])

  const checkData = (data: any) => {
    if (data?.length) return data
    if (!data?.length) return mockSites
  }

  const createDataAcquisition = (project: any) => {
    const { sites } = project

    // fill in missing values
    project.sites.map((curr: any) => {
      if (!("target" in curr) || curr.target == null) {
        curr.target = 0
      }

      if (!("uploaded_data" in curr) || curr.uploaded_data == null) {
        curr.uploaded_data = {
          pass: 0,
          fail: 0,
        }
      }
    })

    const dataAcquisiton = {
      subject: calculateDataAcquisition(project),
      target: checkData(project.sites).reduce((acc: any, curr: any) => {
        return (acc += curr.target)
      }, 0),
    }

    return {
      sites: [...sites],
      acquisiton: { ...dataAcquisiton },
    }
  }

  const calculateDataAcquisition = (project: any) => {
    if (project.sites.length) {
      return project.sites.reduce((acc: any, current: any) => {
        return (acc += current.uploaded_data ? current.uploaded_data.pass : 0)
      }, 0)
    } else {
      return mockSites.reduce((acc, current) => {
        return (acc += current.uploaded_data ? current.uploaded_data.pass : 0)
      }, 0)
    }
  }

  const calculateSubjectPercentFromTargetPercent = (
    subject: any,
    target: any
  ) => {
    return (subject || target) === 0 ? 0 : Math.floor((subject / target) * 100)
  }

  return (
    <Card className="card">
      <CardContent>
        <h3 className="weight-medium">
          Data Acquisition&nbsp;
          {/* tooltip placement doesn't work the same way as the docs described
              bottom-start and bottom-end seem to be reversed
              https://material-ui.com/components/tooltips/#positioned-tooltips
          */}
          <Tooltip title={toolTipText} placement="bottom-start">
            <i className="material-icons sm-icon">help</i>
          </Tooltip>
        </h3>
        <div>
          <div className="card-acquisition-container">
            <div>
              <progress
                id="study_acquisition_card.progress_bar"
                value={project.acquisiton.subject}
                max={project.acquisiton.target}
                className="progress-bar"
              />
            </div>
            <div className="progress-container-info">
              <label id="study_acquisition_card.subject">{`${
                project.acquisiton.subject
              } Timepoints (${calculateSubjectPercentFromTargetPercent(
                project.acquisiton.subject,
                project.acquisiton.target
              )}%)`}</label>
              <label id="study_acquisition_card.target">{`Target: ${project.acquisiton.target}`}</label>
            </div>
          </div>
          {checkData(project.sites).map((site: any, index: any) => {
            return (
              <React.Fragment key={index}>
                <div className="info-container">
                  <p
                    id={"study_acquisition_card.site-" + index + ".name"}
                    className="key-column"
                  >
                    {" "}
                    {site.name}
                  </p>
                  <div className="sites-info">
                    {site.uploaded_data && site.uploaded_data.fail !== 0 ? (
                      <i
                        id={"study_acquisition_card.site-" + index + ".qc_icon"}
                        className="material-icons is-warning"
                      >
                        warning
                      </i>
                    ) : (
                      <i
                        id={"study_acquisition_card.site-" + index + ".qc_icon"}
                        className="material-icons is-okay"
                      >
                        check_circle_outline
                      </i>
                    )}
                    <progress
                      id={
                        "study_acquisition_card.site-" +
                        index +
                        ".uploaded_data"
                      }
                      value={site.uploaded_data ? site.uploaded_data.pass : 0}
                      max={site.target}
                    />
                    <div className="per-site">
                      {site.uploaded_data ? (
                        <span
                          id={
                            "study_acquisition_card.site-" +
                            index +
                            ".pass_target"
                          }
                        >{`${site.uploaded_data.pass} / ${site.target}`}</span>
                      ) : (
                        <span
                          id={
                            "study_acquisition_card.site-" +
                            index +
                            ".pass_target"
                          }
                        >{`0 / ${site.target}`}</span>
                      )}
                    </div>
                  </div>
                </div>
                {project.sites?.length !== index + 1 ? null : null}
              </React.Fragment>
            )
          })}
        </div>
      </CardContent>
    </Card>
  )
}

export default StudyAcquisitionCard
