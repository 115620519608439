interface Column {
  label: string
  className?: string
  accessor: string
  id: any
  align?: "left" | "center"
}

const projectListColumns: Column[] = [
  {
    label: "Status",
    className: "tag is-active",
    accessor: "status",
    id: "status",
    align: "left",
  },
  {
    label: "Name",
    id: "name",
    accessor: "name",
    align: "left",
  },
  {
    label: "My access role",
    accessor: "my_access",
    id: "my_access",
    align: "left",
  },
]

const activityColumn: Column[] = [
  {
    label: "Date & time",
    accessor: "timestamp",
    id: "timestamp",
    align: "left",
  },
  {
    label: "Username",
    accessor: "user_name",
    id: "user_name",
    align: "left",
  },
  {
    label: "Activity name",
    accessor: "name",
    id: "name",
    align: "left",
  },
]

const workListColumn: Column[] = [
  {
    label: "Status",
    id: "status",
    accessor: "status",
    align: "left",
  },
  {
    label: "Patient",
    id: "patient_secret_name",
    accessor: "patient_secret_name",
    align: "left",
  },
  {
    label: "Timepoint",
    id: "session_id",
    accessor: "session_id",
    align: "left",
  },
  {
    label: "Date Submitted",
    id: "timestamp",
    accessor: "timestamp",
    align: "left",
  },
  {
    label: "Task type",
    id: "task_type",
    accessor: "task_type",
    align: "left",
  },
  {
    label: "Actions",
    id: "action",
    accessor: "action",
    align: "left",
  },
]

const siteColumn: Column[] = [
  {
    label: "Name",
    accessor: "name",
    id: "name",
    align: "left",
  },
  {
    label: "Number of Subject",
    accessor: "number",
    id: "number",
    align: "left",
  },
  {
    label: "invited Date",
    accessor: "site",
    id: "site",
    align: "left",
  },
]

const collaboratorColumn: Column[] = [
  {
    label: "Collaborator",
    accessor: "collaborator",
    id: "collaborator",
    align: "left",
  },
  {
    label: "Email",
    accessor: "email",
    id: "email",
    align: "left",
  },
  {
    label: "Imagining Site",
    accessor: "site",
    id: "site",
    align: "left",
  },
  {
    label: "User Role",
    accessor: "role",
    id: "role",
    align: "left",
  },
]

const MonitoringHeader: any = [
  {
    label: "Subject",
    minWidth: 170,
    id: "patient_secret_name",
    cellName: "patient_secret_name",
    sortField: "patient_secret_name",
  },
  {
    label: "Session",
    minWidth: 170,
    id: "ssid",
    cellName: "ssid",
    sortField: "ssid",
  },
  {
    label: "QA",
    minWidth: 170,
    id: "qa_status_icon",
    cellName: "qa",
    sortField: "qa_status_icon",
  },
]

export {
  projectListColumns,
  activityColumn,
  workListColumn,
  collaboratorColumn,
  MonitoringHeader,
  siteColumn,
}
