import "./central-review-progress-monitoring.sass"

import * as React from "react"

import { Card, CardContent } from "@material-ui/core"
import {
  getCentralReadingProgress,
  setAdjudicator,
  setAssignUser,
  setNotNeededAdjudicator,
  setReader_1,
  setReader_2,
  setUnassignUser,
} from "../../actions/CentralReviewAction"
import { useDispatch, useSelector } from "react-redux"

import AddUserModal from "../../components/common/Table/add-user-modal"
import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import ExpandMenuButton from "../../components/common/Table/expand-menu-button"
import FailIcon from "../../assets/icons/fail.png"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Grid from "@material-ui/core/Grid"
import InProgressIcon from "../../assets/icons/in_progress.png"
import LoaderPoints from "../../components/common/loader-points/loader-points"
import LoadingWhite from "../../assets/icons/loading_white.gif"
import MenuItem from "@material-ui/core/MenuItem"
import NotNeeded from "../../assets/icons/open.png"
import OpenIcon from "../../assets/icons/open.png"
import Paper from "@material-ui/core/Paper"
import PassIcon from "../../assets/icons/pass.png"
import { RootState } from "../../reducers/mainReducer"
import Select from "@material-ui/core/Select"
import Table from "@material-ui/core/Table/"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import Tooltip from "@material-ui/core/Tooltip"
import { useEffect } from "react"

interface Column {
  id: any
  cellName: any
  label: string
  width?: number
  minWidth?: number
  align?: "right"
  sortField: string
  format?: (value: number) => string
}

const CentralReviewProgressMonitoring = (props: any) => {
  const [order, orderSet] = React.useState("asc")
  const [orderBy, orderBySet] = React.useState("id")
  const [pageTable, pageTableSet] = React.useState(0)
  const [openDialog, openDialogSet] = React.useState(false)
  const [comesFromAdj, comesFromAdjSet] = React.useState(false)
  const [isLoading, isLoadingSet] = React.useState({
    rowIndex: null,
    cellIndex: null,
    loading: false,
  })
  const [valuesNotNeededAdj, valuesNotNeededAdjSet] = React.useState({})
  const [valuesUnassign, valuesUnassignSet] = React.useState({})
  const [visibleAddUserModal, setVisibleAddUserModal] = React.useState(false)
  const [reader_1, reader_1Set] = React.useState({})
  const [reader_2, reader_2Set] = React.useState({})
  const [adjudicator, adjudicatorSet] = React.useState({})
  const [columnName, setColumnName] = React.useState("")
  const [clickedRow, setClickedRow] = React.useState({
    unassigned_readers: [],
    _id: 0,
  })
  const [queryData, SetQueryData] = React.useState({
    projectset_id: props.match.params.project_id,
    limit: 10,
    offset: 0,
    status: "",
    assigned_to: "",
    // sort: "1, ssid",
  })
  const [title] = React.useState("Assign To:")
  const [submitButton] = React.useState("Assign")
  const dispatch = useDispatch()
  const state = useSelector((state: RootState | any) => state)

  const DataStatus = [
    {
      name: "Pass",
      value: "pass",
    },
    {
      name: "Fail",
      value: "fail",
    },
    {
      name: "Open",
      value: "open",
    },
    {
      name: "In progress",
      value: "in_progress",
    },
    {
      name: "Not Needed",
      value: "not_needed",
    },
  ]

  const { header } = state.current.project.progress_monitoring_table

  useEffect(() => {
    dispatch(getCentralReadingProgress(queryData))
  }, [reader_1, reader_2, adjudicator, queryData])

  const setImgurl = (status: any) => {
    switch (status) {
      case "pass":
      case "done":
        return PassIcon
      case "fail":
        return FailIcon
      case "open":
        return OpenIcon
      case "in_progress":
        return InProgressIcon
      case "not_needed":
        return NotNeeded
    }
  }

  const _openAddUserModal = (
    row: any,
    column: any,
    cellIndex: any,
    rowIndex: any
  ) => {
    isLoadingSet({
      cellIndex,
      rowIndex,
      loading: false,
    })
    setClickedRow(row)
    setColumnName(column.cellName)
    setVisibleAddUserModal(true)
  }

  const setAssignUserAsync = async (values: any) => {
    isLoadingSet({
      ...isLoading,
      loading: true,
    })
    Promise.all([dispatch(setAssignUser(values, queryData))]).then(() =>
      isLoadingSet({
        ...isLoading,
        loading: false,
      })
    )
  }

  const setUnassignUserAsync = async () => {
    openDialogSet(false)
    Promise.all([dispatch(setUnassignUser(valuesUnassign, queryData))]).then(
      () => {
        isLoadingSet({
          ...isLoading,
          loading: false,
        })
      }
    )
  }

  const onSubmitModal = (user: any) => {
    setVisibleAddUserModal(false)
    const values = {
      patient_id: clickedRow._id,
      assigned_to: user,
      task_type: columnName,
      projectset_id: props.match.params.project_id,
    }

    isLoadingSet({
      ...isLoading,
      loading: true,
    })

    switch (columnName) {
      case "reading_1":
        reader_1Set(user)
        return setAssignUserAsync(values).then(() => {
          dispatch(setReader_1(user))
        })
      case "reading_2":
        reader_2Set(user)
        return setAssignUserAsync(values).then(() => {
          dispatch(setReader_2(user))
        })
      case "diagnosis_adjudicator":
        adjudicatorSet(user)
        return setAssignUserAsync(values).then(() => {
          dispatch(setAdjudicator(user))
        })
    }
  }

  const MyTableCell = (props: any) => {
    return <TableCell {...props} />
  }

  const handleAssignedUser = (columnIndex: any, rowIndex: any) => {
    switch (columnIndex) {
      case 3: // Reader 1
        return state.CentralReview.progress[rowIndex]
          .reader_1_assigned_to_display_name
      case 4: // Reader 2
        return state.CentralReview.progress[rowIndex]
          .reader_2_assigned_to_display_name
      case 5: // Adjudicator
        return state.CentralReview.progress[rowIndex]
          .diagnosis_adjudicator_assigned_to_display_name
    }
  }

  const handleAssignedUserEmails = (columnIndex: any, rowIndex: any) => {
    switch (columnIndex) {
      case 3: // Reader 1 email / user_id
        return state.CentralReview.progress[rowIndex].reader_1_assigned_to
      case 4: // Reader 2 email / user_id
        return state.CentralReview.progress[rowIndex].reader_2_assigned_to
      case 5: // Adjudicator email / user_id
        return state.CentralReview.progress[rowIndex]
          .diagnosis_adjudicator_assigned_to
    }
  }

  const setNotNeededAdj = (cellIndex: any, rowIndex: any, patient_id: any) => {
    const values = {
      projectset_id: props.match.params.project_id,
      patient_id,
    }
    isLoadingSet({
      cellIndex,
      rowIndex,
      loading: false,
    })
    valuesNotNeededAdjSet(values)
    comesFromAdjSet(true)
    openDialogSet(true)
  }

  const handleNotNeededAdjDispatch = () => {
    openDialogSet(false)
    isLoadingSet({
      ...isLoading,
      loading: true,
    })
    Promise.all([dispatch(setNotNeededAdjudicator(valuesNotNeededAdj))]).then(
      () => {
        isLoadingSet({
          ...isLoading,
          loading: false,
        })
        dispatch(getCentralReadingProgress(queryData))
      }
    )
  }

  const setUnassignUserDispatch = (
    user_id: any,
    cellIndex: any,
    rowIndex: any
  ) => {
    isLoadingSet({
      cellIndex,
      rowIndex,
      loading: false,
    })
    let task_id
    switch (cellIndex) {
      case 3: // Reader 1 task_id
        task_id = state.CentralReview.progress[rowIndex].reader_1_task_id
        break
      case 4: // Reader 2 task_id
        task_id = state.CentralReview.progress[rowIndex].reader_2_task_id
        break
      case 5: // Adjudicator task_id
        task_id =
          state.CentralReview.progress[rowIndex].diagnosis_adjudicator_task_id
        break
    }

    const values = {
      projectset_id: props.match.params.project_id,
      task_id,
      user_id,
    }
    valuesUnassignSet(values)
    comesFromAdjSet(false)
    openDialogSet(true)
  }

  const handleUnassignUserDispatch = () => {
    setUnassignUserAsync().then(() => {
      isLoadingSet({
        ...isLoading,
        loading: false,
      })
    })
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    pageTableSet(newPage)
    SetQueryData((prevState: any) => ({
      ...prevState,
      offset: newPage ? newPage * queryData.limit + 1 : 0,
    }))
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    pageTableSet(0)
    SetQueryData((prevState: any) => ({
      ...prevState,
      offset: 0,
      limit: +event.target.value,
    }))
  }

  return (
    <>
      {state.CentralReview.progress && (
        <Grid
          container
          style={{ padding: "16px", justifyContent: "space-between" }}
        >
          <Grid item xs={12}>
            <h1 className="title">
              Central Review monitoring
              <LoaderPoints isloading={state.CentralReview.isFetching} />
            </h1>
            <label>
              You are monitoring {state.CentralReview.totalResult} active tasks
            </label>
          </Grid>
          {state.CentralReview.progress ? (
            <>
              <Grid item xs={12} sm={12} md={12}>
                {!state.CentralReview.isFetching && (
                  <Paper className="root">
                    <TableContainer>
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            {header.map((column: Column, index: number) => (
                              <MyTableCell
                                key={index}
                                align={column.align}
                                style={{ minWidth: column.minWidth }}
                                sortDirection={
                                  orderBy === column.id ? order : false
                                }
                              >
                                {column.label}
                              </MyTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {state.CentralReview.progress.map(
                            (row: any, rowIndex: any) => {
                              return (
                                <TableRow
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={rowIndex}
                                >
                                  {header.map(
                                    (column: Column, cellIndex: number) => {
                                      const value = row[column.id]
                                      if (
                                        column.id === "qa_status_icon" ||
                                        column.id === "reader_1_status_icon" ||
                                        column.id === "reader_2_status_icon" ||
                                        column.id ===
                                          "diagnosis_adjudicator_status_icon"
                                      ) {
                                        if (value === "not_assigned") {
                                          return (
                                            <MyTableCell
                                              width={`${column.minWidth}px`}
                                              component="th"
                                              scope="row"
                                              id={
                                                column.id +
                                                "-row-" +
                                                rowIndex +
                                                "-cell-" +
                                                cellIndex
                                              }
                                              key={
                                                column.id +
                                                "-row-" +
                                                rowIndex +
                                                "-cell-" +
                                                cellIndex
                                              }
                                            >
                                              {cellIndex === 5 &&
                                              row.reader_1_status === "done" &&
                                              row.reader_2_status === "done" ? (
                                                isLoading.cellIndex ===
                                                  cellIndex &&
                                                isLoading.rowIndex ===
                                                  rowIndex &&
                                                isLoading.loading ? (
                                                  <img
                                                    width="24px"
                                                    height="24px"
                                                    src={LoadingWhite}
                                                    alt="loading"
                                                  />
                                                ) : (
                                                  <FormControl>
                                                    <Select
                                                      labelId="demo-simple-select-autowidth-label"
                                                      id="demo-simple-select-autowidth"
                                                      value={1}
                                                      onChange={() => null}
                                                      autoWidth
                                                    >
                                                      <MenuItem value={1}>
                                                        <button
                                                          className="row-button"
                                                          onClick={() =>
                                                            _openAddUserModal(
                                                              row,
                                                              column,
                                                              cellIndex,
                                                              rowIndex
                                                            )
                                                          }
                                                        >
                                                          Assign
                                                        </button>
                                                      </MenuItem>
                                                      <MenuItem value={2}>
                                                        <button
                                                          className="row-button"
                                                          onClick={() =>
                                                            setNotNeededAdj(
                                                              cellIndex,
                                                              rowIndex,
                                                              row._id
                                                            )
                                                          }
                                                        >
                                                          Not Needed
                                                        </button>
                                                      </MenuItem>
                                                    </Select>
                                                    <FormHelperText>
                                                      Assign Adjudicator
                                                    </FormHelperText>
                                                  </FormControl>
                                                )
                                              ) : (
                                                <div>
                                                  {isLoading.cellIndex ===
                                                    cellIndex &&
                                                  isLoading.rowIndex ===
                                                    rowIndex &&
                                                  isLoading.loading ? (
                                                    <img
                                                      width="24px"
                                                      height="24px"
                                                      src={LoadingWhite}
                                                      alt="loading"
                                                    />
                                                  ) : cellIndex === 5 ? (
                                                    <img
                                                      alt="rowImg"
                                                      className="row-img"
                                                      src={OpenIcon}
                                                    />
                                                  ) : (
                                                    <button
                                                      disabled={
                                                        isLoading.loading &&
                                                        isLoading.rowIndex ===
                                                          rowIndex
                                                      }
                                                      className="row-button"
                                                      onClick={() =>
                                                        _openAddUserModal(
                                                          row,
                                                          column,
                                                          cellIndex,
                                                          rowIndex
                                                        )
                                                      }
                                                    >
                                                      Assign
                                                    </button>
                                                  )}
                                                </div>
                                              )}
                                            </MyTableCell>
                                          )
                                        } else {
                                          return (
                                            <MyTableCell
                                              width={`${column.minWidth}px`}
                                              component="th"
                                              scope="row"
                                              id={
                                                column.id +
                                                "-row-" +
                                                rowIndex +
                                                "-cell-" +
                                                cellIndex
                                              }
                                              key={
                                                column.id +
                                                "-row-" +
                                                rowIndex +
                                                "-cell-" +
                                                cellIndex
                                              }
                                            >
                                              <div
                                                style={{
                                                  display: "flex",
                                                  alignItems: "center",
                                                }}
                                              >
                                                {isLoading.cellIndex ===
                                                  cellIndex &&
                                                isLoading.rowIndex ===
                                                  rowIndex &&
                                                isLoading.loading ? (
                                                  <img
                                                    width="24px"
                                                    height="24px"
                                                    alt="loading"
                                                  />
                                                ) : (
                                                  <div
                                                    style={{
                                                      margin: "0",
                                                      padding: "0",
                                                      display: "flex",
                                                      alignItems: "center",
                                                    }}
                                                  >
                                                    <Tooltip
                                                      title={`Task is in ${
                                                        row[column.id]
                                                      } mode`}
                                                      placement="bottom-start"
                                                    >
                                                      <img
                                                        alt="rowImg"
                                                        className="row-img"
                                                        src={setImgurl(value)}
                                                      />
                                                    </Tooltip>
                                                    <span
                                                      style={{
                                                        fontSize: "15px",
                                                        marginLeft: "5px",
                                                        minWidth: "80px",
                                                        maxWidth: "80px",
                                                        overflowWrap:
                                                          "break-word",
                                                      }}
                                                    >
                                                      {handleAssignedUser(
                                                        cellIndex,
                                                        rowIndex
                                                      )}
                                                    </span>
                                                    {(value.includes("pass") ||
                                                      value.includes("done") ||
                                                      value.includes("fail") ||
                                                      value.includes("open")) &&
                                                      cellIndex !==
                                                        2 /** 2 is QA */ && (
                                                        <ExpandMenuButton
                                                          centralReadingTable={
                                                            true
                                                          }
                                                          row={row}
                                                          isForView={true}
                                                          children={
                                                            <div>
                                                              {(value.includes(
                                                                "pass"
                                                              ) ||
                                                                value.includes(
                                                                  "done"
                                                                )) && (
                                                                <MenuItem
                                                                  id={
                                                                    "menu_item_info.row-" +
                                                                    rowIndex +
                                                                    "-cell-" +
                                                                    cellIndex
                                                                  }
                                                                  onClick={() => {
                                                                    switch (
                                                                      cellIndex
                                                                    ) {
                                                                      case 3: // Reader 1
                                                                        return props.history.push(
                                                                          `/project/${props.match.params.project_id}/task/${row.reader_1_task_id}`
                                                                        )
                                                                      case 4: // Reader 2
                                                                        return props.history.push(
                                                                          `/project/${props.match.params.project_id}/task/${row.reader_2_task_id}`
                                                                        )
                                                                      case 5: // Adjudicator
                                                                        return props.history.push(
                                                                          `/project/${props.match.params.project_id}/adjudicate/${row.diagnosis_adjudicator_task_id}`
                                                                        )
                                                                    }
                                                                  }}
                                                                >
                                                                  View Read
                                                                </MenuItem>
                                                              )}
                                                              {value.includes(
                                                                "open"
                                                              ) &&
                                                                handleAssignedUserEmails(
                                                                  cellIndex,
                                                                  rowIndex
                                                                ) != null && (
                                                                  <MenuItem
                                                                    id={
                                                                      "menu_item_info.row-" +
                                                                      rowIndex +
                                                                      "-cell-" +
                                                                      cellIndex
                                                                    }
                                                                    onClick={() =>
                                                                      setUnassignUserDispatch(
                                                                        handleAssignedUserEmails(
                                                                          cellIndex,
                                                                          rowIndex
                                                                        ),
                                                                        cellIndex,
                                                                        rowIndex
                                                                      )
                                                                    }
                                                                  >
                                                                    Unassign
                                                                  </MenuItem>
                                                                )}
                                                            </div>
                                                          }
                                                          cellDetails={{
                                                            rowIndex,
                                                            cellIndex,
                                                          }}
                                                        />
                                                      )}
                                                  </div>
                                                )}
                                              </div>
                                            </MyTableCell>
                                          )
                                        }
                                      } else {
                                        return (
                                          <TableCell
                                            key={cellIndex}
                                            align={column.align}
                                          >
                                            {column.format &&
                                            typeof value === "number"
                                              ? column.format(value)
                                              : value}
                                          </TableCell>
                                        )
                                      }
                                    }
                                  )}
                                </TableRow>
                              )
                            }
                          )}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <TablePagination
                      rowsPerPageOptions={[10, 25, 100]}
                      component="div"
                      count={state.CentralReview.totalResult}
                      rowsPerPage={queryData.limit}
                      page={pageTable}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      backIconButtonProps={
                        isLoading.loading
                          ? {
                              disabled: isLoading.loading,
                            }
                          : undefined
                      }
                      nextIconButtonProps={
                        isLoading.loading
                          ? {
                              disabled: isLoading.loading,
                            }
                          : undefined
                      }
                    />
                  </Paper>
                )}
              </Grid>
              <Grid item xs={12} className="icon-description-container">
                <h2 className="title">Icon description</h2>
                <Card>
                  <CardContent
                    className="icon-card"
                    style={{ paddingBottom: "16px" }}
                  >
                    <Grid container className="icon-description-container-row">
                      {DataStatus.map((status, index) => {
                        return (
                          <div
                            key={index}
                            className="icon-description-container-cell"
                          >
                            <img
                              alt={status.name}
                              width={25}
                              src={setImgurl(status.value)}
                              className="icon-img"
                            />
                            <p>{status.name}</p>
                          </div>
                        )
                      })}
                    </Grid>
                  </CardContent>
                </Card>
              </Grid>
            </>
          ) : (
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              container
              justifyContent={"center"}
              alignContent={"center"}
            >
              {state.CentralReview.isEmpty ? (
                <p>No task on this result</p>
              ) : (
                <p>Loading</p>
              )}
            </Grid>
          )}
        </Grid>
      )}
      {visibleAddUserModal && (
        <AddUserModal
          listOfUsers={clickedRow.unassigned_readers}
          open={visibleAddUserModal}
          onClose={() => setVisibleAddUserModal(false)}
          onSubmitAssignModal={(user: any) => onSubmitModal(user)}
          title={title}
          submitButton={submitButton}
        />
      )}
      <Dialog
        open={openDialog}
        onClose={() => openDialogSet(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Please Confirm!"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {comesFromAdj
              ? "Do you want to set adjudication to not needed?"
              : "Do you want to unassign this user?"}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => openDialogSet(false)}
            style={{ color: "#279181" }}
          >
            Cancel
          </Button>
          <Button
            onClick={() =>
              comesFromAdj
                ? handleNotNeededAdjDispatch()
                : handleUnassignUserDispatch()
            }
            style={{ color: "#279181" }}
            autoFocus
          >
            Agree
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CentralReviewProgressMonitoring
