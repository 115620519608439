import "./table-layout.sass"

import * as React from "react"

import { Table, TableContainer, TablePagination } from "@material-ui/core"

import TableBodyComponent from "./body/table-body-component"
import TableHeaderComponent from "./header/table-header-component"
import { useState } from "react"

const TableLayout = (props: any) => {
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [page, setPage] = useState(0)

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(+event.target.value)
    setPage(0)
  }

  return (
    <div className="table-layout-container">
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHeaderComponent columns={props.columns} />
          <TableBodyComponent
            data={props.data}
            match={props.match}
            columns={props.columns}
            page={page}
            rowsPerPage={rowsPerPage}
            rowLink={props.rowLink}
          />
        </Table>
      </TableContainer>
      {props.pagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          component="div"
          count={props.data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={(e, newPage) => setPage(newPage)}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}

export default TableLayout
