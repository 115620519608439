import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from "../constants/constants"

import { AnyAction } from "redux"

const initialState: any = {
  notifications: [],
}

interface IInitialState {
  notifications: any[]
}

export default function (
  state: IInitialState = initialState,
  action: AnyAction
) {
  switch (action.type) {
    case ENQUEUE_SNACKBAR:
      return {
        ...state,
        notifications: [
          ...state.notifications,
          {
            ...action.notification,
          },
        ],
      }

    case REMOVE_SNACKBAR:
      return {
        ...state,
        notifications: state.notifications.filter(
          (notification) => notification.key !== action.key
        ),
      }

    default:
      return state
  }
}
