import "./loader-container.sass"

import * as React from "react"

import lottie from "lottie-web"

const LoaderContainer = ({ isloading, lottieSVG, children }: any) => {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#loader-container"),
      animationData: lottieSVG,
      loop: true,
      autoplay: true,
    })
  }, [])

  return (
    <>
      <div className={isloading ? "loadercontainer" : "loadercontainer-hide"}>
        <div id="loader-container" />
      </div>
      <div
        className={isloading ? "children-container-hide" : "children-container"}
      >
        {children}
      </div>
    </>
  )
}

export default LoaderContainer
