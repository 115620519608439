import * as React from "react"

import { connect } from "react-redux"
import { hide } from "../../actions/notificationsActions"
import { withSnackbar } from "notistack"

class Notifier extends React.Component<any, any> {
  displayed: any = []

  storeDisplayed = (id: any) => {
    this.displayed = [...this.displayed, id]
  }

  shouldComponentUpdate({ notifications: newSnacks = [] }: any) {
    const { notifications: currentSnacks } = this.props
    let notExists = false
    for (let i = 0; i < newSnacks.length; i++) {
      if (notExists) continue
      notExists =
        notExists ||
        !currentSnacks.filter(({ key }: any) => newSnacks[i].key === key).length
    }
    return true
  }

  componentDidUpdate() {
    const { notifications = [] }: any = this.props

    notifications.forEach((notification: any) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(notification.key)) return
      // Display snackbar using notistack
      this.props.enqueueSnackbar(notification.message, notification.options)
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(notification.key)
      // Dispatch action to remove snackbar from redux store
      this.props.removeSnackbar(notification.key)
    })
  }

  render() {
    return <></>
  }
}

const mapStateToProps = ({ notifications }: any) => ({
  notifications: notifications.notifications,
})

const mapDispatchToProps = (dispatch: any) => ({
  removeSnackbar: (key: any) => dispatch(hide(key)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withSnackbar(Notifier))
