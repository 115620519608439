import {
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROJECT_FETCHING,
} from "../constants/constants"

import { AnyAction } from "redux"
import { ICurrent } from "../utils/interfaces"

const CURRENT_INITIAL_STATE: ICurrent = {
  project: {
    progress_monitoring_table: {
      header: [],
    },
    data: {
      abbreviation: "",
      analysis_credits: null,
      description: "No description",
      config: {
        data_location: "",
        time_created: {
          $date: 1551398400000,
        },
      },
      free_credits: null,
      has_analysis_credits: null,
      message: "",
      name: "",
      owner_name: "",
      project_type: "",
      published: 0,
      roles: [],
      state: "",
      success: null,
      sites: [],
      tags: [],
      user_id: "",
      _id: null,
      isManager: false,
    },
    isFetching: false,
  },
}

export default function (state = CURRENT_INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SET_CURRENT_PROJECT:
      return {
        ...state,
        project: {
          ...state.project,
          progress_monitoring_table: {
            ...state.project.progress_monitoring_table,
            header: action.header,
          },
          data: {
            ...action.payload,
          },
          isFetching: false,
          isManager: action.isManager,
        },
      }

    case SET_CURRENT_PROJECT_FETCHING:
      return {
        ...state,
        project: {
          ...state.project,
          isFetching: true,
        },
      }
    default:
      return state
  }
}
