import {
  applyMiddleware,
  compose,
  legacy_createStore as createStore,
} from "redux"

import ApiMiddleware from "./middlewares/ApiMiddleware"
import NotificationsMiddleware from "./middlewares/notificationMiddleware"
import createMainReducer from "./reducers/mainReducer"
import thunk from "redux-thunk"

export const store = () =>
  (createStore as any)(
    createMainReducer(),
    compose(applyMiddleware(thunk, ApiMiddleware(), NotificationsMiddleware()))
  )

export default store
