import "./index.sass"

import * as React from "react"
import * as ReactDOM from "react-dom"

import { Route, Router } from "react-router-dom"

import AuthorizationApp from "./components/AuthorizationApp"
import { Provider } from "react-redux"
import { SnackbarProvider } from "notistack"
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk"
import configureStore from "./store"
import history from "./utils/history"
;(async () => {
  const appStore = await configureStore()
  const LDProvider = await asyncWithLDProvider({
    clientSideID: process.env.REACT_APP_LD_CLIENT_ID || "",
  })

  ReactDOM.render(
    <React.Fragment>
      <Router history={history}>
        <LDProvider>
          <Provider store={appStore}>
            <SnackbarProvider maxSnack={3}>
              <Route component={AuthorizationApp} />
            </SnackbarProvider>
          </Provider>
        </LDProvider>
      </Router>
    </React.Fragment>,
    document.getElementById("root")
  )
})()
