import "./study-card.sass"

import * as React from "react"

import { useEffect, useState } from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import Tooltip from "@material-ui/core/Tooltip"
import { useSelector } from "react-redux"

const toolTipText =
  "Quality control (QC) is a standard which is used to conduct a study determined by research requirements"

const StudyQcCard = () => {
  const [project, setProject] = useState<any>({})
  const state = useSelector((state: any) => state)

  useEffect(() => {
    setProject(createQcData(state.current.project.data))
  }, [state.current.project.data])

  const createQcData = (project: any) => {
    const qcData = project.sites.reduce(
      (acc: any, current: any) => {
        if (current.uploaded_data) {
          acc.pass += current.uploaded_data.pass
          acc.fail += current.uploaded_data.fail
          acc.no_qc += current.uploaded_data.no_qc || 0
        }
        acc.total += current.target || 0
        return acc
      },
      { pass: 0, no_qc: 0, fail: 0, total: 0 }
    )
    return qcData
  }

  return (
    <Card className="card">
      <CardContent>
        <h3 className="weight-medium">
          Sessions by QC status&nbsp;
          {/* tooltip placement doesn't work the same way as the docs described
              bottom-start and bottom-end seem to be reversed
              https://material-ui.com/components/tooltips/#positioned-tooltips
          */}
          <Tooltip title={toolTipText} placement="bottom-start">
            <i className="material-icons sm-icon">help</i>
          </Tooltip>
        </h3>

        <div>
          <div className="info-container">
            <p className="key-column">Accepted</p>
            <div className="pass">
              <span id="study_qc_card.qc_pass">{project.pass}</span>
            </div>
          </div>

          <div className="info-container">
            <p className="key-column">Pending</p>
            <p className="no_qc">
              <span id="study_qc_card.no_qc">{project.no_qc}</span>
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Rejected</p>
            <p className="error">
              <span id="study_qc_card.qc_fail">{project.fail}</span>
            </p>
          </div>

          <div className="info-container">
            <p className="key-column key-total">Total projected</p>
            <p className="total">
              <span id="study_qc_card.qc_fail">{project.total}</span>
            </p>
          </div>
        </div>

        <div className="button-right-container">
          {/* <Link to={"/"}> */}
          <button
            id="study_activity_card.view_activity_log_button"
            className="ghost-btn"
            // onClick={() => this.props.history.push("data")}
          >
            <i className="material-icons">visibility</i>
            View sessions
          </button>
          {/* </Link> */}
        </div>
      </CardContent>
    </Card>
  )
}

export default StudyQcCard
