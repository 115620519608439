import { API_CALL } from "../constants/constants"

export const callAPI = (
  url: any,
  method = "GET",
  postData: any = null,
  headers = {}
) => ({
  type: API_CALL,
  url,
  method,
  postData,
  headers,
})
