import "./study-card.sass"
import {} from "react"

import * as React from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"

const StudyActivityCard = (props: any) => {
  const state = useSelector((state: any) => state)

  return (
    <Card className="card">
      <CardContent>
        <div>
          <h3>Study activity log</h3>
        </div>

        <div>
          <div className="info-container">
            <p className="key-column">Activities completed</p>
            <p id="study_info_card.abreviation">
              {state.activityTable.totalActivity
                ? state.activityTable.totalActivity
                : "searching..."}
            </p>
          </div>
        </div>

        <div>
          <div className="button-right-container">
            <Link to={"activity"}>
              <button className="ghost-btn">
                <i className="material-icons clickable">edit</i>
                View activity
              </button>
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}

export default StudyActivityCard
