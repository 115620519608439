import "./user-modal.sass"

import * as React from "react"

import { useDispatch, useSelector } from "react-redux"

import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Grid } from "@material-ui/core"
import LoaderPoints from "../../../components/common/loader-points/loader-points"
import history from "./../../../utils/history"
import { verifyPassword } from "../../../actions/CentralReviewAction"

const SubmitModal = (props: any) => {
  const state = useSelector((state: any) => state)
  const dispatch = useDispatch()
  const handleSubmit = (event: any) => {
    event.preventDefault()
    const data = new FormData(event.target)
    dispatch(verifyPassword({ password: data.get("password") }, props, history))
  }

  return (
    <Dialog open={props.open} aria-labelledby="responsive-dialog-title">
      <DialogTitle id="responsive-dialog-title">
        {props.title}
        <LoaderPoints isloading={state.CentralReview.isFetching} />
      </DialogTitle>
      <DialogContent>
        <p>The current Project is configured to use e-signature</p>
        <p>
          Please confirm this action with the username and password of your
          QMENTA account
        </p>
        <Grid container className="submit-container">
          <Grid item xs={6} sm={6} md={6}>
            <h4>Subject ID</h4>
            <h3>
              <b>{props.subjectId}</b>
            </h3>
          </Grid>
          <Grid item xs={6} sm={6} md={6}>
            <h4>Session ID</h4>
            <h3>
              <b>{props.sessionId}</b>
            </h3>
          </Grid>
        </Grid>
        <form
          id="very_password"
          className="submit-signature"
          onSubmit={(e) => handleSubmit(e)}
        >
          <div className="field column">
            <label>Email or Username</label>
            <div>
              <input
                placeholder={"Email or username"}
                type={"text"}
                className={"input"}
                value={state.session.user_data.username}
                disabled
              />
            </div>
          </div>
          <div className="field column">
            <label>Password</label>
            <div>
              <input
                placeholder={"Password"}
                name={"password"}
                type={"password"}
                className={"input"}
              />
            </div>
          </div>
        </form>
      </DialogContent>
      <DialogActions className="buttons-container">
        <button
          id="delete-modal-cancel"
          onClick={props.onClose}
          color="primary"
          className={
            props.disabled ? "btn quaternary inactive" : "btn quaternary"
          }
          disabled={props.disabled ? props.disabled : undefined}
        >
          Cancel
        </button>
        <button
          form="very_password"
          type="submit"
          color="primary"
          className={props.disabled ? "btn denial inactive" : "btn primary"}
          disabled={props.disabled ? props.disabled : undefined}
        >
          {props.submitButton}
        </button>
      </DialogActions>
    </Dialog>
  )
}

export default SubmitModal
