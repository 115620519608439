import "../../create-edit-study/create-edit-study.sass"

import * as React from "react"

import { Grid } from "@material-ui/core"

const CustomTextArea = (props: any) => {
  return (
    <Grid container className={props.classname}>
      <Grid item xs={12} sm={12} md={12}>
        <label>{props.label}</label>
        <textarea
          className={"textarea"}
          name={props.name}
          autoComplete="off"
          placeholder={props.placeholder}
          value={props.formik.values[props.name]}
          onChange={props.onChange}
        />
      </Grid>
    </Grid>
  )
}

export default CustomTextArea
