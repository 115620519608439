import "./expand-menu-button.sass"

import * as React from "react"

import Button from "@material-ui/core/Button"
import Menu from "@material-ui/core/Menu"
import dotsIcons from "../../../assets/icons/dots.png"
import { useState } from "react"

interface IProps {
  centralReadingTable: boolean
  row: any
  isForView: boolean
  children: React.ReactNode
  cellDetails: any
}

const ExpandMenuButton = (props: IProps) => {
  const [anchorEl, setAnchorEl] = useState<any>(null)

  return (
    <React.Fragment>
      <Button
        aria-owns={anchorEl ? "simple-menu" : undefined}
        className="buttonMenu"
        aria-haspopup="true"
        onClick={(event) => setAnchorEl(event.currentTarget)}
        id={"more-button--" + props.row.name}
      >
        {props.centralReadingTable ? (
          <img alt="dotImage" className="dot-image" src={dotsIcons} />
        ) : (
          <p>...</p>
        )}
      </Button>

      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
        PaperProps={{ id: "action-menu-list-div" }}
        BackdropProps={{ id: "action-menu-backdrop", invisible: true }}
        children={props.isForView && props.children}
      />
    </React.Fragment>
  )
}

export default ExpandMenuButton
