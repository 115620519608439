import "./AuthorizationApp.sass"

import * as React from "react"

import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom"
import { getMe, getProjectModuleConfig } from "../actions/apiAction"

import CreateEditStudy from "./forms/create-edit-study"
import NotFoundPage from "./common/notFound"
import Notifier from "../components/notifier"
import ProjectList from "../containers/dashboard/project-list-page"
import ScrollTopTop from "../components/scroll-to-top/scrollToTop"
import StudyContainer from "../containers/dashboard/study-container"
import WorkListViewer from "../containers/dashboard/work-list-viewer"
import { useDispatch } from "react-redux"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useLDFlags } from "../utils/useLDFlags"

const AuthorizationApp = () => {
  const dispatch = useDispatch()
  const flags = useFlags()

  React.useEffect(() => {
    useLDFlags.setItem("flags", flags).then(() => {
      Promise.resolve(dispatch(getMe())).then((res: any) => {
        if (res.payload?.success !== 0) {
          dispatch(getProjectModuleConfig())
        }
      })
    })
  }, [])

  return (
    <div id="main" className="grey-background">
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Notifier />
        <ScrollTopTop />
        <Switch>
          <Route path="/" exact render={() => <Redirect to={"/project"} />} />
          <Route
            path={"/project"}
            exact={true}
            render={(props: any) => <ProjectList {...props} />}
          />
          <Route
            path={"/project/create"}
            render={(props: any) => (
              <CreateEditStudy
                create={true}
                title={"Create Study"}
                {...props}
              />
            )}
          />
          <Route
            path={"/project/:project_id/task/:task_id"}
            exact
            render={(props) => <WorkListViewer {...props} />}
          />
          <Route
            path={"/project/:project_id"}
            render={(props) => <StudyContainer {...props} />}
          />

          <Route component={NotFoundPage} />
        </Switch>
      </BrowserRouter>
    </div>
  )
}

export default AuthorizationApp
