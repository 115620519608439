import { AnyAction } from "redux"
import { ISiteTable } from "../utils/interfaces"
import { SET_SITES } from "../constants/constants"

const SITES_INITIAL_STATE: ISiteTable = {
  data: [],
  total: 0,
}

export default function (state = SITES_INITIAL_STATE, action: AnyAction) {
  switch (action.type) {
    case SET_SITES:
      return {
        ...state,
        data: action.sites,
        total: action.sites.length,
      }
    default:
      return state
  }
}
