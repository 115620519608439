import {
  CLEAR_CURRENT_MEASUREMENT,
  CLEAR_CURRENT_TASK_FORM,
  FETCHING_WORKLIST,
  GET_CENTRAL_READING_PROGRESS,
  GET_CURRENT_ADJ_TASK_ENDPOINT,
  GET_CURRENT_TASK,
  GET_CURRENT_TASK_ENDPOINT,
  GET_WORKLIST,
  SET_ADJUDICATOR,
  SET_CURRENT_LINE_MEASUREMENT,
  SET_MATRIX_DATA,
  SET_READER_1,
  SET_READER_2,
} from "../constants/constants"

import { DATE_FORMAT } from "../constants/constants"
import { IError } from "../utils/interfaces"
import { callAPI } from "../middlewares/call-api"
import { format } from "date-fns"
import { success } from "../actions/notificationsActions"

export const getWorklist = (data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: FETCHING_WORKLIST,
      isFetching: true,
    })
    return dispatch(
      callAPI(`/central_reading_manager/get_worklist`, "GET", data)
    )
      .then((response: any) => {
        const variable = response.map((el: any) => {
          const task_type = String(el.task_type).startsWith("reading_")
            ? "Read"
            : "Adjudicate"

          return {
            id: el._id || "-",
            container_id: el.container_id || "-",
            patient_secret_name: el.patient_secret_name || "-",
            project_id: el.projectset_id || "-",
            session_id: el.ssid || "-",
            status: el.status || "-",
            task_type: task_type || "-",
            site: el.site || "-",
            time_created: el.time_created
              ? format(el.time_created.$date, `${DATE_FORMAT}`)
              : "-",
            timestamp: el.time_modified
              ? format(el.time_modified.$date, `${DATE_FORMAT}`)
              : "-",
            age_at_scan: el.age_at_scan || "-",
            viewoption: el.viewoption || "true",
          }
        })

        // TO-DO fix when https://qmenta.atlassian.net/browse/EN-1549 is done

        dispatch({
          type: GET_WORKLIST,
          payload: variable,
          isFetching: false,
          totalResult: variable.length,
          isEmpty: variable.length ? false : true,
        })
      })
      .catch((error: IError) => error)
  }
}

export const getCentralReadingProgress = (data: any) => {
  // added new field to avoid axios cache
  // TO-DO: evaluate how to include no-cache in some requests
  data["now"] = Date.now()
  return (dispatch: any) => {
    return dispatch(
      callAPI(
        "/central_reading_manager/get_project_manager_overview",
        "GET",
        data
      )
    )
      .then((res: any[]) => {
        dispatch({
          type: GET_CENTRAL_READING_PROGRESS,
          payload: res,
          isFetching: false,
          isEmpty: false,
        })
      })
      .catch((err: IError) => err)
  }
}

export const setAssignUser = (values: any, data: any) => {
  return (dispatch: any) => {
    return dispatch(
      callAPI("/central_reading_manager/assign_reader", "POST", values)
    )
      .then(() => dispatch(getCentralReadingProgress(data)))
      .catch((error: IError) => {
        dispatch(getCentralReadingProgress(data))
        throw error
      })
  }
}

export const setUnassignUser = (values: any, data: any) => {
  return (dispatch: any) => {
    return dispatch(
      callAPI("/central_reading_manager/unassign_reader", "POST", values)
    )
      .then(() => dispatch(getCentralReadingProgress(data)))
      .catch((error: IError) => {
        dispatch(getCentralReadingProgress(data))
        throw error
      })
  }
}

export const setNotNeededAdjudicator = (values: any) => {
  return (dispatch: any) => {
    return dispatch(
      callAPI("/central_reading_manager/assign_not_needed", "POST", values)
    ).catch((error: IError) => error)
  }
}

export const setReader_1 = (val: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_READER_1,
      payload: val,
    })
  }
}

export const setReader_2 = (val: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_READER_2,
      payload: val,
    })
  }
}

export const setAdjudicator = (val: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_ADJUDICATOR,
      payload: val,
    })
  }
}

export const clearCurrentTaskForm = () => {
  return (dispatch: any) => {
    dispatch({
      type: CLEAR_CURRENT_TASK_FORM,
    })
  }
}

export const clearCurrentMeasurement = () => {
  return (dispatch: any) => {
    dispatch({
      type: CLEAR_CURRENT_MEASUREMENT,
    })
  }
}

export const getCurrentTaskEndpoint = (params: any) => {
  return (dispatch: any) => {
    dispatch(
      callAPI(
        `/central_reading_manager/load_reader_task?task_id=${params.task_id}&projectset_id=${params.project_id}`,
        "GET"
      )
    )
      .then((res: any) => {
        //NOT SUPPORT MULTIPLE ANNOTATIONS
        dispatch({
          type: GET_CURRENT_TASK_ENDPOINT,
          payload: res,
          isFetching: false,
        })
      })
      .catch((error: IError) => error)
  }
}

export const getCurrentAdjTask = (task: any, params: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_CURRENT_TASK,
      payload: task,
    })
    dispatch(getCurrentAdjTaskEndpoint(params))
  }
}

export const getCurrentAdjTaskEndpoint = (params: any) => {
  return (dispatch: any) => {
    dispatch(
      callAPI(
        `/central_reading_manager/load_adjudicator_task?task_id=${params.task_id}&projectset_id=${params.project_id}`,
        "GET"
      )
    ).then((res: any) => {
      dispatch({
        type: GET_CURRENT_ADJ_TASK_ENDPOINT,
        payload: res,
      })
    })
  }
}

export const submitAdjudication = (props: any, formData: any) => {
  return (dispatch: any) => {
    dispatch(
      callAPI("/central_reading_manager/submit_adjudicator", "POST", formData)
    )
      .then(() => {
        props.history.goBack()
        dispatch(success("Form was submitted successfully!"))
      })
      .catch((error: IError) => error)
  }
}

export const submitTask = (props: any, formData: any) => {
  return (dispatch: any) => {
    dispatch(
      callAPI("/central_reading_manager/submit_reader_task", "POST", formData)
    )
      .then(() => {
        props.history.goBack()
        dispatch(success("Form was submitted successfully!"))
      })
      .catch((error: IError) => {
        throw error
      })
  }
}

export const setCurrentLineMeasurement = (measurement: any) => {
  return (dispatch: any) => {
    dispatch({
      type: SET_CURRENT_LINE_MEASUREMENT,
      payload: measurement,
    })
  }
}

export const verifyPassword = (data: any, props: any, history: any) => {
  return (dispatch: any) => {
    dispatch(callAPI("/a_user_manager/verify_password", "POST", data))
      .then((response: any) => {
        if (response.success) {
          Promise.all([dispatch(submitTask(props, props.formData))])
            .then(() => {
              history.goBack()
              dispatch(success("Form was submitted successfully!"))
            })
            .catch((error) => {
              throw error
            })
        }
      })
      .catch((error: IError) => {
        throw error
      })
  }
}

export const getHistory = (data: any) => {
  return (dispatch: any) => {
    dispatch(callAPI("/central_reading_manager/get_history", "GET", data))
      .then((response: any) => {
        dispatch({
          type: SET_MATRIX_DATA,
          payload: response,
          isFetching: false,
          isEmpty: false,
        })
      })
      .catch((error: IError) => {
        throw error
      })
  }
}
