import "../../create-edit-study/create-edit-study.sass"

import * as React from "react"

import { Grid, MenuItem } from "@material-ui/core"

import Select from "@material-ui/core/Select"

const CustomSelect = (props: any) => {
  return (
    <Grid container className={props.classname}>
      <Grid item xs={12} sm={12} md={12}>
        <label>{props.label}</label>
        <Select
          id={props.id}
          name={props.name}
          defaultValue={props.defaultValue}
          value={props.formik.values[props.name]}
          style={{ width: "80%", height: "40px", background: "#fff" }}
          onChange={props.formik.handleChange}
        >
          {props.options.map((value: any, index: any) => {
            return (
              <MenuItem key={index} value={`${value.value}`}>
                {value.label}
              </MenuItem>
            )
          })}
        </Select>
        {((props.formik.touched[props.name] &&
          props.formik.errors[props.name]) ||
          !props.formik.isSubmitting) && (
          <p style={{ color: "#9B1A06", fontSize: "14px" }}>
            {props.formik.errors[props.name]}
          </p>
        )}
        <div>
          <small className="description-text">{props.advice}</small>
        </div>
      </Grid>
    </Grid>
  )
}

export default CustomSelect
