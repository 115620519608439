import "date-fns"
import "./create-edit-study.sass"

import * as React from "react"
import * as Yup from "yup"

import { endOfDay, format } from "date-fns"
import { reduxForm, reset } from "redux-form"
import { useDispatch, useSelector } from "react-redux"

import CustomInput from "../custom-fields/input"
import CustomRadioButton from "../custom-fields/radio-button"
import CustomSelect from "../custom-fields/select"
import CustomSwitch from "../custom-fields/switch"
import CustomTextArea from "../custom-fields/textarea"
import { DATE_FORMAT } from "../../../constants/constants"
import DashboardSidePanel from "../../../components/common/sidepanel/DashboardSidePanel"
import { Grid } from "@material-ui/core"
import { Link } from "react-router-dom"
import LoaderContainer from "../../common/loader-container/loader-container"
import LoaderPoints from "../../../components/common/loader-points/loader-points"
import Tooltip from "@material-ui/core/Tooltip"
import brainThinking from "../../../assets/lotties/brain-lottie"
import { compose } from "recompose"
import { createNewProject } from "../../../actions/apiAction"
import { getFormTypes } from "../../../actions/apiAction"
import { toolTipText } from "../../../constants/constants"
import { useEffect } from "react"
import { useFormik } from "formik"
import { warning } from "../../../actions/notificationsActions"

const CreateStudyForm = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state)

  useEffect(() => {
    dispatch(getFormTypes())
  }, [])

  useEffect(() => {
    dispatch(reset("createStudyForm"))
    const formInitial = {
      data_location: "us",
      age_measure: { value: "month", label: "Months" },
      status: { value: "setup", label: "In Set Up" },
      form_id: "Recist form v1",
      name: "",
      description: "",
      owner: state.session.user_data.name,
      creation_date: format(endOfDay(new Date()), `${DATE_FORMAT}`),
      num_readers: 0,
      num_diagnosis_adjudicators: 0,
      number_expected_tm_per_subject: 0,
      password_verify: false,
    }
    props.initialize(formInitial)
  }, [])

  const formik = useFormik({
    initialValues: {
      name: "",
      data_location: "us",
      form_id: "Recist form v1",
      age_measure: "month",
      num_readers: "",
      num_diagnosis_adjudicators: "",
      number_expected_tm_per_subject: "",
      password_verify: false,
    },
    validationSchema: Yup.object({
      name: Yup.string()
        .max(50, "Must be 50 characters or less!")
        .required("Required!")
        .matches(
          /^[aA-zZ0-9\s]+$/,
          "Only letters and numbers are allowed for this field !"
        ),
      num_readers: Yup.string()
        .max(2, "Not allowed more than 99")
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      num_diagnosis_adjudicators: Yup.string()
        .max(2, "Not allowed more than 99")
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
      number_expected_tm_per_subject: Yup.string()
        .max(2, "Not allowed more than 99")
        .required("Required!")
        .matches(
          /^[0-9]*$/,
          "Only positive integers are allowed for this field "
        ),
    }),
    onSubmit: (values) => onSubmit(values),
    validateOnChange: true,
  })

  const onSubmit = (values: any) => {
    const params = {
      ...values,
      data_location: values.data_location,
      form_id: values.form_id,
      name: values.name,
      status: values.status,
      age_measure: values.age_measure,
      description: values.description,
      num_readers: values.num_readers,
      num_diagnosis_adjudicators: values.num_diagnosis_adjudicators,
      number_expected_tm_per_subject: values.number_expected_tm_per_subject,
      password_verify: values.password_verify === false ? 0 : 1,
    }

    const max_num_diagnosis_adjudicators = 1
    if (
      parseInt(params.num_diagnosis_adjudicators) >
      max_num_diagnosis_adjudicators
    ) {
      dispatch(
        warning(
          `The number of adjudicators must be between 0 and ${max_num_diagnosis_adjudicators}!`
        )
      )
    } else {
      if (!Object.keys(formik.errors).length) {
        dispatch(createNewProject(params, props.history))
      }
    }
  }

  return (
    <Grid container>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <DashboardSidePanel {...props} />
      </Grid>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <div className="container-form">
          <h1>
            {props.title}
            <LoaderPoints isloading={state.project_config.isFetching} />
          </h1>
          <LoaderContainer
            isloading={state.project_config.isFetching}
            lottieSVG={brainThinking}
          >
            <form onSubmit={formik.handleSubmit}>
              <Grid container className="form-container-create-edit">
                <CustomInput
                  label={"Study Name"}
                  name={"name"}
                  placeholder={"Study Name e.g 'My Test Study'"}
                  type={"text"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />
                {state.project_config.data.data_locations[0].value && (
                  <CustomSelect
                    label={"Data Location *"}
                    name={"data_location"}
                    onChange={formik.handleChange}
                    classname={"form-item"}
                    formik={formik}
                    defaultValue={
                      state.project_config.data.data_locations[0].value
                    }
                    options={state.project_config.data.data_locations}
                    advice={` The generic study's data location won't be changable after
                    completing this step`}
                  />
                )}

                {state.project_config.formTypes[0]?.value && (
                  <CustomSelect
                    label={"Form Type *"}
                    name={"form_id"}
                    onChange={formik.handleChange}
                    classname={"form-item"}
                    formik={formik}
                    defaultValue={state.project_config.formTypes[0]?.value}
                    options={state.project_config.formTypes}
                  />
                )}

                <CustomRadioButton
                  label={"Age units *"}
                  name="age_measure"
                  defaultValue="month"
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />

                <CustomTextArea
                  label={"Description"}
                  name="description"
                  placeholder={"Desciption of the study (max. 240 characters)"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />

                <CustomInput
                  label={"Number of readers *"}
                  name="num_readers"
                  placeholder={""}
                  type={"number"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />

                <CustomInput
                  label={"Number of diagnosis adjudicators *"}
                  name="num_diagnosis_adjudicators"
                  placeholder={""}
                  type={"number"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />

                <CustomInput
                  label={"Number of expected timepoints per subject *"}
                  name="number_expected_tm_per_subject"
                  placeholder={""}
                  type={"number"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />

                <CustomSwitch
                  label={"Task signature"}
                  name="password_verify"
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                />
              </Grid>

              <Grid container className="buttons-container">
                <Link to={"/"}>
                  <button className={"btn quaternary"}>Cancel</button>
                </Link>

                {Object.keys(formik.errors).length ? (
                  <Tooltip title={toolTipText} placement="bottom-start">
                    <button
                      className="btn inactive denial"
                      type="submit"
                      disabled={props.submitting}
                    >
                      Create Study
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    className="btn primary"
                    type="submit"
                    disabled={props.submitting}
                  >
                    Create Study
                  </button>
                )}
              </Grid>
            </form>
          </LoaderContainer>
        </div>
      </Grid>
    </Grid>
  )
}

export default compose(
  reduxForm({
    form: "createStudyForm",
  })
)(CreateStudyForm)
