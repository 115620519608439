import * as React from "react"

import { Link } from "react-router-dom"

const Tab = (props: any) => {
  return (
    <>
      {props.elements.map((el: any, idx: any) => {
        return (
          <li
            className={`tab-link ${
              el.url && props.history.location.pathname.includes(el.url)
                ? "is-active"
                : ""
            }`}
            key={`tab ${el.name}`}
          >
            {el.externalURL ? (
              <a target="_blank" rel="noopener noreferrer" href={el.url}>
                {el.name}
                <i className="material-icons sm-icon">open_in_new</i>
              </a>
            ) : (
              <Link to={el.url}>{el.name}</Link>
            )}
          </li>
        )
      })}
    </>
  )
}

export default Tab
