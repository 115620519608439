import "../../create-edit-study/create-edit-study.sass"

import * as React from "react"

import FormControlLabel from "@material-ui/core/FormControlLabel"
import { Grid } from "@material-ui/core"
import Radio from "@material-ui/core/Radio"
import RadioGroup from "@material-ui/core/RadioGroup"

const CustomRadioButton = (props: any) => {
  return (
    <Grid container className={props.classname}>
      <Grid item xs={12} sm={12} md={12}>
        <label>{props.label}</label>
        <RadioGroup
          aria-label="age unit"
          name={props.name}
          value={props.formik.values[props.name]}
          defaultValue={props.defaultValue}
          onChange={props.handleChange}
          row
        >
          <FormControlLabel
            value="month"
            control={<Radio color="default" />}
            label="Months"
          />

          <FormControlLabel
            value="year"
            control={<Radio color="default" />}
            label="Years"
          />
        </RadioGroup>
      </Grid>
    </Grid>
  )
}

export default CustomRadioButton
