import {
  DATE_HOUR_MINUTE_FORMAT,
  RECEIVE_ACTIVITY_DATA,
} from "../constants/constants"

import { AnyAction } from "redux"
import { IActivityTable } from "../utils/interfaces"
import { format } from "date-fns"

const initialState: IActivityTable = {
  data: [],
  isFetching: true,
  totalActivity: 0,
}

const formatDate = (date: any) => {
  return format(date, `${DATE_HOUR_MINUTE_FORMAT}`)
}
export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case RECEIVE_ACTIVITY_DATA:
      const data = action.payload.map((value: any) => {
        return {
          user_name: value.user_name,
          timestamp: formatDate(value.timestamp["$date"]),
          name: value.name,
        }
      })

      return {
        ...state,
        data: data,
        isFetching: false,
        totalActivity: action.total,
      }

    default:
      return {
        ...state,
      }
  }
}
