import * as React from "react"
import * as Yup from "yup"

import { IS_FETCHING, toolTipText } from "../../../constants/constants"
import { addNewCollaborator, getRoles } from "../../../actions/apiAction"
import { useDispatch, useSelector } from "react-redux"

import CustomInput from "../../forms/custom-fields/input"
import CustomSelect from "../../forms/custom-fields/select"
import Fetching from "../../../components/common/fetching-table/fetching-table"
import { Grid } from "@material-ui/core"
import { Link } from "react-router-dom"
import LoaderPoints from "../../../components/common/loader-points/loader-points"
import Tooltip from "@material-ui/core/Tooltip"
import { compose } from "recompose"
import { reduxForm } from "redux-form"
import { useEffect } from "react"
import { useFormik } from "formik"

const CollaboratorsAdd = (props: any) => {
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state)

  useEffect(() => {
    dispatch({
      type: IS_FETCHING,
    })
    if (state.current.project.data.project_type) {
      dispatch(getRoles(state.current.project.data.project_type))
    }
  }, [state.current.project.data.project_type])

  const formik = useFormik({
    initialValues: {
      email: "",
      imagining_site: "us",
      user_role: state.project_config.roles[0]?.value,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email format")
        .required("Mail is required"),
    }),
    onSubmit: (values) => onSubmit(values),
    validateOnChange: true,
  })

  const onSubmit = (values: any) => {
    const params = {
      ...values,
      email: values.email,
      imagining_site: values.imagining_site,
      user_role: values.user_role || state.project_config.roles[0]?.value,
    }

    if (!Object.keys(formik.errors).length) {
      dispatch(
        addNewCollaborator(params, props.history, props.match.params.project_id)
      )
    }
  }

  return (
    <Grid container>
      <Grid item xs={6} sm={6} md={6} lg={6}>
        <div className="container-form">
          <h1>
            Add collaborator
            <LoaderPoints isloading={state.project_config.isFetching} />
          </h1>
          <Fetching isFetching={state.project_config.isFetching}>
            <form onSubmit={formik.handleSubmit}>
              <Grid container className="form-container-create-edit">
                <CustomInput
                  label={"Email *"}
                  name={"email"}
                  placeholder={"john.doe@email.com"}
                  type={"text"}
                  onChange={formik.handleChange}
                  classname={"form-item"}
                  formik={formik}
                  advice={`If desired collaborator does not have an account at QMENTA an invitation will be sent to the e-mail. When a user signs up with the specified e-mail, he will be automatically added to the study.`}
                />
                {state.project_config.data.data_locations[0].value && (
                  <CustomSelect
                    label={"Imagining Site *"}
                    name={"imagining_site"}
                    onChange={formik.handleChange}
                    classname={"form-item"}
                    formik={formik}
                    defaultValue={
                      state.project_config.data.data_locations[0].value
                    }
                    options={state.project_config.data.data_locations}
                  />
                )}

                {state.project_config.roles[0]?.value && (
                  <CustomSelect
                    label={"User Role *"}
                    name={"user_role"}
                    onChange={formik.handleChange}
                    classname={"form-item"}
                    formik={formik}
                    defaultValue={state.project_config.roles[0]?.value}
                    options={state.project_config.roles}
                    advice={`Please select the site the collaborator should have access to. All selected permissions will be given for the study and the selected sites`}
                  />
                )}
              </Grid>

              <Grid container className="buttons-container">
                <Link
                  to={`/project/${props.match.params.project_id}/collaborators`}
                >
                  <button className={"btn quaternary"}>Cancel</button>
                </Link>

                {Object.keys(formik.errors).length ? (
                  <Tooltip title={toolTipText} placement="bottom-start">
                    <button
                      className="btn inactive denial"
                      type="submit"
                      disabled={props.submitting}
                    >
                      Add Collaborator
                    </button>
                  </Tooltip>
                ) : (
                  <button
                    className="btn primary"
                    type="submit"
                    disabled={props.submitting}
                  >
                    Add Collaborator
                  </button>
                )}
              </Grid>
            </form>
          </Fetching>
        </div>
      </Grid>
    </Grid>
  )
}

export default compose(
  reduxForm({
    form: "CollaboratorsAdd",
  })
)(CollaboratorsAdd)
