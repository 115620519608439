import {
  CLEAR_CURRENT_MEASUREMENT,
  CLEAR_CURRENT_TASK_FORM,
  FETCHING_WORKLIST,
  GET_CENTRAL_READING_PROGRESS,
  GET_CURRENT_ADJ_TASK_ENDPOINT,
  GET_CURRENT_TASK,
  GET_CURRENT_TASK_ENDPOINT,
  GET_WORKLIST,
  SET_ADJUDICATOR,
  SET_CURRENT_LINE_MEASUREMENT,
  SET_MATRIX_DATA,
  SET_READER_1,
  SET_READER_2,
} from "../constants/constants"

import { AnyAction } from "redux"
import { ICentralReview } from "../utils/interfaces"

interface It {
  ssid: number
  unassigned_readers: string
  qa_status: string
  qa_status_icon: any
  reader_1_status: string
  reader_1_status_icon: string
  reader_2_status: string
  reader_2_status_icon: string
  patient_secret_name: string
  diagnosis_adjudicator_status: string
  diagnosis_adjudicator_status_icon: string
}

const initialState: ICentralReview = {
  currentTask: {
    task_info: {},
    form_fields: [],
    previous_timepoints: [],
    historyTable: {},
    related_reader_tasks: [],
    isFetching: true,
    annotation: [],
  },
  currentLineMeasurement: {},
  currentAdjTask: {
    form_fields: [],
    related_reader_tasks: [],
    task_info: {},
  },
  workList: [],
  progress: [],
  isFetching: true,
  isEmpty: true,
  totalResult: 0,
}

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case GET_WORKLIST:
      return {
        ...state,
        workList: action.payload,
        totalResult: action.totalResult,
        isFetching: action.isFetching,
        isEmpty: action.isEmpty,
      }

    case FETCHING_WORKLIST:
      return {
        ...state,
        isFetching: action.isFetching,
      }

    case SET_READER_1: {
      return {
        ...state,
        reader_1: action.payload,
      }
    }

    case SET_READER_2: {
      return {
        ...state,
        reader_2: action.payload,
      }
    }

    case SET_ADJUDICATOR: {
      return {
        ...state,
        adjudicator: action.payload,
      }
    }

    case GET_CENTRAL_READING_PROGRESS: {
      const checkFailPass = (qa: string) => {
        if (!qa) {
          return "open"
        } else {
          return qa
        }
      }

      const renderProgress = action.payload.data.map((t: It) => {
        return {
          ...t,
          ssid: t.ssid,
          unassigned_readers: t.unassigned_readers,
          qa_status: t.qa_status,
          qa_status_icon: checkFailPass(t.qa_status),
          reader_1_status: t.reader_1_status,
          reader_1_status_icon: t.reader_1_status,
          reader_2_status: t.reader_2_status,
          reader_2_status_icon: t.reader_2_status,
          patient_secret_name: t.patient_secret_name,
          diagnosis_adjudicator_status: t.diagnosis_adjudicator_status,
          diagnosis_adjudicator_status_icon: t.diagnosis_adjudicator_status,
        }
      })

      return {
        ...state,
        progress: renderProgress,
        isFetching: action.isFetching,
        isEmpty: action.isEmpty,
        totalResult: action.payload.total_result,
      }
    }

    case GET_CURRENT_TASK:
      return {
        ...state,
        currentTask: Object.assign(state.currentTask, action.payload),
      }

    case SET_CURRENT_LINE_MEASUREMENT:
      return {
        ...state,
        currentLineMeasurement: action.payload,
      }

    case CLEAR_CURRENT_TASK_FORM:
      return {
        ...state,
        currentLineMeasurement: {},
        currentTask: {
          ...state.currentTask,
          form_fields: [],
        },
      }

    case CLEAR_CURRENT_MEASUREMENT:
      return {
        ...state,
        currentLineMeasurement: {},
        currentTask: {
          ...state.currentTask,
        },
      }

    case SET_MATRIX_DATA:
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          historyTable: action.payload,
        },
        isFetching: action.isFetching,
        isEmpty: action.isEmpty,
      }

    case GET_CURRENT_TASK_ENDPOINT: {
      return {
        ...state,
        currentTask: {
          ...state.currentTask,
          task_info: action.payload.task_info,
          form_fields: action.payload.json_schema_form.properties,
          token: action.payload.token,
          annotation: action.payload.json_schema_form.annotations,
          previous_timepoints: action.payload.previous_timepoints,
          related_reader_tasks: action.payload.related_reader_tasks,
          isFetching: action.isFetching,
        },
      }
    }

    case GET_CURRENT_ADJ_TASK_ENDPOINT: {
      return {
        ...state,
        currentAdjTask: {
          ...state.currentAdjTask,
          task_info: action.payload.task_info,
          form_fields: action.payload.json_schema_form.properties,
          related_reader_tasks: action.payload.related_reader_tasks,
        },
      }
    }

    default:
      return {
        ...state,
      }
  }
}
