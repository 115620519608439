import {
  GET_FORM_TYPES,
  GET_ROLES_ADD_COLLABORATOR,
  IS_FETCHING,
  IS_FETCHING_SESSION,
  PROJECT_LIST_FETCHING_DATA,
  PROJECT_LIST_RECEIVE_DATA,
  RECEIVE_ACTIVITY_DATA,
  RECEIVE_PROJECT_CONFIG,
  SET_COLLABORATORS,
  SET_CURRENT_PROJECT,
  SET_CURRENT_PROJECT_FETCHING,
  SET_SESSION,
  SET_SESSION_EXPIRED,
  SET_SITES,
} from "../constants/constants"
import { IError, IFormValues, IModuleConfig } from "../utils/interfaces"
import { notificationError, success } from "../actions/notificationsActions"

import { MonitoringHeader } from "../utils/column-definition"
import { callAPI } from "../middlewares/call-api"
import { projectFiltered } from "../utils/project-filtered"
import { reset } from "redux-form"

let project_types: any = []

export const getMe = () => {
  return (dispatch: any) => {
    dispatch({
      type: IS_FETCHING_SESSION,
      payload: true,
    })
    return dispatch(callAPI("/me", "GET"))
      .then((data: any) => {
        if (data.success !== 0) {
          localStorage.setItem("qmenta-global-state", data.user_data)
          return dispatch({
            type: SET_SESSION,
            payload: data,
          })
        } else {
          dispatch({
            type: SET_SESSION_EXPIRED,
            payload: true,
          })
        }
      })
      .catch((error: IError) => error)
  }
}

export const updateProject =
  (values: IFormValues, history: any) => (dispatch: any) =>
    dispatch(
      callAPI("/projectset_manager/trials_project_create", "POST", values)
    )
      .then(() => {
        dispatch(reset("editStudyForm"))
        dispatch(success("Your project was successfully updated!"))
        history.push("/project")
      })
      .catch((error: IError) => error)

export const createNewProject =
  (values: IFormValues, history: any) => (dispatch: any) =>
    dispatch(
      callAPI("/projectset_manager/trials_project_create", "POST", values)
    )
      .then(() => {
        dispatch(reset("createStudyForm"))
        dispatch(success("Your project was successfully created!"))
        history.push("/project")
      })
      .catch((error: IError) => error)

const parseProjectForListTable = (data: any) => {
  const list_project = projectFiltered(data).map((project: any) => {
    return project_types.reduce((acc: any, current: any) => {
      current.roles.map((role: any) => {
        if (project.access.some((item: string) => item === role[0])) {
          acc = {
            status:
              project.state === "setup" ? "In " + project.state : project.state,
            name: project.name,
            my_access: role[6],
            id: project._id,
          }
        }
      })
      return acc
    }, {})
  })
  return list_project
}

export const getProjectList = () => (dispatch: any) => {
  dispatch({
    type: PROJECT_LIST_FETCHING_DATA,
  })
  return dispatch(callAPI("/projectset_manager/get_projectset_list"))
    .then((data: any) => {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve(parseProjectForListTable(data.data))
        }, 500)
      }).then((value: any) => {
        dispatch({
          type: PROJECT_LIST_RECEIVE_DATA,
          payload: value,
        })
      })
    })
    .catch((error: IError) => error)
}

export const getFormTypes = () => {
  return (dispatch: any) => {
    dispatch({
      type: IS_FETCHING,
    })
    dispatch(callAPI("/projectset_manager/load_create_trials_project"))
      .then(({ data }: any) => {
        const structure = data.properties.form_id.enum.map((element: any) => {
          return {
            value: element[0],
            label: element[1],
          }
        })
        dispatch({
          type: GET_FORM_TYPES,
          payload: structure,
          isFetching: false,
        })
      })
      .catch((error: IError) => {
        dispatch(notificationError("Something went wrong!"))
        throw error
      })
  }
}

const parseRolesForAddCollaborator = (project_type: any) => {
  let parsedRoles: any = []
  project_types.map((current: any) => {
    if (project_type === current.value) {
      parsedRoles = current.roles.map((role: any) => {
        return {
          value: role[0],
          label: role[6],
        }
      })
    }
  })
  return parsedRoles
}

export const getRoles = (project_type: any) => {
  return (dispatch: any) => {
    dispatch({
      type: GET_ROLES_ADD_COLLABORATOR,
      payload: parseRolesForAddCollaborator(project_type),
    })
  }
}

export const getProjectData = (id: any, history: any) => {
  return (dispatch: any) =>
    dispatch(
      callAPI(`/projectset_manager/get_project_data?project_id=${id}`)
    ).catch((error: IError) => {
      history.push("/project")
      dispatch(notificationError("You are not part of this project!"))
      throw error
    })
}

export const getProjectModuleConfig = () => (dispatch: any) =>
  dispatch(callAPI("/projectset_manager/module_config"))
    .then((data: IModuleConfig) => {
      const data_locations = data.data_locations.map((value: any) => {
        return { value: value.location_code, label: value.location_name }
      })
      project_types = data.project_types
      dispatch({
        type: RECEIVE_PROJECT_CONFIG,
        allowed_project_types: data.allowed_project_types,
        project_types: data.project_types,
        data_locations: data_locations,
      })
    })
    .catch((error: IError) => error)

export const addNewCollaborator =
  (collab_data: any, history: any, project_id: any) => (dispatch: any) => {
    dispatch(
      callAPI("/projectset_manager/upsert_project_member", "POST", collab_data)
    )
      .then(() => {
        dispatch(reset("CollaboratorsAdd"))
        dispatch(success("Collaborator was added created!"))
        // history.push(`/project/${project_id}/collaborators`)
      })
      .catch((error: any) => {
        throw error
      })
  }

// export const removeCollaboratorApi = (val: any) => (dispatch: any) =>
//   dispatch(callAPI("/projectset_manager/remove_project_member", "POST", val))
//     .then((res: any) => res.data)
//     .catch((error: any) => error)
// export const findUserByEmail = (val: any) => (dispatch: any) =>
//   dispatch(callAPI("/a_user_manager/find_user_by_mail", "POST", val)).catch(
//     (e: any) => {
//       return {
//         success: 0,
//       }
//     }
//   )

export const addNewSite =
  (site_data: any, history: any, project_id: any) => (dispatch: any) => {
    console.log(site_data)
    console.log(history)
    console.log(project_id)
    // dispatch(
    //   callAPI("/projectset_manager/upsert_project_member", "POST", site_data)
    // )
    //   .then(() => {
    //     dispatch(reset("CollaboratorsAdd"))
    //     dispatch(success("Collaborator was added created!"))
    //     // history.push(`/project/${project_id}/collaborators`)
    //   })
    //   .catch((error: any) => {
    //     throw error
    //   })
  }

export const getActivityList = (projectId: any) => (dispatch: any) => {
  return dispatch(
    callAPI(`/projectset_manager/get_activity_list?_pid=${projectId}`)
  )
    .then((res: any) => {
      dispatch({
        type: RECEIVE_ACTIVITY_DATA,
        payload: res,
        total: res.length,
      })
    })
    .catch((error: IError) => error)
}

const parseUsersForListCollaborator = (data: any) => {
  const usersRolesParsed = data.users.map((user: any) => {
    return project_types.reduce((acc: any, current: any) => {
      if (data.project_type === current.value) {
        current.roles.map((role: any) => {
          if (user.access.some((item: string) => item === role[0])) {
            acc = {
              collaborator: user.name,
              email: user.user_id,
              site: user.data_location,
              role: role[6],
            }
          }
        })
      }
      return acc
    }, {})
  })
  return usersRolesParsed
}

export const fetchItem =
  (values: any, username: any, history: any) => (dispatch: any) => {
    dispatch({
      type: SET_CURRENT_PROJECT_FETCHING,
    })
    dispatch(getProjectData(values, history))
      .then((data: any) => {
        const userFiltered = data.users.find(
          (user: any) => user.user_id === username
        )

        for (let i = 1; i <= data.config.num_readers; i++) {
          MonitoringHeader.push({
            label: `Reading ${i}`,
            minWidth: 190,
            id: `reader_${i}_status_icon`,
            cellName: `reading_${i}`,
            sortField: `reader_${i}_status_icon`,
          })
        }

        MonitoringHeader.push({
          label: "Adjudication",
          minWidth: 190,
          id: "diagnosis_adjudicator_status_icon",
          cellName: "diagnosis_adjudicator",
          sortField: "diagnosis_adjudicator_status_icon",
        })

        dispatch({
          type: SET_CURRENT_PROJECT,
          header: MonitoringHeader,
          payload: data,
          isManager:
            userFiltered.access.includes("role_clinical_trial_project_admin") ||
            userFiltered.access.includes(
              "role_clinical_trial_project_manager"
            ) ||
            userFiltered.access.includes("role_clinical_trial_owner"),
        })
        dispatch({
          type: SET_SITES,
          sites: data,
        })
        dispatch({
          type: SET_COLLABORATORS,
          collaborators: parseUsersForListCollaborator(data),
        })
      })

      .catch((error: IError) => error)
  }
