import * as React from "react"

import { FormControlLabel, FormGroup, Grid, Switch } from "@material-ui/core"

const CustomSwitch = (props: any) => {
  return (
    <Grid container className={props.classname}>
      <Grid item xs={12} sm={12} md={6}>
        <FormGroup>
          <FormControlLabel
            control={
              <Switch
                name={props.name}
                onChange={props.onChange}
                value={props.formik.values[props.name]}
              />
            }
            label={props.label}
          />
        </FormGroup>
      </Grid>
    </Grid>
  )
}

export default CustomSwitch
