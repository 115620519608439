import * as React from "react"

import CreateStudyForm from "./create-study-form"
import EditStudyForm from "./edit-study-form"

const CreateEditStudy = (props: any) => {
  return (
    <>
      {props.create ? (
        <CreateStudyForm {...props} />
      ) : (
        <EditStudyForm {...props} />
      )}
    </>
  )
}

export default CreateEditStudy
