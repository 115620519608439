import "./collaborators-page.sass"

import * as React from "react"

import EmptyMessage from "../../components/common/empty-message/empty-message"
import Fetching from "../../components/common/fetching-table/fetching-table"
import { Grid } from "@material-ui/core"
import { Link } from "react-router-dom"
import LoaderPoints from "../../components/common/loader-points/loader-points"
import Paper from "@material-ui/core/Paper"
import TableLayout from "../../components/common/Table/table-layout"
import { collaboratorColumn } from "../../utils/column-definition"
import { useSelector } from "react-redux"

const CollaboratorsPage = (props: any) => {
  const state = useSelector((state: any) => state)

  return (
    <div className="container">
      <Grid container className="container-worklist-page">
        <Grid item xs={12} sm={12} md={2}>
          <h1>
            Collaborators
            <LoaderPoints isloading={state.current.project.isFetching} />
          </h1>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={12} md={12}>
            <div className="create-study-button-row">
              <Link
                to={`/project/${props.match.params.project_id}/collaborators/add`}
              >
                <button className="btn primary">Add Collaborator</button>
              </Link>
            </div>
            {!state.current.project.isFetching && (
              <label>
                You are monitoring {state.collaborators.total} collaborators
              </label>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Fetching
            isFetching={
              state.current.project.isFetching &&
              !state.collaborators.data.length
            }
          >
            <EmptyMessage dataLoaded={state.collaborators.data}>
              <Grid
                item
                xs={12}
                container
                className="no-task"
                justifyContent={"center"}
                alignContent={"center"}
              >
                <p>Your collaborators list is currently empty</p>
              </Grid>
            </EmptyMessage>
            {state.collaborators.data.length && (
              <Grid item xs={12} sm={12} md={12}>
                <Paper className="root">
                  <TableLayout
                    columns={collaboratorColumn}
                    data={state.collaborators.data}
                    pagination={true}
                    rowLink={false}
                  />
                </Paper>
              </Grid>
            )}
          </Fetching>
        </Grid>
      </Grid>
    </div>
  )
}

export default CollaboratorsPage
