import "../../icons/styling-icons.sass"

import * as React from "react"

import rotate from "../../../assets/meddream-icons/rotate.svg"
import rotateActive from "../../../assets/meddream-icons/rotateActive.svg"
// import dropdownIcon from "../../../assets/meddream-icons/dropdownicon.svg"
// import dropdownIconActive from "../../../assets/meddream-icons/dropdowniconactive.svg"
// import mouse from "../../../assets/meddream-icons/mouse.svg"

interface IRotate {
  rotateIcon: boolean
  handleActiveIcon: any
  dropdown: string
  handleDropdown: any
}

const RotateIcon = (props: IRotate) => {
  const { dropdown, handleDropdown, rotateIcon, handleActiveIcon } = props
  const globalViewport: any = global

  const handleRotateChange = () => {
    if (rotateIcon) {
      handleActiveIcon("")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 0)
    } else {
      handleActiveIcon("rotate")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 6)
    }
  }

  return (
    <div className={`container-icons ${rotateIcon && "active"}`}>
      <div className="study-top-btn" onClick={() => handleRotateChange()}>
        <img
          width="30px"
          height="30px"
          src={rotateIcon ? rotateActive : rotate}
          alt="rotate"
        />
        <h5 style={{ color: rotateIcon ? "#2CA895" : "#666" }}>Rotate</h5>
      </div>

      {/* <div className="study-top-dropdown">
        <img
          onClick={() => handleDropdown()}
          className="top-btn-drop-down"
          width="15px"
          height="15px"
          src={rotateIcon ? dropdownIconActive : dropdownIcon}
          alt="drop-down"
        />
      </div>
      <div className="dropdown-container">
        {dropdown && (
          <div>
            <p>Hello</p>
          </div>
        )}
      </div> */}
    </div>
  )
}

export default RotateIcon
