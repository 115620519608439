import "./project-list-page.sass"

import * as React from "react"

import { Grid, Paper } from "@material-ui/core"
import { useDispatch, useSelector } from "react-redux"

import DashboardSidePanel from "../../components/common/sidepanel/DashboardSidePanel"
import EmptyMessage from "../../components/common/empty-message/empty-message"
import Fetching from "../../components/common/fetching-table/fetching-table"
import { Link } from "react-router-dom"
import LoaderPoints from "../../components/common/loader-points/loader-points"
import TableLayout from "../../components/common/Table/table-layout"
import { getProjectList } from "../../actions/apiAction"
import { projectListColumns } from "../../utils/column-definition"
import { useEffect } from "react"

interface IProps {
  fetchProjects: () => {}
  classes: any
  projects: any
  project_config: any
  history: any
}

const ProjectList = (props: IProps) => {
  const state = useSelector((state: any) => state)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getProjectList())
  }, [])

  return (
    <Grid container>
      <Grid item xs={2} sm={2} md={2} lg={2}>
        <DashboardSidePanel {...props} />
      </Grid>
      <Grid item xs={10} sm={10} md={10}>
        <div className="container-project-list-page">
          <Grid item xs={12} sm={12} md={12}>
            <h1>
              Studies
              <LoaderPoints isloading={state.projects.isFetching} />
            </h1>
          </Grid>
          <Grid item>
            <p className="paragraph">
              The studies that are owned by you and/or assigned to you are shown
              on this page
            </p>
          </Grid>

          {state.current.project.isManager && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12}>
                <div className="create-study-button-row">
                  <Link to={"/project/create"}>
                    <button className="btn primary">Create New Study</button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          )}

          <Fetching
            isFetching={
              state.projects.isFetching && !state.projects.data.length
            }
          >
            <EmptyMessage dataLoaded={state.projects.data}>
              <Grid
                item
                xs={12}
                container
                className="no-task"
                justifyContent={"center"}
                alignContent={"center"}
              >
                <p>Your studies list is currently empty</p>
              </Grid>
            </EmptyMessage>
            {state.projects.data.length && (
              <Grid item xs={12} sm={12} md={12}>
                <Paper className="root">
                  <TableLayout
                    columns={projectListColumns}
                    data={state.projects.data}
                    pagination={true}
                    rowLink={true}
                  />
                </Paper>
              </Grid>
            )}
          </Fetching>
        </div>
      </Grid>
    </Grid>
  )
}

export default ProjectList
