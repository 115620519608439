import {
  PROJECT_LIST_FETCHING_DATA,
  PROJECT_LIST_RECEIVE_DATA,
} from "../constants/constants"

import { AnyAction } from "redux"
import { IProjectList } from "../utils/interfaces"

const ldFlags = localStorage.getItem("flags")
  ? localStorage.getItem("flags")
  : false

const { useFastApiEndpoint } = ldFlags ? JSON.parse(ldFlags) : false

const initialState: IProjectList = {
  data: [],
  empty: false,
  isFetching: false,
}

export default function (state = initialState, action: AnyAction) {
  switch (action.type) {
    case PROJECT_LIST_RECEIVE_DATA:
      return {
        ...state,
        data: action.payload,
        empty: action.emptyTable,
        isFetching: false,
      }

    case PROJECT_LIST_FETCHING_DATA:
      return {
        ...state,
        data: [],
        isFetching: true,
      }

    default:
      return {
        ...state,
      }
  }
}
