import "./materialBreadcrumb.sass"

import * as React from "react"

import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import Link from "@material-ui/core/Link"
import Typography from "@material-ui/core/Typography"
import { useSelector } from "react-redux"
import { withRouter } from "react-router-dom"

const MuiBreadCrumbs = (props: any) => {
  const {
    history,
    location: { pathname },
  } = props
  const pathnames = pathname.split("/").filter((x: any) => x)
  const state = useSelector((state: any) => state)

  const customizeNames = (name: string) => {
    if (name === "overview")
      return `Study overview (${state.current.project.data.name})`
    if (name === "add-files") return "Add files"
    if (name === "sites")
      return `Imaging sites (${state.current.project.data.name})`
    if (name === "add" && pathnames[pathnames.length - 2] === "data")
      return "Data Add"
    if (name === "monitoring")
      return `Study progress monitoring (${state.current.project.data.name})`
    if (name === "worklist")
      return `Worklist (${state.current.project.data.name})`
    if (name === "information")
      return `Study information (${state.current.project.data.name})`
    if (name === "edit" && pathnames[pathnames.length - 2] === "information")
      return "Edit Study"
    if (name === "create" && pathnames[pathnames.length - 2] === "project")
      return "Create study"
    if (name === "edit" && pathnames[pathnames.length - 3] === "sites")
      return "Edit Site"
    if (name === "add" && pathnames[pathnames.length - 2] === "sites")
      return "Add on Imagining site"

    if (name === "activity")
      return `Study (${state.current.project.data.name}) log`
    if (name === "collaborators")
      return `Collaborators (${state.current.project.data.name})`
    if (name === "edit" && pathnames[pathnames.length - 3] === "collaborators")
      return "Edit collaborator"
    if (name === "add" && pathnames[pathnames.length - 2] === "collaborators")
      return "Add collaborator"
    if (name === "history") return "History"
    if (name === "progress" && pathnames[pathnames.length - 2] === "analysis")
      return "Full analysis progress"
    if (name === "task")
      return `${state.current.project.isManager ? "Monitoring" : "Worklist"} (${
        state.current.project.data.name
      })`
    if (name === "adjudicate")
      return `${state.current.project.isManager ? "Monitoring" : "Worklist"} (${
        state.current.project.data.name
      })`
    if (!isNaN(parseInt(name))) return `Task ${name}`
    // return name
  }

  const filterRoutes = (name: any, index: any) => {
    const routeTo = `/${pathnames.slice(0, index + 1).join("/")}`
    if (!isNaN(name)) return
    if (name === "project") return
    if (name === "information")
      return (
        <Link
          key={index}
          onClick={() =>
            history.push(`/project/${state.current.project.data._id}/overview`)
          }
          style={{ cursor: "pointer", color: "#000" }}
        >
          {customizeNames(name)}
        </Link>
      )
    if (
      pathnames[pathnames.length - 2] === "task" ||
      pathnames[pathnames.length - 2] === "adjudicate" ||
      pathnames[pathnames.length - 1] === "history"
    )
      return (
        <Link
          key={index}
          onClick={() =>
            history.push(
              `/project/${state.current.project.data._id}/${
                state.current.project.isManager ? "monitoring" : "worklist"
              }`
            )
          }
          style={{ cursor: "pointer", color: "#000" }}
        >
          {customizeNames(name)}
        </Link>
      )
    return (
      <Link
        key={index}
        onClick={() => history.push(routeTo)}
        style={{ cursor: "pointer", color: "#000" }}
      >
        {customizeNames(name)}
      </Link>
    )
  }

  return (
    <div className="container-breadcrumb">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          onClick={() => history.push("/")}
          style={{ cursor: "pointer", color: "#000" }}
        >
          Studies
        </Link>
        {pathnames.map((name: any, index: any) => {
          const isLast = index === pathnames.length - 1
          if (isLast) {
            return <Typography key={index}>{customizeNames(name)}</Typography>
          } else {
            return filterRoutes(name, index)
          }
        })}
      </Breadcrumbs>
    </div>
  )
}

export default withRouter(MuiBreadCrumbs)
