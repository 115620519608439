import "./work-list-page.sass"

import * as React from "react"

import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"

import DropdownList from "react-widgets/DropdownList"
import EmptyMessage from "../../components/common/empty-message/empty-message"
import Fetching from "../../components/common/fetching-table/fetching-table"
import { Grid } from "@material-ui/core"
import LoaderPoints from "../../components/common/loader-points/loader-points"
import Paper from "@material-ui/core/Paper"
import TableLayout from "../../components/common/Table/table-layout"
import { getWorklist } from "../../actions/CentralReviewAction"
import { workListColumn } from "../../utils/column-definition"

// import SearchBar from "../../components/common/Table/search-bar/search-bar"

const sitesData = [
  { id: 0, name: "Open", value: "open" },
  { id: 1, name: "Done", value: "done" },
]

const WorkList = (props: any) => {
  const [task_value, task_valueSet] = useState(sitesData[0])
  const dispatch = useDispatch()
  const state = useSelector((state: any) => state)

  useEffect(() => {
    const data = {
      projectset_id: props.match.params.project_id,
      filter: "",
      status: sitesData[0].value,
    }
    dispatch(getWorklist(data))
  }, [])

  const onSubmit = (event: any) => {
    props.onSubmit()
    event.preventDefault()
  }

  const filteringStatus = (object: any) => {
    task_valueSet(object)
    const data = {
      projectset_id: props.match.params.project_id,
      status: object.value,
    }
    dispatch(getWorklist(data))
  }

  return (
    <>
      <Grid container className="container-worklist-page">
        <Grid item xs={12} sm={12} md={12}>
          <Grid item xs={12} sm={12} md={2}>
            <h1>
              Worklist
              <LoaderPoints isloading={state.CentralReview.isFetching} />
            </h1>
            <form onSubmit={onSubmit} className="form-row">
              <div>
                <label>Select Worklist Status</label>
                <DropdownList
                  onBlur={(event: any) => event.preventDefault()}
                  data={sitesData}
                  textField="name"
                  value={task_value}
                  onChange={(object: any) => filteringStatus(object)}
                />
              </div>
            </form>
            {!state.CentralReview.isEmpty && (
              <label>
                There are {state.CentralReview.totalResult} activities within
                this study
              </label>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={12}>
          <Fetching
            isFetching={
              state.CentralReview.isFetching &&
              !state.CentralReview.workList.length
            }
          >
            <EmptyMessage dataLoaded={state.CentralReview.totalResult}>
              <Grid
                item
                xs={12}
                container
                className="no-task"
                justifyContent={"center"}
                alignContent={"center"}
              >
                <p>Your {task_value.name} worklist is currently empty</p>
              </Grid>
            </EmptyMessage>
            {!state.CentralReview.isEmpty && (
              <>
                {/* TO DO uncomment when https://qmenta.atlassian.net/browse/EN-1550 is done */}
                {/* <SearchBar {...props} /> */}
                <Grid item xs={12} sm={12} md={12}>
                  <Paper className="root">
                    <TableLayout
                      columns={workListColumn}
                      data={state.CentralReview.workList}
                      pagination={true}
                      searchbar={true}
                      {...props}
                    />
                  </Paper>
                </Grid>
              </>
            )}
          </Fetching>
        </Grid>
      </Grid>
    </>
  )
}

export default WorkList
