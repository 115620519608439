import "../../create-edit-study/create-edit-study.sass"

import * as React from "react"

import { Grid } from "@material-ui/core"

const CustomInput = (props: any) => {
  return (
    <Grid container className={props.classname}>
      <Grid item xs={12} sm={12} md={12}>
        <label>{props.label}</label>
        <input
          className={
            props.formik.errors[props.name] ? "input border-error" : "input"
          }
          name={props.name}
          autoComplete="off"
          placeholder={props.placeholder}
          type={props.type}
          value={props.formik.values[props.name]}
          onChange={props.onChange}
          disabled={props.disabled}
        />
        {((props.formik.touched[props.name] &&
          props.formik.errors[props.name]) ||
          !props.formik.isSubmitting) && (
          <p style={{ color: "#9B1A06", fontSize: "14px" }}>
            {props.formik.errors[props.name]}
          </p>
        )}
        <div>
          <small className="description-text">{props.advice}</small>
        </div>
      </Grid>
    </Grid>
  )
}

export default CustomInput
