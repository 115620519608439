import "../../icons/styling-icons.sass"

import * as React from "react"

import pan from "../../../assets/meddream-icons/pan.svg"
import panActive from "../../../assets/meddream-icons/panActive.svg"
// import dropdownIcon from "../../../assets/meddream-icons/dropdownicon.svg"
// import dropdownIconActive from "../../../assets/meddream-icons/dropdowniconactive.svg"

interface IViewerInteractions {
  viewerInteractionsIcon: boolean
  handleActiveIcon: any
  dropdown: string
  handleDropdown: any
}

const PanIcon = (props: IViewerInteractions) => {
  const { dropdown, handleDropdown, viewerInteractionsIcon, handleActiveIcon } =
    props
  const globalViewport: any = global

  const handleViewerInteractionChange = () => {
    if (viewerInteractionsIcon) {
      handleActiveIcon("")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 0)
    } else {
      handleActiveIcon("viewerInteractions")
      globalViewport.viewportsCore.setMouseButtonFunction(1, 3)
    }
  }

  return (
    <div className={`container-icons ${viewerInteractionsIcon && "active"}`}>
      <div
        className="study-top-btn"
        onClick={() => handleViewerInteractionChange()}
      >
        <img
          width="30px"
          height="30px"
          src={viewerInteractionsIcon ? panActive : pan}
          alt="pan"
        />
        <h5 style={{ color: viewerInteractionsIcon ? "#2CA895" : "#666" }}>
          Pan
        </h5>
      </div>

      {/* <div className="study-top-dropdown">
        <img
          onClick={() => handleDropdown()}
          className="top-btn-drop-down"
          width="15px"
          height="15px"
          src={viewerInteractionsIcon ? dropdownIconActive : dropdownIcon}
          alt="drop-down"
        />
      </div>
      <div className="dropdown-container">
        {dropdown && (
          <div>
            <p>Hello</p>
          </div>
        )}
      </div> */}
    </div>
  )
}

export default PanIcon
