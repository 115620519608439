import { ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from "../constants/constants"

const enqueueSnackbar = (notification: any, type: any, timeout: any) => ({
  type: ENQUEUE_SNACKBAR,
  notification: {
    key: new Date().getTime() + Math.random(),
    message: notification,
    options: {
      variant: type,
      autoHideDuration: timeout,
    },
  },
})

export const hide = (key: any) => ({
  type: REMOVE_SNACKBAR,
  key,
})

export const notificationError =
  (message: any, timeout = 3000) =>
  (dispatch: any) =>
    dispatch(enqueueSnackbar(message, "error", timeout))

export const success =
  (message: any, timeout = 3000) =>
  (dispatch: any) =>
    dispatch(enqueueSnackbar(message, "success", timeout))

export const warning =
  (message: any, timeout = 3000) =>
  (dispatch: any) =>
    dispatch(enqueueSnackbar(message, "warning", timeout))
