import "./work-list-viewer.sass"
import "@meddream/viewports-core"

import * as React from "react"

import { useDispatch, useSelector } from "react-redux"

import { Grid } from "@material-ui/core"
import LoaderContainer from "../../components/common/loader-container/loader-container"
import MuiBreadCrumbs from "../../components/common/breadcrumbsContext/materialBreadcrumb"
import { RootState } from "../../reducers/mainReducer"
import StudiesMenu from "./StudiesMenu/StudiesMenu"
import ViewportsLayout from "./ViewportsLayout/ViewportsLayout"
import WorkListViewerForm from "./work-list-viewer-form"
import brainThinking from "../../assets/lotties/brain-lottie"
import { fetchItem } from "../../actions/apiAction"

let isManager: any

const WorkListViewer = (props: any) => {
  const dispatch = useDispatch()
  const [activeIcon, setActiveIcon] = React.useState("")
  const globalViewport: any = global
  const [activeContainerId, setActiveContainerId] = React.useState(
    "viewport-container-1-1"
  )
  const state = useSelector((state: RootState | any) => state)
  const [isloading, setIsLoading] = React.useState(true)

  React.useEffect(() => {
    setTimeout(() => {
      setIsLoading(false)
    }, 3000)
  }, [])
  React.useEffect(() => {
    dispatch(
      fetchItem(
        props.match.params.project_id,
        state.session.user_data.username,
        props.history
      )
    )
  }, [state.session.user_data])

  React.useEffect(() => {
    isManager = state.current.project.isManager
  }, [state.current.project.data])

  React.useEffect(() => {
    const posibleContainerIds = [
      "viewport-container-1-1",
      "viewport-container-1-2",
    ]
    if (state.CentralReview.currentTask["token"]) {
      Promise.all([
        globalViewport.viewportsCore.loadTokenWithHIS(
          state.CentralReview.currentTask["token"]
        ),
      ]).then((studyData) => {
        const instanceUid = studyData[0][0].series[0].instances[0].uid
        const instanceUid2 = studyData[0][0].series[1].instances[0].uid

        if (state.CentralReview.currentTask.annotation) {
          globalViewport.viewportsCore.layoutController.openInstanceToViewportContainer(
            state.CentralReview.currentTask.annotation["containerId"],
            state.CentralReview.currentTask.annotation["instanceUid"]
          )
          globalViewport.viewportsCore.layoutController.openInstanceToViewportContainer(
            posibleContainerIds.filter(
              (containerID) =>
                containerID !==
                state.CentralReview.currentTask.annotation["containerId"]
            )[0],
            posibleContainerIds.filter(
              (containerID) =>
                containerID !==
                state.CentralReview.currentTask.annotation["containerId"]
            )[0] === "viewport-container-1-2"
              ? instanceUid2
              : instanceUid
          )
        } else {
          globalViewport.viewportsCore.layoutController.openInstanceToViewportContainer(
            "viewport-container-1-1",
            instanceUid
          )
          globalViewport.viewportsCore.layoutController.openInstanceToViewportContainer(
            "viewport-container-1-2",
            instanceUid2
          )
        }
      })
    }
  }, [state.CentralReview.currentTask.token])

  React.useEffect(() => {
    // const notLocalEnv: any = [
    //   "https://test.qmenta.com",
    //   "https://platform.qmenta.com",
    //   "https://staging.qmenta.com",
    // ]
    globalViewport.viewportsCore.create()
    // if (notLocalEnv.includes(process.env.REACT_APP_BACKEND_URL)) globalViewport.viewportsCore.setBaseUrl(process.env.REACT_APP_MEDDREAM_URL)
    globalViewport.viewportsCore.registerEvent(
      globalViewport.viewportsCore.getConstants().CORE_EVENTS
        .ACTIVE_CONTAINER_CHANGED,
      (containerId: any) => selectViewportContainer(containerId)
    )
  }, [])

  const selectViewportContainer = (containerId: any) => {
    setActiveContainerId(containerId)
  }

  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <MuiBreadCrumbs />
        </Grid>
        <Grid item xs={2} className="form-container-viewer">
          <WorkListViewerForm {...props} isManager={isManager} />
        </Grid>
        <Grid item xs={10} className="meddream-container">
          <LoaderContainer isloading={isloading} lottieSVG={brainThinking}>
            <div className="content">
              <StudiesMenu
                activeIcon={activeIcon}
                handleActiveIcon={(value: any) => {
                  setActiveIcon(value)
                }}
                activeContainerId={activeContainerId}
                layoutRows={1}
                layoutColumns={1}
                {...props}
              />
              <div className="viewer-menu-container">
                <div id="viewer">
                  <ViewportsLayout
                    layoutRows={1}
                    layoutColumns={2}
                    activeContainerId={activeContainerId}
                  />
                </div>
              </div>
            </div>
          </LoaderContainer>
        </Grid>
      </Grid>
    </div>
  )
}

export default WorkListViewer
