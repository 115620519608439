export const SET_CURRENT_PROJECT = "SET_CURRENT_PROJECT"
export const SET_SESSION = "SET_SESSION"
export const SET_SESSION_EXPIRED = "SET_SESSION_EXPIRED"
export const IS_FETCHING_SESSION = "IS_FETCHING_SESSION"

export const API_CALL = "API_CALL"

export const RECEIVE_PROJECT_CONFIG = "RECEIVE_PROJECT_CONFIG"

export const SET_CURRENT_PROJECT_FETCHING = "SET_CURRENT_PROJECT_FETCHING"
export const SET_READER_1 = "SET_READER_1"
export const SET_READER_2 = "SET_READER_2"
export const SET_ADJUDICATOR = "SET_ADJUDICATOR"
export const GET_CENTRAL_READING_PROGRESS = "GET_CENTRAL_READING_PROGRESS"

export const SET_SITES = "SET_SITES"

export const GET_WORKLIST = "GET_WORKLIST"
export const FETCHING_WORKLIST = "FETCHING_WORKLIST"
export const GET_CURRENT_TASK = "GET_CURRENT_TASK"
export const CLEAR_CURRENT_TASK_FORM = "CLEAR_CURRENT_TASK_FORM"
export const CLEAR_CURRENT_MEASUREMENT = "CLEAR_CURRENT_MEASUREMENT"
export const SET_CURRENT_LINE_MEASUREMENT = "SET_CURRENT_LINE_MEASUREMENT"
export const GET_CURRENT_TASK_ENDPOINT = "GET_CURRENT_TASK_ENDPOINT"
export const GET_CURRENT_ADJ_TASK_ENDPOINT = "GET_CURRENT_ADJ_TASK_ENDPOINT"
export const SET_MATRIX_DATA = "SET_MATRIX_DATA"

/* PROJECTS */

export const RECEIVE_DATA = "RECEIVE_DATA"
export const FETCHING_DATA = "FETCHING_DATA"
export const GET_FORM_TYPES = "GET_FORM_TYPES"
export const IS_FETCHING = "IS_FETCHING"

/* CONSTANTS FOR TABLES */

export const SET_COLLABORATORS = "SET_COLLABORATORS"
export const GET_ROLES_ADD_COLLABORATOR = "GET_ROLES_ADD_COLLABORATOR"

/* ACTIVITY_TABLE */

export const RECEIVE_ACTIVITY_DATA = "RECEIVE_ACTIVITY_DATA"

/* PROGRESS_MONITORING_TABLE */

export const PROGRESS_MONITORING_TABLE_RECEIVE_DATA =
  "PROGRESS_MONITORING_TABLE_RECEIVE_DATA"

/* PROJECT_LIST */

export const PROJECT_LIST_RECEIVE_DATA = "PROJECT_LIST_RECEIVE_DATA"
export const PROJECT_LIST_FETCHING_DATA = "PROJECT_LIST_FETCHING_DATA"

export const PROJECT_OWNER_ROLE_NAME = "Clinical Trial Owner"

/* DATE FORMATS */

export const DATE_HOUR_MINUTE_FORMAT = "yyyy-MM-dd HH:mm:ss"
export const DATE_FORMAT = "yyyy-MM-dd"

/* SNACKBAR */

export const ENQUEUE_SNACKBAR = "ENQUEUE_SNACKBAR"
export const REMOVE_SNACKBAR = "REMOVE_SNACKBAR"

export const toolTipText = "You can't submit, required missing values"
