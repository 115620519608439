import "./loader-points.sass"

import * as React from "react"

import LoadingImage from "../../../assets/icons/loading-image.gif"
import LoadingAltImage from "../../../assets/icons/fountain.gif"

const LoaderPoints = ({ isloading, useAlt }: any) => {
  return (
    <>
      {isloading ? (
        <img
          className="loading-points"
          width={useAlt ? "44px" : "24px"}
          height={useAlt ? "auto" : "24px"}
          src={useAlt ? LoadingAltImage : LoadingImage}
          alt="loading"
        />
      ) : null}
    </>
  )
}

export default LoaderPoints
