import "./table-body-component.sass"

import * as React from "react"

import { TableBody, TableCell, TableRow } from "@material-ui/core"

import ActionsButtons from "../actions-buttons/actions-buttons"
import { useHistory } from "react-router-dom"

const TableBodyComponent = ({
  columns,
  data,
  page,
  rowsPerPage,
  rowLink,
  match,
}: any) => {
  const history = useHistory()
  const handleClickRow = (value: any) => {
    if (rowLink) {
      return history.push(`/project/${value}/overview`)
    }

    return null
  }

  const renderSwitch = (row: any, column: any) => {
    switch (column.accessor) {
      case "action":
        return <ActionsButtons row={row} match={match} />

      default:
        return <span className={row[column.id]}>{row[column.id] || "-"}</span>
    }
  }

  return (
    <TableBody>
      {data
        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
        .map((row: any, rowIndex: any) => {
          return (
            <TableRow
              className={rowLink && "body-row"}
              role="checkbox"
              tabIndex={-1}
              hover={rowLink}
              key={rowIndex}
            >
              {columns.map((column: any, cellIndex: any) => {
                return (
                  <TableCell
                    key={cellIndex}
                    className="body-cell"
                    component="th"
                    scope="row"
                    onClick={() => handleClickRow(row.id)}
                  >
                    {renderSwitch(row, column)}
                  </TableCell>
                )
              })}
            </TableRow>
          )
        })}
    </TableBody>
  )
}

export default TableBodyComponent
