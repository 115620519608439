import "./side-panel.sass"

import * as React from "react"

import { useEffect, useState } from "react"

import { Link } from "react-router-dom"
import { Logo } from "../Logo"
import Tabs from "../dashboard/tabs"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useSelector } from "react-redux"

const ProjectSidePanel = (props: any) => {
  const [study, setStudy] = useState([])
  const [setup, setSetup] = useState([])
  const flags = useFlags()
  const state = useSelector((state: any) => state)

  useEffect(() => {
    setStudy([])
    setSetup([])
    setStudy([
      {
        url: `/project/${props.match.params.project_id}/overview`,
        id: 1,
        name: "Overview",
      },
    ])
    if (state.current.project.isManager) {
      setStudy((oldArray) => [
        ...oldArray,
        {
          url: `/project/${props.match.params.project_id}/monitoring`,
          id: 9,
          name: "Monitoring",
        },
      ])

      if (flags.activityLog) {
        setStudy((oldArray) => [
          ...oldArray,
          {
            url: `/project/${props.match.params.project_id}/activity`,
            id: 5,
            name: "Activity log",
          },
        ])
      }

      if (flags.sitesAndCollaborators) {
        setSetup((oldArray) => [
          ...oldArray,
          {
            url: `/project/${props.match.params.project_id}/information/edit`,
            id: 4,
            name: "Information",
          },
          {
            url: `/project/${props.match.params.project_id}/sites`,
            id: 6,
            name: "Sites",
          },
          {
            url: `/project/${props.match.params.project_id}/collaborators`,
            id: 7,
            name: "Collaborators",
          },
        ])
      }

      if (flags.protocolSetup) {
        setSetup((oldArray) => [
          ...oldArray,
          {
            url: `/project/${props.match.params.project_id}/protocol`,
            id: 8,
            name: "Protocol / QC",
          },
        ])
      }
    } else {
      setStudy((oldArray) => [
        ...oldArray,
        {
          url: `/project/${props.match.params.project_id}/worklist`,
          id: 2,
          name: "Worklist",
        },
      ])
    }
  }, [state.current.project.isManager])

  return (
    <aside className={"project-side-panel"}>
      <Logo color="#279181" />
      <span className="dashboard-link">
        <Link to={"/project"}>Studies</Link>
      </span>
      <div>
        <p className="sidepanel-category">STUDY</p>
        <Tabs elements={study} {...props} />
      </div>

      {setup.length ? (
        <div>
          <p className="sidepanel-category">SETUP</p>
          <Tabs elements={setup} {...props} />
        </div>
      ) : (
        <></>
      )}
    </aside>
  )
}

export default ProjectSidePanel
