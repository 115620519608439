import "./study-card.sass"

import * as React from "react"

import Card from "@material-ui/core/Card"
import CardContent from "@material-ui/core/CardContent"
import { DATE_FORMAT } from "../../../constants/constants"
import { Link } from "react-router-dom"
import { format } from "date-fns"
import { useSelector } from "react-redux"

const StudyInfoCard = () => {
  const state = useSelector((state: any) => state)

  return (
    <Card className="card">
      <CardContent>
        <h3 className="weight-medium">Study details</h3>

        <div>
          <div className="info-container">
            <p className="key-column">Name</p>
            <p id="study_info_card.abreviation">
              {state.current.project.data.name}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Study ID</p>
            <p id="study_info_card.study_id">
              {state.current.project.data._id}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Study Status</p>
            <div id="study_info_card.study_status" className="study-status">
              <span>
                {state.current.project.data.state === "setup"
                  ? "In " + state.current.project.data.state
                  : state.current.project.data.state}
              </span>
            </div>
          </div>

          <div className="info-container">
            <p className="key-column">Owner</p>
            <p id="study_info_card.owner">
              {state.current.project.data.user_id}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Data Location</p>
            <p id="study_info_card.study_id">
              {state.current.project.data.config.data_location}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Age units</p>
            <p id="study_info_card.age_units">
              {state.current.project.data.config.age_measure}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Created at</p>
            <p id="study_info_card.from_date">
              {format(
                state.current.project.data.config.time_created.$date,
                `${DATE_FORMAT}`
              )}
            </p>
          </div>

          <div className="desription-container">
            <p className="key-column description-key">Description</p>
            <p className={"info-description"}>
              {state.current.project.data.description}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Number of readers</p>
            <p id="study_info_card.num_readers">
              {state.current.project.data.config.num_readers}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">Number of adjudicators</p>
            <p id="study_info_card.num_diagnosis_adjudicators">
              {state.current.project.data.config.num_diagnosis_adjudicators}
            </p>
          </div>

          <div className="info-container">
            <p className="key-column">
              Number of expected timepoints per subject
            </p>
            <p id="study_info_card.number_expected_tm_per_subject">
              {
                state.current.project.data.config
                  ?.number_expected_tm_per_subject
              }
            </p>
          </div>
        </div>

        {state.current.project.isManager && (
          <div className="button-right-container">
            <Link to={"information/edit"}>
              <button className="ghost-btn">
                <i className="material-icons clickable">edit</i>
                Edit study information
              </button>
            </Link>
          </div>
        )}
      </CardContent>
    </Card>
  )
}

export default StudyInfoCard
