export const useLDFlags = {
  setItem: async (key: string, value: any) => {
    return Promise.resolve().then(() =>
      localStorage.setItem(key, JSON.stringify(value))
    )
  },

  getItem: async (key: string) => {
    await Promise.resolve()
    return localStorage.getItem(key)
  },
}
